/* eslint-disable @typescript-eslint/naming-convention */
// eslint-disable-next-line import/no-cycle
import { actions } from './dashboardActions';
import { mutations } from './dashboardMutations';
import { dashboardGetters } from './dashboardGetters';

const vuex_dashboard = {
  state: {
    main: { dashboardGroups: [] },
    expenses: {
      reports: [],
    },
    reportData: [],
  },
  mutations,
  actions,
  getters: dashboardGetters,
};

export default vuex_dashboard;
