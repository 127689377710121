<template>
  <div>
    <search-panel
      v-if="!this.favoritesOnly"
      v-model="searchParams"
      :autofocus="true"
      :fields="searchFields"
      :showMaxRows="true"
      :maxRows="maxRows"
      :submitBtn="true"
      :mainAction="mainAction" />
  </div>
</template>

<script>
import SearchPanel from '@/app-components/SearchPanel.vue';

export default {
  name: 'ClientSearch',
  props: {
    maxRows: [Number, Object],
    favoritesOnly: {
      default: false,
      type: Boolean,
    },
    mainAction: Boolean,
  },
  components: {
    SearchPanel,
  },
  data() {
    return {
      searchParams: {},
      searchFields: [
        { name: 'search', caption: 'Search', size: 'm', value: null, maxChars: 0 },
        { name: 'category', caption: 'Category filter', size: 'm', items: this.categories(), value: null },
      ],
    };
  },

  created() {},

  watch: {
    searchParams(newValue) {
      if (newValue.search) {
        window.messageBus.$emit('clientSearchValue', this.searchParams);
      }
    },
  },

  computed: {},

  methods: {
    categories() {
      return this.$store.state.helper.clientCategories;
    },
  },
};
</script>

<style scoped></style>
