<template>
  <div>
    <div v-if="$store.state.user.internal && ($store.getters.userRight('C.ACCESS') || $store.getters.userRight('C.NEW') || $store.getters.userRight('C.EDIT'))">    
      <gat-tab :items="tabs" :clientId="clientId" :show-in-toolbar="true" />
      <keep-alive>
        <router-view :value="client" location="client" :parentId="clientId"></router-view>
      </keep-alive>
    </div>
    <div v-else>
      No access to clients.
      <br>
      Please contact your super user for access.
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { apiGet } from '../../store/api';
import GatUtils from '../../app-components/GatUtils';

export default {
  name: 'Client',
  components: {},
  props: ['clientId', 'clientList'],

  data() {
    return {
      client: {},
      url: '',
      activeWindowIndex: 0,
      clients: [],
    };
  },

  watch: {
    clientId: {
      handler(id) {
        this.retrieveClient(id);
      },
    },
    clientList: {
      immediate: true,
      handler(newVal) {
        if (this.clients && newVal) {
          this.clients = [...newVal];
        }
      },
    },
  },

  created() {
    window.messageBus.$on('toolbar_prev_clicked:client', () => {
      this.gotoClientByIndex(-1);
    });
    window.messageBus.$on('toolbar_next_clicked:client', () => {
      this.gotoClientByIndex(+1);
    });
    window.messageBus.$on('clientUpdate', (param) => {
      this.client = GatUtils.makeCopyViaJson(param);
    });
  },

  computed: {
    tabHasSqlScripts() {
      return this.$store.state.helper.tabHasSqlScripts;
    },

    tabs() {
      const result = [];
      result.push({ caption: 'Details', routeName: 'clientDetails' });
      if (this.$store.getters.userRight('C.ACCESS_CONTACTS') || this.$store.getters.userRight('OC.ACCESS_CONTACTS')) {
        result.push({ caption: 'Contacts', routeName: 'clientContacts', routeParams: { parentId: this.clientId } });
      }
      if (this.isInternalUser) {
        result.push({ caption: 'Documents', routeName: 'clientDocs', routeParams: {} });
      }
      if (this.$store.getters.userRight('C2.ACCESS_FOLLOW_UP')) {
        result.push({ caption: 'Follow up', routeName: 'clientFollowUps', routeParams: { clientId: this.clientId } });
      }
      if (this.$store.state.user.internal && this.tabHasSqlScripts.CLIENT > 0) {
        result.push({ caption: 'Dashboard', routeName: 'clientDashboard' });
      }
      if (this.$store.state.user.internal || this.$store.getters.userRight('EM.ACCESS')) {
        result.push({ caption: 'E-Mail', routeName: 'clientEmailList' });
      }
      return result;
    },

    isInternalUser() {
      return this.$store.state.user.internal;
    },
  },

  activated() {
    this.$store.commit('setToolbarTitleWithSecondary', { title: this.client.NAME, secondary: this.client.ACCOUNT_NO });
    if (this.clientId && (!this.client || this.client.ID !== this.clientId)) {
      this.retrieveClient(this.clientId);
    }
    if (this.clients.length > 1) {
      if (
        // eslint-disable-next-line array-callback-return
        this.clients.findIndex((item) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          item.ID == this.clientId;
        })
      ) {
        this.$store.commit('setToolbarNavBtns', { name: 'client', show: true });
        this.$store.commit('setTraversableList', this.clients);
        this.$store.commit('setTraversableListCounter', true);
      }
    }
  },

  deactivated() {
    this.$store.commit('setToolbarTitle', '');
    this.$store.commit('setToolbarNavBtns', { name: '', show: false });
    this.$store.commit('setTraversableList', []);
    this.$store.commit('setTraversableListCounter', false);
    this.$store.commit('setTraversableListIndex', 0);
  },

  methods: {
    gotoClientByIndex(step) {
      if (this.clients) {
        let idx = this.clients.findIndex((item) => item.ID == this.clientId);
        if (idx >= 0) {
          idx += step;
          if (idx < 0) {
            idx = this.clients.length - 1;
          }
          if (idx >= this.clients.length) {
            idx = 0;
          }
          const newId = this.clients[idx].ID;
          this.$router.push({ name: this.$route.name, params: { clientId: newId } });
        }
      }
    },
    retrieveClient() {
      apiGet(`clients/${this.clientId}`).then((data) => {
        if (data.FollowUps) {
          // eslint-disable-next-line array-callback-return
          data.FollowUps.map((item) => {
            // eslint-disable-next-line no-param-reassign
            item.editMode = false;
          });
        }
        this.client = data;
        this.$store.commit('setToolbarTitleWithSecondary', {
          title: this.client.NAME,
          secondary: this.client.ACCOUNT_NO,
        });
        const lastUsedIdx = this.$store.state.application.clients.lastClientsUsed.findIndex(
          (item) => item.ID == this.clientId
        );
        if (lastUsedIdx != 0 && this.$store.state.application.appIsInitialized) {
          this.$store.commit('addToLastClientUsed', { ID: this.client.ID, NAME: this.client.NAME });
        }
        if (this.clients.length > 1) {
          const idx = this.clients.findIndex((item) => item.ID == this.clientId);
          this.$store.commit('setTraversableListIndex', idx);
        }
      });
    },
  },
};
</script>

<style scoped></style>
