<template>
  <v-dialog v-model="showDialog" persistent max-width="1000">
    <v-card class="pa-3">
      <gat-form v-model="isValid" ref="form" :readonly="!canAddVisitors" :isLoading="isLoading">
        <gat-select label="Visitor templates" v-model="selectedTemplate" :items="visitorTemplates" size="xxl" />
      </gat-form>
      <gat-grid
        dense
        :elevation="0"
        :compact="true"
        title="Visitors"
        :items="visitorItems"
        :columns="columns"
        :selectable="true"
        keyName="VIS_ID"
        @selectionChanged="selected = $event"
        height="500px"
        min-width="500px" />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="#fb8c00" text @click="$emit('close')"> Close </v-btn>
        <v-btn
          color="#fb8c00"
          class="white--text"
          :disabled="!this.canAddVisitors || !this.selected.length > 0 || isLoading"
          @click="addSelected">
          Add
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { apiGet, apiPost } from '@/store/api';

export default {
  name: 'VisitorTemplateDialog',
  props: {
    showDialog: Boolean,
    callId: Number,
  },
  components: {},
  data() {
    return {
      isValid: false,
      isLoading: false,
      templates: [],
      selected: [],
      selectedTemplateId: -1,
      visitorItems: [],
    };
  },

  created() {
    this.getVisitorTemplates();
  },

  watch: {},

  computed: {
    selectedTemplate: {
      get() {
        return this.selectedTemplateId;
      },
      set(id) {
        if (id != this.selectedTemplateId) {
          this.selectedTemplateId = id;
          this.getTemplateItems(id);
        }
      },
    },
    visitorTemplates() {
      const { templates } = this;
      const result = templates.map((obj) => {
        const newObj = {};
        if (obj.VIT_ID) {
          newObj.text = obj.VIT_NAME;
          newObj.value = obj.VIT_ID;
        }
        return newObj;
      });
      return result;
    },
    columns() {
      const result = [];
      const settings = this.$store.getters.agencySettings(this.$store.state.portcall.callDetails.SETUP_ID);
      result.push({ header: 'Company', field: 'VIS_COMPANY' });
      result.push({ header: 'Name', field: 'VIS_NAME' });
      result.push({ header: 'Phone', field: 'VIS_PHONE' });
      result.push({ header: 'Other', field: 'VIS_OTHER' });

      for (let i = 1; i <= 6; i++) {
        if (settings[`VIS_USER${i}`]) {
          result.push({ header: settings[`VIS_USER${i}`], field: `VIS_USER${i}` });
        }
      }
      return result;
    },
    canAddVisitors() {
      if (this.$store.state.user.internal) {
        return this.$store.getters.userRight('P.NEW_AND_EDIT');
      }
      return this.$store.getters.userRight('VI.NEW_AND_EDIT');
    },
    agencyId() {
      return this.$store.state.application.agencyIdSelected;
    },
  },

  methods: {
    addSelected() {
      this.isLoading = true;
      this.selected.forEach((visitorId) => {
        this.addVisitorItem(visitorId);
      });
    },
    getVisitorTemplates() {
      if (this.agencyId) {
        this.isLoading = true;
        apiGet(`visitor/templates/${this.agencyId}`)
          .then((result) => {
            if (result) {
              this.templates = this.duplicateViaJson(result);
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    getTemplateItems(templateId) {
      if (this.agencyId && templateId) {
        apiGet(`visitor/template/${templateId}`).then((result) => {
          this.visitorItems = this.duplicateViaJson(result);
          this.visitorItems.forEach((visitor) => {
            // eslint-disable-next-line no-param-reassign
            visitor.VIS_PORTCALL_ID = this.callId;
          });
        });
      }
    },
    addVisitorItem(visitorId) {
      const newVisitor = this.duplicateViaJson(this.visitorItems.find((visitor) => visitor.VIS_ID === visitorId));
      this.$delete(newVisitor, 'VIS_HISTORY');
      this.$delete(newVisitor, 'VIS_TEMPLATE_ID');
      apiPost('/visitor/', newVisitor)
        .then((result) => {
          if (result.resultType === 2) {
            // 2=UPDATE_OK
            this.$store.commit('addVisitorItem', result.payload);
          }
        })
        .finally(() => {
          this.isLoading = false;
          this.$emit('close');
        });
    },
  },
};
</script>

<style scoped></style>
