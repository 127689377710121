import { v4 as uuid } from 'uuid';
import { apiPut } from '@/store/api';
import WmButtonGroup from '../WmButtonGroup.vue';

export const ApplicationMixin = {
  components: { WmButtonGroup },

  methods: {
    getColumnLength(tableAndColumnName) {
      if (this.$store.state.application && this.$store.state.application.varcharColumns) {
        return this.$store.state.application.varcharColumns[tableAndColumnName];
      }
      return 0; // if the settings are not loaded yet, we do not want to show "maxChar missing"
    },

    createDocumentFromTemplate(templateId, applicationStatus, location, foreignKey) {
      const guid = uuid();
      this.showDocumentStatusDialog = true;
      const appstatusen = this.duplicateViaJson(applicationStatus);
      appstatusen.VesselId = this.$store.state.portcall.callDetails.VESSEL_ID;
      appstatusen.SetupID = this.$store.state.portcall.callDetails.SETUP_ID;

      const param = {
        guid,
        templateId,
        location,
        foreignKey,
        appStatus: JSON.stringify(appstatusen),
      };
      apiPut('document/generate/', param).then(() => {});
      return guid;
    },

    getDocumentButton(docType, agencyId, disabled) {
      const button = {
        btnType: 'CUSTOM',
        caption: 'create doc',
        disabled,
        icon: 'mdi-file-document',
      };

      const getIcon = (item) => {
        let result = 'mdi-file-outline';

        if (item.FILENAME && item.FILENAME.toUpperCase().endsWith('.DOCX')) {
          result = 'mdi-file-word-outline';
        }
        if (item.MAKE_PDF) {
          result = 'mdi-file-pdf-outline';
        }
        return result;
      };

      // menuItems
      const docTemplates = this.$store.getters.documentTemplates(docType, agencyId);
      const menuItems = [];
      // eslint-disable-next-line array-callback-return
      docTemplates.map((item) => {
        const menuItem = {
          caption: item.TITLE,
          eventName: 'createDocClick',
          icon: getIcon(item),
          ID: item.ID,
        };
        menuItems.push(menuItem);
      });
      button.menuItems = menuItems;

      return button;
    },
  },
};
