<template>
  <div>
    <gat-form
      v-model="isValid"
      ref="form"
      :readonly="!userRightsPeople() || this.person.LOCK_FLAG == 1"
      :isLoading="isLoading">
      <gat-group title="Crew change details" :collapsed="isNew ? !expandedIfNew : false">
        <v-layout wrap>
          <gat-select
            v-model="person.TYPE_ID"
            label="Type"
            :items="crewChangeTypes"
            textFieldName="NAME"
            @item-selected="TypeChanged"
            codeFieldName="ID"
            :required="true"
            :disabled="this.orgPerson !== {}" />
          <gat-client-select
            label="Debtor"
            v-model="person.PRINCIPLE_ID"
            category="0"
            :required="true"
            :clearable="this.person.LOCK_FLAG != 1 && userRightsPeople()"
            showClientWarning
            showClientCreditLimit />
          <gat-edit v-model="person.SEQUENCE" label="Sequence" size="s" dataType="number" />
        </v-layout>
        <v-layout wrap>
          <v-radio-group label="Operation" v-model="person.OPERATION" class="ml-4">
            <v-radio label="Embarking" :value="0" />
            <v-radio label="Disembarking" :value="1" />
            <v-radio label="Other" :value="2" />
          </v-radio-group>
          <v-radio-group label="Person type" v-model="person.PERSON_TYPE" class="ml-4">
            <v-radio label="Crew" :value="0" />
            <v-radio label="Passenger" :value="1" />
            <v-radio label="Other" :value="2" />
          </v-radio-group>

          <v-radio-group label="Status" v-model="person.STATUS" class="ml-4">
            <v-radio label="In progress" :value="0" />
            <v-radio label="Completed" :value="1" />
            <v-radio label="Cancelled" :value="2" />
          </v-radio-group>
        </v-layout>
      </gat-group>

      <gat-group title="Person details" :collapsed="isNew ? !expandedIfNew : false">
        <v-layout wrap>
          <gat-edit v-model="person.FIRSTNAME" label="Firstname" :maxChars="50" size="xs12 sm6 xl3" />
          <gat-edit v-model="person.LASTNAME" label="Lastname" :maxChars="50" size="xs12 sm6 xl3" />
          <gat-edit v-model="person.FULLNAME" label="Fullname" :maxChars="100" size="xs12 sm6 xl3" />
          <gat-select
            v-model="person.RANK"
            label="Rank"
            :items="crewChangeRanks"
            textFieldName="NAME"
            codeFieldName="NAME"
            size="xs12 sm6 xl3" />
          <gat-select
            v-model="person.NATIONALITY"
            label="Nationality"
            :items="countries"
            textFieldName="text"
            codeFieldName="text"
            size="xs12 sm6 xl3" />
          <gat-select
            v-model="person.GENDER"
            label="Gender"
            :items="Genders"
            textFieldName="NAME"
            codeFieldName="NAME"
            size="xs12 sm6 xl3" />
          <gat-edit v-model="person.MOBILEPHONE" label="Mobilephone" :maxChars="20" size="xs12 sm6 xl3" />
          <GatSubGroup title="" size="xs12 sm12 xl12">
            <gat-edit v-model="person.PASSPORT_NUMBER" label="Passport number" :maxChars="25" size="xs12 sm4 xl3" />
            <gat-edit v-model="person.PASSPORT_ISSUED_DATE" dateEdit label="Issued date" size="xs12 sm4 xl3" />
            <gat-edit v-model="person.PASSPORT_VALID_TO" dateEdit label="Valid to" size="xs12 sm4 xl3" />
          </GatSubGroup>
          <GatSubGroup title="" size="xs12 sm12 xl12">
            <gat-edit v-model="person.SEAMANBOOK_NUMBER" label="Seamanbook number" :maxChars="25" size="xs12 sm4 xl3" />
            <gat-edit v-model="person.SEAMANBOOK_ISSUED_DATE" dateEdit label="Issued date" size="xs12 sm4 xl3" />
            <gat-edit v-model="person.SEAMANBOOK_VALID_TO" dateEdit label="Valid to" size="xs12 sm4 xl3" />
          </GatSubGroup>
          <gat-edit v-model="person.REMARKS" label="Remarks" :maxChars="10000" :rows="3" size="xs12 sm12 xl12" />
        </v-layout>
      </gat-group>
      <gat-group title="Dynamic fields" :collapsed="false">
        <gat-dyna-fields
          v-model="person.DynaFields"
          componentName="pDynaFields.CrewChangeDynaPerson"
          container="pDynaFields"
          :filterByIds="dynaFieldIds"></gat-dyna-fields>
      </gat-group>

      <gat-group title="Documents" :collapsed="false">
        <v-layout wrap>
          <embedded-doc-list
            v-if="!isNew && person && person.ID"
            :compact="false"
            location="crewchangeperson"
            :parentId="personIDString"
            :disabled="isDocListDisabled()" />
        </v-layout>
        <span v-if="isNew">The person must be created in order to add documents.</span>
      </gat-group>
      <gat-grid
        v-if="this.$store.getters.userRight('CRCH.SHOW_EXPENSES')"
        :items="person.EXPENSES"
        :columns="expense_columns"
        title="Expenses"
        :loading="isLoading"
        :fixedheader="true"
        :selectable="true"
        keyName="ID"
        @selectionChanged="expensesInUseChanged"
        :selectedKeys="selectedExpensesIDs">
        <template slot="cell" slot-scope="props">
          <div v-if="props.column.field == `Price`">
            <v-text-field
              hide-spin-buttons
              type="number"
              reverse
              dense
              v-model="props.row.Price"
              @input="priceChanged(props.row.ID)" />
          </div>
        </template>
      </gat-grid>
      <gat-bottom-panel
        :orgValue="orgPerson"
        :newValue="person"
        :itemDescription="'person'"
        :saveBtn="true"
        :deleteBtn="true"
        :buttonsEnabled="!saving"
        :deleteDisabled="!person.ID || !userRightsPeople() || this.person.LOCK_FLAG == 1"
        :saveDisabled="!userRightsPeople() || this.person.LOCK_FLAG == 1"
        @saveClicked="savePerson"
        @deleteClicked="deletePerson"></gat-bottom-panel>
    </gat-form>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { apiGet, apiPut, apiDelete } from '@/store/api';
import GatClientSelect from '@/app-components/GatClientSelect.vue';
import GatDynaFields from '@/app-components/dynafields/GatDynaFields.vue';
import EmbeddedDocList from '@/app-components/EmbeddedDocList.vue';

export default {
  name: 'CrewChangePerson',
  props: ['personID', 'callId', 'isNew'],
  components: { GatClientSelect, GatDynaFields, EmbeddedDocList },
  data() {
    return {
      person: {},
      orgPerson: {},
      isValid: null,
      saving: false,
      isLoading: true,
      expandedIfNew: false, // is set in activated/deactivated based on isNew prop.
      selectedExpensesIDs: [],
      Genders: ['Male', 'Female'],
      expense_columns: [
        { header: 'Name', field: 'EXPENSE_GROUP_NAME' },
        { header: 'Template', field: 'EXPENSE_TEMPLATE_NAME' },
        { header: 'Price tariff', field: 'PRICE_GROUP_NAME' },
        { header: 'Price', field: 'Price', dataType: 'number' },
        { header: 'Merge', field: 'MERGE_EXPENSES', dataType: 'boolean' },
      ],
      dynaFieldIds: [],
      loaded: false,
    };
  },

  created() {},
  activated() {
    if (!this.loaded && this.personID) {
      this.loaded = true;
      this.loadPerson(this.personID);
    }

    this.$store.commit('setBackNavigation', [
      { text: 'port call', href: `/call/${this.callId}` },
      { text: 'crew change', href: `/call/${this.callId}/crewchange` },
    ]);
    this.expandedIfNew = this.isNew;
  },
  deactivated() {
    this.$store.commit('setBackNavigation', undefined);
    this.loaded = false;
    this.$store.commit('setToolbarNavBtns', { previousState: true });
    this.expandedIfNew = !this.isNew;
  },

  computed: {
    crewChangeTypes: {
      get() {
        return this.$store.state.helper.crewChangeTypes;
      },
    },
    crewChangeRanks: {
      get() {
        return this.$store.state.helper.crewChangeRanks;
      },
    },
    countries: {
      get() {
        return this.$store.state.helper.countries;
      },
    },
    personIDString: {
      get() {
        return this.person.ID.toString();
      },
    },
  },
  methods: {
    setInUsedExpenses() {
      // this.selectedExpensesIDs = [65, 66];
      const tmpArr = [];
      for (let index = 0; index < this.person.EXPENSES.length; index++) {
        if (this.person.EXPENSES[index].USE_EXPENSE === 1) {
          tmpArr.push(this.person.EXPENSES[index].ID);
        }
      }
      this.selectedExpensesIDs = tmpArr;
    },
    loadPerson(personID) {
      if (this.isNew) {
        this.$refs.gatform.resetValidation();
        const person = {};
        this.person = person;
        this.orgPerson = this.duplicateViaJson(this.person);
        this.isLoading = false;
      } else {
        // set isLoading to true to show progressbar if response is not immidiate
        this.isLoading = true;
        apiGet(`/crewchange/people/${personID}`).then(
          (result) => {
            // remove progressbar
            this.person = result;
            this.orgPerson = this.duplicateViaJson(this.person);
            this.filterDynaFields();
            this.isLoading = false;
            this.setInUsedExpenses();
          },
          () => {
            this.isLoading = false;
          }
        );
      }
    },
    deletePerson() {
      // set isLoading to true to show progressbar if response is not immidiate
      this.isLoading = true;
      apiDelete(`/crewChangePerson/${this.person.ID}`).then((result) => {
        // remove progressbar
        this.isLoading = false;
        if (result.resultCategory == 1) {
          this.person = {};
          this.orgPerson = {};
          // delayed for å ungå at dialog om data endret vises (changed property må rekke å settes)
          this.$nextTick(() => {
            this.$store.dispatch('getCrewChangePeople', { callId: this.callId, clientId: this.clientId() });
            this.$router.push({ name: 'crewChange', params: { callId: this.callId } });
          });
        }
      });
    },
    savePerson() {
      this.$refs.form.validate();
      if (this.isValid) {
        this.saving = true;
        const person = { ...this.person };
        this.isLoading = true;
        apiPut('/CrewChange/Person', {
          value: person,
          orgValue: this.orgPerson,
          dynaValues: this.person.DynaFields,
          orgDynaValues: this.orgPerson.DynaFields,
          expenses: this.person.EXPENSES,
          orgExpenses: this.orgPerson.EXPENSES,
        }).then((result) => {
          // remove progressbar
          this.isLoading = false;
          //   person = result.payload;
          if (result.resultCategory == 1) {
            if (this.isNew) {
              this.person = {};
              this.orgPerson = {};
            } else {
              this.person = person;
              this.orgPerson = this.duplicateViaJson(this.person);
            }

            // delayed for å ungå at dialog om data endret vises (changed property må rekke å settes)
            this.$nextTick(() => {
              this.$store.dispatch('getCrewChangePeople', { callId: this.callId, clientId: this.clientId() });
              this.$router.push({ name: 'crewChange', params: { callId: this.callId } });
              this.saving = false;
            });
          } else {
            this.saving = false;
          }
        });
      }
    },
    userRightsPeople() {
      return this.$store.state.user.internal || this.$store.getters.userRight('CRCH.ACCESS_ALL_PERSONS');
    },

    TypeChanged(event) {
      // Get dynamic fields
      if (this.orgPerson === {}) {
        console.log('type:', event);
      }
      // Get expense lines
    },

    expensesInUseChanged(expenseID) {
      this.selectedExpensesIDs = expenseID;
      for (let index = 0; index < this.person.EXPENSES.length; index++) {
        const expIndex = this.selectedExpensesIDs.indexOf(this.person.EXPENSES[index].ID);
        this.person.EXPENSES[index].USE_EXPENSE = expIndex != -1 ? 1 : 0;
      }
    },
    filterDynaFields() {
      this.dynaFieldIds = [];
      for (let index = 0; index < this.person.DynaFields.length; index++) {
        this.dynaFieldIds.push(this.person.DynaFields[index].FIELD_ID);
      }
    },
    clientId() {
      if (
        this.$store.state.user.internal ||
        this.$store.getters.userRight('CRCH.ACCESS_ALL_PERSONS') ||
        this.$store.getters.userRight('CRCH.ACCESS')
      ) {
        return 0;
      }
      return this.$store.state.user.externalClientId ? this.$store.state.user.externalClientId : null;
    },
    isDocListDisabled() {
      const result = !this.userRightsPeople() || this.person.LOCK_FLAG == 1;
      return result;
    },
    priceChanged(ID) {
      const idx = this.person.EXPENSES.findIndex((x) => x.ID === ID);
      this.person.EXPENSES[idx].AMOUNT = this.person.EXPENSES[idx].QTY * this.person.EXPENSES[idx].Price;
    },
  },
  watch: {
    personID: {
      handler(newPersonID) {
        if (!this.loaded) {
          this.loadPerson(newPersonID);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss"></style>
