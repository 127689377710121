/* eslint-disable no-param-reassign */
export const mutations = {
  setAttendanceCodes(state, data) {
    state.attendanceCodes = data;
  },

  setAttendanceItemCheckout(state, data) {
    const prevAttendance = state.previousAttendances;
    const portcallAttendance = state.portcallAttendances;
    for (let i = 0; prevAttendance.length > i; i++) {
      if (prevAttendance[i].ID === data.ID) {
        state.previousAttendances[i].CHECKOUT = data.CHECKOUT;
        state.previousAttendances[i].REMARK = data.REMARK;
        state.previousAttendances[i].STATUS = data.STATUS;
      }
    }
    if (portcallAttendance.length != 0) {
      for (let i = 0; portcallAttendance.length > i; i++) {
        if (portcallAttendance[i].ID === data.ID) {
          state.portcallAttendances[i].CHECKOUT = data.CHECKOUT;
          state.portcallAttendances[i].REMARK = data.REMARK;
          state.portcallAttendances[i].STATUS = data.STATUS;
        }
      }
    }
  },

  setBerthedPortcalls(state, data) {
    state.berthedPortcalls = data;
  },

  setPreviousAttendances(state, data) {
    state.previousAttendances = data;
  },

  setPortcallAttendaces(state, data) {
    state.portcallAttendances = data;
  },

  setPortcallAttendaceItem(state, data) {
    state.portcallAttendances.push(data);
  },

  setPreviousAttendanceItem(state, data) {
    const berthedCalls = state.berthedPortcalls;
    for (let i = 0; berthedCalls.length > i; i++) {
      if (!data.PORTCALL_NUMBER || !data.VESSEL_PREFIX || !data.VESSEL_NAME) {
        if (berthedCalls[i].PORTCALL_ID === data.PORTCALL_ID) {
          data.PORTCALL_NUMBER = berthedCalls[i].PORTCALL_NUMBER;
          data.VESSEL_PREFIX = berthedCalls[i].VESSEL_PREFIX;
          data.VESSEL_NAME = berthedCalls[i].VESSEL_NAME;
        }
      }
      // Add more properties to the object before being updated in the previousAttendance store.
    }

    const { attendanceCodes } = state;
    for (let i = 0; attendanceCodes.length > i; i++) {
      if (!data.ATTENDANCE_CODE_DESCRIPTION) {
        if (attendanceCodes[i].value === data.ATTENDANCE_CODE) {
          data.ATTENDANCE_CODE_DESCRIPTION = attendanceCodes[i].text;
        }
      }
    }

    // Add the object to the list.
    state.previousAttendances.push(data);
  },

  setInAttendance(state, data) {
    if (Object.keys(data).length === 0 && data.constructor === Object) {
      // Check if data is empty and a object type.
      state.inAttendance = null;
    } else {
      state.inAttendance = data;
    }
  },

  replaceAttendanceItem(state, attendanceItem) {
    // Find and replace items in previous attendace $store.
    const previousAttendances = [...state.previousAttendances];
    if (previousAttendances.length > 0) {
      const idx = previousAttendances.findIndex((item) => +item.ID === +attendanceItem.ID);
      if (idx >= 0) {
        previousAttendances.splice(idx, 1, attendanceItem);
        state.previousAttendances = previousAttendances; // update the store.
      }
    }
    // Find and replace items in portcall attendace $store.
    const portcallAttendaces = [...state.portcallAttendances];
    if (portcallAttendaces.length > 0) {
      const idx = portcallAttendaces.findIndex((item) => +item.ID === +attendanceItem.ID);
      if (idx >= 0) {
        portcallAttendaces.splice(idx, 1, attendanceItem);
        state.portcallAttendances = portcallAttendaces; // update the store.
      }
    }
  },

  removeAttendanceItem(state, id) {
    let idx = state.previousAttendances.findIndex((item) => item.ID == id);
    if (idx >= 0) {
      // replace item
      state.previousAttendances.splice(idx, 1);
    }
    if (state.portcallAttendances.length != 0) {
      idx = 0;
      idx = state.portcallAttendances.findIndex((item) => item.ID == id);
      if (idx >= 0) {
        state.portcallAttendances.splice(idx, 1);
      }
    }
  },
};
