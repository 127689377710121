<template>
  <gat-select
    :style="showSelect ? '' : 'display:none'"
    v-model="emailFrom"
    :label="label"
    :items="fromItems"
    :noflex="noFlex"
    :readonly="isReadonly"
    @item-selected="$emit('item-selected', $event)"
    :required="required" />
</template>

<script>
// import { apiGet } from '../store/api'

export default {
  name: 'SelectEmailFrom',
  props: {
    value: String,
    startValue: {},
    agencyId: {
      type: Number,
    },
    label: {
      type: String,
      default: 'From',
    },
    noFlex: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: Boolean,
  },
  components: {},
  data() {
    return {
      emailFrom: undefined,
      fromItems: [],
    };
  },
  activated() {
    this.fromItems = [];
    this.getFromAddress();
    // if(Array.isArray(this.fromItems) && this.fromItems.length === 0){
    //     this.getFromAddress();
    // } else {
    //     this.emailFrom = this.fromItems[0].value;
    // }
  },
  mounted() {},

  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.emailFrom = val;
      },
    },
    fromItems: {
      immediate: true,
      handler(val) {
        if (Array.isArray(val) && val.length > 0) {
          if (this.startValue && !this.emailFrom) {
            this.emailFrom = this.startValue;
          } else {
            this.emailFrom = val[0].value;
          }
        }
      },
    },
    emailFrom(val) {
      this.$emit('input', val);
    },
  },

  computed: {
    isReadonly() {
      return this.readonly || (Array.isArray(this.fromItems) && this.fromItems.length === 1);
    },
    showSelect() {
      return true; // Array.isArray(this.fromItems) && this.fromItems.length >= 2;
    },
  },

  methods: {
    getFromAddress() {
      const result = [];

      const agency = this.$store.getters.agencySettings(this.agencyId);
      const { user } = this.$store.state;
      const { globalSettings } = this.$store.state;
      const agencyName = globalSettings.SGL_WM_EMAIL_USE_PERSONAL_ON_AGENCY === 1 ? user.fullName : agency.AGENT;

      const agencyItem = {
        NAME: agencyName,
        EMAIL: agency.EMAIL,
        text:
          globalSettings.SGL_WM_EMAIL_USE_PERSONAL_ON_AGENCY === 1 ? `${agencyName} <${agency.EMAIL}>` : agency.EMAIL,
        value: agency.EMAIL,
      };

      const userItem = {
        NAME: user.fullName,
        EMAIL: user.email,
        text: `${user.fullName} <${user.email}>`,
        value: user.email,
      };

      if (globalSettings.SGL_WM_EMAIL_FROM === 1 /* Personal email only */) {
        result.push(userItem);
      } else if (globalSettings.SGL_WM_EMAIL_FROM === 2 /* Agency email only */) {
        result.push(agencyItem);
      } else if (globalSettings.SGL_WM_EMAIL_FROM === 3 /* Personal email and optional agency email */) {
        result.push(userItem);
        result.push(agencyItem);
      } else if (globalSettings.SGL_WM_EMAIL_FROM === 4 /* Agency email and optional personal email */) {
        result.push(agencyItem);
        result.push(userItem);
      } else {
        result.push(userItem);
      }

      this.fromItems = result;
    },
  },
};
</script>

<style scoped></style>
