/* eslint-disable no-prototype-builtins */
/* eslint-disable no-param-reassign */
import Vue from 'vue';

import Vuex from 'vuex';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import numeral from 'numeral';
// eslint-disable-next-line import/no-cycle
import { apiGet, apiPost, apiDelete } from './api';
import vuex_portcall from './portcall';
import vuex_toolbar from './toolbar';
import vuex_helper from './helper';
import vuex_application from './application';
import vuex_constants from './constants';
import vuex_dashboard from '../modules/dashboard/store/dashboardState';
import vuex_attendance from '../modules/attendance/store/attendanceState';
import vuex_gatcomponents from './gatComponents';
import vuex_appStatus from './appStatus';

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: true,
  modules: {
    application: vuex_application,
    appStatus: vuex_appStatus,
    dashboard: vuex_dashboard,
    attendance: vuex_attendance,
    portcall: vuex_portcall,
    toolbar: vuex_toolbar,
    helper: vuex_helper,
    constants: vuex_constants,
    gatcomponents: vuex_gatcomponents,
  },
  state: {
    agencySettings: [],
    globalSettings: {},
    settings: {
      dark: null, // null until retrived from db
      numberFormat: 'en',
      dateFormat: 'DD.MM.YYYY',
    },
    user: {
      loaded: false, // will be set to true when user data is retrieved from backend
      apiToken: '',
      externalClientId: null,
      fullName: null,
      userName: null,
      userId: null,
      defaultAgencyId: null,
      internal: false,
      favorites: [],
      userRights: {},
      userSettings: {
        crewChange: {
          denseTables: null,
          showTables: null,
        },
        whiteBoards: {
          showCallsFromAllAgencies: false,
        },
      },
      upcomingTasks: {
        transport: false,
        accommodation: false,
        service: false,
      },
    },
    whiteboard: {
      whiteBoards: [],
      whiteBoardData: [],
      isDirty: false, // set to TRUE to force reloading of whiteboard data when showing whiteboard
    },
  },

  mutations: {
    setAgencySettings(state, data) {
      state.agencySettings = data;
    },
    setApiToken(state, token) {
      state.user.apiToken = token;
    },

    setCrewChangeTablesDense(state, data) {
      state.user.userSettings.crewChange.denseTables = data;
    },

    setCrewChangeTablesShow(state, data) {
      state.user.userSettings.crewChange.showTables = data;
    },

    setDarkTheme(state, data) {
      localStorage.setItem('dark_theme', data);
      state.settings.dark = data;
    },

    setGlobalSettings(state, data) {
      state.globalSettings = data;
    },
    setNumberFormat(state, data) {
      state.settings.numberFormat = data;
      numeral.locale(data);
    },
    setDateFormat(state, data) {
      state.settings.dateFormat = data;
    },
    setWhiteBoards(state, whiteBoards) {
      state.whiteboard.whiteBoards = whiteBoards;
    },
    setWhiteBoardData(state, data) {
      state.whiteboard.whiteBoardData = data;
    },
    setWhiteBoardDataIsDirty(state, isDirty) {
      state.whiteboard.isDirty = isDirty;
    },
    setWhiteBoardUserSettings(state, whiteboardSettings) {
      state.user.userSettings.whiteBoards = whiteboardSettings;
    },

    setUser(state, data) {
      data.loaded = true;
      state.user = Object.assign(state.user, data);
      // state.application.agencyIdSelected = data.defaultAgencyId;
    },
    addVessel(state, vessel) {
      const idx = state.vessels.findIndex((item) => item.id == vessel.id);
      if (idx >= 0) {
        state.vessels[idx] = vessel;
      } else {
        state.vessels.push(vessel);
      }
    },
    setFavorites(state, data) {
      state.user.favorites = data;
    },

    setUpcomingAccommodations(state, data) {
      state.user.upcomingTasks.accommodation = data;
    },

    setUpcomingTransports(state, data) {
      state.user.upcomingTasks.transport = data;
    },

    setUpcomingServices(state, data) {
      state.user.upcomingTasks.service = data;
    },

    toggelFavorite(state, fav) {
      const idx = state.user.favorites.findIndex(
        (item) => item.FAV_TYPE == fav.FAV_TYPE && item.FAV_KEY == fav.FAV_KEY
      );
      if (idx >= 0) {
        apiDelete('favorites/', {
          recType: fav.FAV_TYPE,
          keyInt: fav.FAV_KEY,
          keyStr: '',
        });
        state.user.favorites.splice(idx, 1);
      } else {
        apiPost('favorites/', {
          recType: fav.FAV_TYPE,
          keyInt: fav.FAV_KEY,
          keyStr: '',
        });
        state.user.favorites.push({
          FAV_TYPE: fav.FAV_TYPE,
          FAV_KEY: fav.FAV_KEY,
        });
      }
    },
  },

  getters: {
    agencySettings: (state) => (agencyId) => {
      const result = state.agencySettings.find((item) => item.ID == agencyId);
      if (result) {
        return result;
      }
      return {};
    },

    user(state) {
      return state.user;
    },

    favCalls(state) {
      const result = [];
      // eslint-disable-next-line array-callback-return
      state.user.favorites.map((item) => {
        if (item.FAV_TYPE == 'CALL') {
          result.push(item.FAV_KEY);
        }
      });
      return result;
    },

    isProbablyAuthenticated(state) {
      let result = false;
      try {
        if (state.user.apiToken) {
          const decoded = jwtDecode(state.user.apiToken);
          if (decoded.scope == 'GatshipWebModule' && moment(decoded.validUntil).diff(moment(), 'days') > 0) {
            result = true;
            if (state.application.debugMode) {
              console.log(`token valid ${moment(decoded.validUntil).diff(moment(), 'days')} more days`);
            }
          } else if (state.application.debugMode) {
            console.log(decoded);
          }
        }
      } catch (error) {
        if (state.application.debugMode) {
          console.log(error);
        }
      }

      return result;
    },

    userRight: (state) => (propName) => {
      if (!propName) {
        return true;
      }
      if (propName == 'Internal-only') {
        return state.user.internal;
      }

      // Rempve trailing number from delsystem. eg EX2.EDIT_PO_NUMBER will be EX.EDIT_PO_NUMBER
      propName = propName.replace(/\d+\./, '.');

      return (
        state.user.userRights &&
        state.user.userRights.hasOwnProperty(propName) &&
        state.user.userRights[propName] == '1'
      );
    },
  },

  actions: {
    getAgencySettings({ commit }) {
      return new Promise((resolve) => {
        apiGet('helper/agencySettings/')
          .then((data) => {
            commit('setAgencySettings', data);
          })
          .finally(() => {
            resolve();
          });
      });
    },

    getVessel({ commit }, vesselID) {
      return apiGet(`vessel/${vesselID}`).then((data) => {
        commit('addVessel', data);
      });
    },

    getAppSettings({ commit }) {
      /* {commit} */
      return new Promise((resolve) => {
        apiGet('savedinfo/web-app-settings')
          .then((data) => {
            if (data.length > 0) {
              const result = JSON.parse(data[0].MEMOVALUE1);
              if (result.numberFormat) {
                commit('setNumberFormat', result.numberFormat);
              }
              if (result.weekStartDay) {
                commit('setWeekStartDay', result.weekStartDay);
              }
              if (result.weekNumberInCalendar) {
                commit('setWeekNumberInCalendar', result.weekNumberInCalendar);
              }
              if (result.dateFormat) {
                commit('setDateFormat', result.dateFormat);
              }
            }
          })
          .finally(() => {
            resolve();
          });
      });
    },

    getCrewChangeTablesDense({ commit }) {
      return apiGet('savedinfo/web-ccm-grid-dense').then((data) => {
        if (data.length > 0) {
          if (data[0].INTVALUE1 == 1) {
            commit('setCrewChangeTablesDense', true);
          }
        }
      });
    },

    getCrewChangeTablesShow({ commit }) {
      return apiGet('savedinfo/web-ccm-grid-show').then((data) => {
        if (data.length > 0) {
          commit('setCrewChangeTablesShow', data[0].INTVALUE1);
        } else {
          commit('setCrewChangeTablesShow', 0);
        }
      });
    },

    getDarkModeSetting({ commit }) {
      return apiGet('savedinfo/web-dark-layout').then((data) => {
        if (data.length > 0) {
          if (data[0].INTVALUE1 == 1) {
            commit('setDarkTheme', true);
          }
        }
      });
    },

    getGlobalSettings({ commit }) {
      return new Promise((resolve) => {
        apiGet('helper/globalSettings')
          .then((data) => {
            commit('setGlobalSettings', data[0]);
          })
          .finally(() => {
            resolve();
          });
      });
    },

    getSavedInfoForWeb({ state, commit }) {
      return new Promise((resolve) => {
        apiGet('savedInfo/web_')
          .then((data) => {
            const userWhiteboardSettingIncludeAgencies = data.find(
              (item) => item.TYPE_NAME == 'web_whiteboard_includeAllAgencies'
            );
            if (userWhiteboardSettingIncludeAgencies) {
              const newSettings = {
                ...state.user.userSettings.whiteBoards,
              };
              newSettings.showCallsFromAllAgencies = JSON.parse(userWhiteboardSettingIncludeAgencies.MEMOVALUE1);
              commit('setWhiteBoardUserSettings', newSettings);
            }
          })
          .finally(() => {
            resolve();
          });
      });
    },

    getWhiteBoardData({ commit }, param) {
      return apiGet(
        `whiteBoardData/${param.whiteboardId}/${param.agencyId}/${param.maxRows}?includeAllAgencies=${param.includeAllAgencies}`
      ).then((data) => {
        commit('setWhiteBoardData', data);
      });
    },

    getFavorites({ commit }) {
      return new Promise((resolve) => {
        apiGet('favorites')
          .then((data) => {
            commit('setFavorites', data);
          })
          .finally(() => {
            resolve();
          });
      });
    },

    getUser({ commit }) {
      return apiGet('user').then((data) => {
        if (this.state.application.debugMode) {
          console.log(data);
        }
        commit('setUser', data);
        commit('setNewAgency', data.defaultAgencyId);
      });
    },

    getNumUpcomingTasks({ commit }, clientId) {
      return new Promise((resolve) => {
        apiGet(`crewchange/numUpcomingTasks/${clientId}`)
          .then((data) => {
            if (data[0].ACCOM_AMOUNT > 0) {
              commit('setUpcomingAccommodations', true);
            }
            if (data[0].TRANSP_AMOUNT > 0) {
              commit('setUpcomingTransports', true);
            }
          })
          .finally(() => {
            resolve();
          });
      });
    },

    getNumUpcomingServices({ commit }, clientId) {
      return new Promise((resolve) => {
        apiGet(`service/numUpcomingServices/${clientId}`)
          .then((data) => {
            if (data[0].SERVICE_AMOUNT > 0) {
              commit('setUpcomingServices', true);
            }
          })
          .finally(() => {
            resolve();
          });
      });
    },

    getWhiteBoards({ commit }, agencyID) {
      return new Promise((resolve) => {
        apiGet(`whiteboards/${agencyID}`)
          .then((data) => {
            commit('setWhiteBoards', data);
          })
          .finally(() => {
            resolve();
          });
      });
    },

    waitForUserData() {
      const self = this;
      return new Promise((resolve) => {
        if (self.state.user.loaded) {
          resolve();
        } else {
          if (self.state.application.debugMode) {
            console.log('waiting for userdata...');
          }
          window.setTimeout(() => {
            self.dispatch('waitForUserData').then(() => {
              resolve();
            });
          }, 300);
        }
      });
    },
  },
});

export default store;
export const useStore = () => store;
