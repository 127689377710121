<template>
  <v-dialog v-model="showDialog" max-width="1000" persistent>
    <v-card class="pa-3">
      <GatSubGroup :compact="true" :elevation="0" size="xxl" style="height: inherit">
        <div class="d-flex flex-grow-1 align-baseline justify-baseline">
          <gat-select
            label="SOF Templates"
            v-model="templates.ID"
            :items="templates"
            textFieldName="NAME"
            codeFieldName="ID"
            @item-selected="loadTemplateItems($event.ID)"
            size="xxl" />
          <gat-reload-btn hint="Reload SOF Templates" @click="loadSofTemplates(agencyId, calltypeId)" />
        </div>
        <gat-grid
          class="test-grid elevation-0"
          :compact="true"
          :dense="true"
          :items="templateItems"
          :hideEmptyColumns="true"
          :columns="columns"
          keyName="ID"
          :selectedKeys="selectedItemKeys"
          :selectable="true"
          @selectionChanged="selectedItemKeys = $event"
          height="calc(60vh - 0px)"
          :fixedheader="true" />
      </GatSubGroup>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="defalut" text @click="close">Close</v-btn>
        <v-btn
          color="primary"
          @click="addsofItems"
          :disabled="!$store.getters.userRight('SOF.NEW_AND_EDIT') || selectedItemKeys.length <= 0"
          >Add</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!--  <v-dialog v-model="showDialog" persistent  max-width="1000">
            <v-card class="pa-3">
                <gat-form ref="form" :readonly="!$store.getters.userRight('SOF.NEW_AND_EDIT')">
                    <gat-select
                        label="SOF template"
                        v-model="templates.ID"
                        :items="templates"
                        textFieldName="NAME"
                        codeFieldName="ID"
                        size="xxl"
                        @item-selected="loadTemplateItems($event.ID)"
                    />
                </gat-form>
                <gat-grid
                    :compact=true
                    :items="templateItems"
                    :columns="columns"
                    keyName="ID"
                    :selectedKeys="selectedItemKeys"
                    :selectable=true
                    @selectionChanged="selectedItemKeys=$event"
                    height="500px"
                    min-width="500px"
                />
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="close">Close</v-btn>
                    <v-btn color="success" @click="addsofItems" :disabled="!$store.getters.userRight('SOF.NEW_AND_EDIT')">Add</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> -->
</template>

<script>
import { apiGet, apiPost } from '@/store/api';

export default {
  name: 'SofAddTemplate',
  props: {
    showDialog: Boolean,
    callId: [String, Number],
    isLoading: Boolean,
    sofTemplates: Array,
  },
  components: {},
  data() {
    return {
      templates: [],
      templateItems: [],
      selectedItemKeys: [],
      loadSofTries: 0,
      loadingSof: undefined,
    };
  },
  created() {
    this.loadSofTemplates(this.agencyId, this.calltypeId);
  },

  watch: {
    sofTemplates(newVal) {
      this.templates = newVal;
    },
    showDialog(newVal) {
      if (newVal) {
        // On open
        if (this.sofTemplates.length <= 0) {
          this.loadSofTemplates(this.agencyId, this.calltypeId);
        } else {
          this.templates = this.sofTemplates;
        }
      }
    },
    agencyId: {
      handler(agencyId) {
        this.loadSofTemplates(agencyId, this.calltypeId);
      },
    },
    calltypeId(callTypeId) {
      this.loadSofTemplates(this.agencyId, callTypeId);
    },
  },

  computed: {
    columns() {
      const result = [];
      result.push({ header: 'No', field: 'SORT_ORDER' });
      result.push({ header: 'Statement', field: 'STATEMENT' });
      result.push({ header: 'Document Code', field: 'TYPE', hideOnPhone: true });
      // result.push({"header": 'Required', "field":"REQ_BEFORE", hideOnPhone: true});
      result.push({
        header: 'Required',
        field: 'REQ_BEFORE',
        calculated: (rowData) => {
          if (rowData.REQ_BEFORE === 1) {
            return 'before Anchored, Berthed, Sailed and Archived';
          }
          if (rowData.REQ_BEFORE === 2) {
            return 'before Berthed, Sailed and Archived';
          }
          if (rowData.REQ_BEFORE === 3) {
            return 'before Sailed and Archived';
          }
          if (rowData.REQ_BEFORE === 4) {
            return 'before Archived';
          }
          return null;
        },
        hideOnPhone: true,
      });
      result.push({ header: 'Connected to', field: 'PORTCALL_FIELD', hideOnPhone: true });
      result.push({
        header: 'Port Call Status',
        field: 'PORTCALL_STATUS',
        calculated: (rowData) => this.lookupCode(rowData.PORTCALL_STATUS, this.$store.state.constants.portcallStatus),
        hideOnPhone: true,
      });
      // result.push({"header": 'Port Call Status', "field":"PORTCALL_STATUS", hideOnPhone: true});
      result.push({ header: 'Code', field: 'CODE1', hideOnPhone: true });
      result.push({ header: 'Remark', field: 'COMMENTS', hideOnPhone: true });

      return result;
    },
    agencyId() {
      return this.$store.state.application.agencyIdSelected;
    },

    calltypeId() {
      return this.$store.state.portcall.callDetails.extra.PCE_CALLTYPE;
    },
  },

  methods: {
    // get sof templates
    loadSofTemplates(agencyId, calltypeId) {
      const tries = this.loadSofTries;
      if (this.loadingSof) {
        clearTimeout(this.loadingSof);
      }
      this.templateItems = [];

      if (!calltypeId && tries >= 5) {
        // eslint-disable-next-line no-param-reassign
        calltypeId = -1;
      }

      if ((agencyId || agencyId === 0) && calltypeId) {
        this.loadingSof = setTimeout(() => {
          apiGet(`sof/templates/${agencyId}/${calltypeId}`)
            .then((result) => {
              /* this.templates = result; */
              this.$emit('SOF-templates', result);
            })
            .catch((err) => {
              console.log(err);
            });
        }, 750);
      } else {
        if (this.$store.state.application.debugMode) {
          console.warn(`Insufficient loadSofTemplates params provided, TRY: ${tries + 1}/5`);
        }
        if (tries !== 5) {
          this.loadSofTries = tries + 1;
          this.loadingSof = setTimeout(() => {
            this.loadSofTemplates(this.agencyId, this.calltypeId);
          }, 1500);
        } else {
          this.loadSofTries = 0;
        }
      }
    },

    // get template items
    loadTemplateItems(templateItemId) {
      if (templateItemId) {
        apiGet(`sof/template/${templateItemId}`)
          .then((result) => {
            this.templateItems = result;
          })
          .finally(() => this.selectAllItems());
      }
    },

    // used in method loadTemplateItems to set all the items to selected when a new template is loaded.
    selectAllItems() {
      this.selectedItemKeys = [];
      for (let i = 0; i < this.templateItems.length; i++) {
        this.selectedItemKeys.push(this.templateItems[i].ID);
      }
    },

    // fetch lines to be added to the statement field
    getSelectedTemplateItems() {
      const items = this.templateItems.filter((templateItem) =>
        // does templateItems exist in the selected list
        this.selectedItemKeys.includes(templateItem.ID)
      );

      return items;
    },

    // called after clicking add button and add new sof item
    addsofItems() {
      // Check length to get title of newly added sof
      const { sofTitles } = this.$store.state.portcall;
      const sofTitlesLength = sofTitles.length + 1;

      const newSof = {
        CallId: this.callId,
        Lines: this.getSelectedTemplateItems(),
        Title: `SOF no ${sofTitlesLength}`,
      };
      this.$emit('loading', true);
      apiPost('sof/', newSof).then((result) => {
        const newSofTitleAndId = { TITLE: result.payload.TITLE, ID: result.payload.ID };
        this.$store.commit('addSofTitleAndId', newSofTitleAndId);
        this.$store.commit('setSof', result.payload);

        // emit sofAdded event with new ID as parameter
        this.$emit('sofAdded', result.payload.ID);
        window.messageBus.$emit('sof-was-added');
        this.$emit('loading', false);
        this.resetForm();
      });
      this.$emit('close');
    },

    close() {
      this.resetForm();
      this.$emit('close');
    },

    resetForm() {
      this.templateItems = [];
      this.templates = [];
    },
  },
};
</script>

<style scoped>
.test-grid {
  width: 100% !important;
}
</style>
