import store from '@/store/app-store';

export function calculateTarif(context, tarifId) {
  const result = {
    name: 'unkown',
    price: null,
    implemented: false,
  };

  const tarif = store.state.helper.tarifs.find((item) => item.ID == tarifId);

  if (tarif) {
    result.name = tarif.NAME;
    // fixed
    if (tarif.CALCULATION_TYPE == 0) {
      result.price = tarif.FIXED_FEE_AMOUNT;
      result.currency = tarif.CURRENCY_CODE;
      result.implemented = true;
    }
  }

  return result;
}
