<template>
  <div>
    <GatGroup class="pa-0" title="Layout">
      <v-layout wrap>
        <gat-edit label="Use dark mode" toggle v-model="isDarkMode" />
      </v-layout>
    </GatGroup>
    <GatGroup class="pa-0" title="Localization">
      <v-layout wrap>
        <gat-select
          size="md2 xs6"
          label="Number format"
          :items="numberFormats"
          :value="$store.state.settings.numberFormat"
          @input="saveSetting('numberFormat', $event)">
          <template v-slot:selection="{ data }">
            {{ data.text }}
          </template>
        </gat-select>
        <gat-select
          size="md2 xs6"
          label="First day of week (calendar)"
          :items="weekStartDays"
          :value="$store.state.gatcomponents.calendarSettings.weekStartDay"
          @input="saveSetting('weekStartDay', $event)" />
        <gat-select
          size="md2 xs6"
          label="Week numbers (calendar)"
          :items="weekNumbering"
          :value="$store.state.gatcomponents.calendarSettings.weekNumberInCalendar"
          @input="saveSetting('weekNumberInCalendar', $event)" />
        <gat-select
          size="md2 xs6"
          label="Date format"
          :items="dateFormats"
          :value="$store.state.settings.dateFormat"
          @input="saveSetting('dateFormat', $event)" />
      </v-layout>
    </GatGroup>
    <GatGroup v-if="this.$store.state.application.debugMode" class="pa-0" title="Debug">
      <v-layout wrap>
        <gat-edit label="External user" toggle v-model="externalUser" />
        <gat-edit
          label="GAT-Ship CRM"
          toggle
          :value="$store.state.application.isCRM"
          @input="$store.commit('setIsCrm', $event)" />
      </v-layout>
    </GatGroup>
    <div class="gat-api-buildnr">
      <span>GAT-Ship web module build: {{ $store.state.application.version }}</span>
      <br />
      <span>GAT-Ship web module API build: {{ buildNr }}</span>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { apiPost, apiGet } from '@/store/api';

export default {
  name: 'Settings',
  props: [],
  components: {},
  data() {
    return {
      isDarkMode: null,
      settings: {},
      externalUser: true,
      numberFormats: [
        { value: 'en', text: '10,000.00' },
        { value: 'de', text: '10 000,00' },
        { value: 'nl-nl', text: '10.000,00' },
      ],
      weekNumbering: [
        { value: 'iso', text: 'ISO (first 4-day week)' },
        { value: 'us', text: 'American' },
        { value: 'none', text: 'Hide week numbers' },
      ],
      weekStartDays: [
        { value: 'monday', text: 'monday' },
        { value: 'sunday', text: 'sunday' },
        { value: 'saturday', text: 'saturday' },
      ],
      dateFormats: [
        { value: 'DD.MM.YYYY', text: 'DD.MM.YYYY' },
        { value: 'MM.DD.YYYY', text: 'MM.DD.YYYY' },
        { value: 'DD/MM/YYYY', text: 'DD/MM/YYYY' },
        { value: 'MM/DD/YYYY', text: 'MM/DD/YYYY' },
        { value: 'MM/DD/YY', text: 'MM/DD/YY' },
      ],
      buildNr: null,
    };
  },
  mounted() {
    this.settings = this.$store.state.settings;
    // if store is loaded from db...
    if (this.$store.state.settings.dark != null) {
      // ...set inital value of isDarkmode from store
      this.isDarkMode = this.$store.state.settings.dark;
    } else {
      // if store value is not load (refresh of settings page will do that),  we get value from backend
      apiGet('savedinfo/web-dark-layout').then((data) => {
        if (data.length > 0) {
          this.isDarkMode = data[0].INTVALUE1 == 1;
        }
      });
    }
    this.getBackendVersion();
  },

  watch: {
    externalUser(val) {
      const usr = this.$store.state.user;
      usr.internal = !val; // DIRTY!! Men kun for testing.  State skal endres via muttation
    },

    isDarkMode(val) {
      // when the local data property isDarkMode changes, we should
      // 1. Set the vuetify theme dark settings
      this.$vuetify.theme.dark = val;
      // 2. Update the store
      this.$store.commit('setDarkTheme', val);
      // 3. Saved the value to the db/backend
      this.saveDarkMode(val);
    },
  },

  methods: {
    postSavedInfo(savedInfo) {
      apiPost('savedInfo', savedInfo).then(() => {
        window.location.reload();
      });
    },
    saveDarkMode(darkMode) {
      let IntValue = '';

      if (darkMode) {
        IntValue = 1;
      } else {
        IntValue = 0;
      }
      const usr = this.$store.state.user;
      const savedInfo = {
        TypeName: 'web-dark-layout',
        Name: usr.userName,
        IntValue,
      };
      apiPost('savedInfo', savedInfo);
    },

    saveSetting(event, value) {
      // Declare all settings here.
      const strValue = {
        numberFormat: this.$store.state.settings.numberFormat,
        weekStartDay: this.$store.state.gatcomponents.calendarSettings.weekStartDay,
        weekNumberInCalendar: this.$store.state.gatcomponents.calendarSettings.weekNumberInCalendar,
        dateFormat: this.$store.state.settings.dateFormat,
      };

      if (event == 'numberFormat') {
        strValue.numberFormat = value;
        this.$store.commit('setNumberFormat', value);
      }
      if (event == 'weekStartDay') {
        strValue.weekStartDay = value;
        this.$store.commit('setWeekStartDay', value);
      }
      if (event == 'weekNumberInCalendar') {
        strValue.weekNumberInCalendar = value;
        this.$store.commit('setWeekNumberInCalendar', value);
      }
      if (event == 'dateFormat') {
        strValue.dateFormat = value;
        this.$store.commit('setDateFormat', value);
      }
      const savedInfo = {
        TypeName: 'web-app-settings',
        strValue: JSON.stringify(strValue),
      };

      this.postSavedInfo(savedInfo);
    },
    getBackendVersion() {
      let path = '';
      if (window.location.href.includes('localhost')) {
        path = 'https://localhost:44345/';
      } else if (window.location.hostname.includes('crm.gatship.com')) {
        // path = 'https://gatship-crm-api.azurewebsites.net/';
        path = 'https://app-gs-crm-api.azurewebsites.net/';
      } else {
        path = `https://${window.location.hostname}:8080/`;
      }
      if (path.length > 0) {
        axios.get(path).then((response) => {
          const buildString = response.data.substring(response.data.indexOf('\n') + 1).trim();
          this.buildNr = buildString.substr(7);
        });
      }
    },
  },
};
</script>

<style scoped>
.gat-api-buildnr {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
</style>
