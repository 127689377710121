<template>
  <div>
    <gat-form v-model="isValid" ref="form" class="mt-6" :isLoading="isLoading">
      <GatGroup title="Agency details" :compact="true">
        <v-layout wrap>
          <gat-edit
            v-model="item.TSA_SERIAL"
            label="Serial no"
            :autofocus="true"
            :required="true"
            size="m"
            :maxChars="50">
          </gat-edit>
          <gat-edit v-model="item.TSA_AGENCY" label="Agency name" :required="true" size="m" :maxChars="250"> </gat-edit>
          <gat-edit v-model="item.TSA_SHORTNAME" label="Group name" size="s" :maxChars="50"> </gat-edit>
          <gat-edit v-model="item.AGENCY_COUNTRY" label="Country" size="s" :maxChars="100"> </gat-edit>
        </v-layout>
      </GatGroup>
      <GatGroup title="Map details" :compact="true">
        <v-layout wrap>
          <gat-edit v-model="item.TSA_LAT" label="Latitude" size="m" :required="true" dataType="number" :decimals="7">
          </gat-edit>
          <gat-edit v-model="item.TSA_LONG" label="Longitude" size="m" :required="true" dataType="number" :decimals="7">
          </gat-edit>
          <gat-edit v-model="item.TSA_ZOOM" label="Zoom" size="xs" :required="true" dataType="number"> </gat-edit>
        </v-layout>
      </GatGroup>

      <!-- <iframe width="600" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=loc:51.03841,-114.01679&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" /> -->
      <iframe
        class="pa-2"
        v-if="googleMapUrl != ''"
        width="600"
        height="500"
        id="gmap_canvas"
        :src="googleMapUrl"
        frameborder="0"
        scrolling="no"
        marginheight="0"
        marginwidth="0" />
    </gat-form>
    <gat-bottom-panel
      :orgValue="orgItem"
      :newValue="item"
      :itemDescription="'agency location'"
      :saveBtn="true"
      :deleteBtn="true"
      :duplicateBtn="!isNew && !valueChanged"
      :buttonsEnabled="!saving"
      :deleteDisabled="!item.TSA_ID || isNew"
      @saveClicked="saveItem()"
      @duplicateClicked="duplicateItem(item.TSA_ID)"
      @deleteClicked="deleteItem(item.TSA_ID)">
    </gat-bottom-panel>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { apiGet, apiPut, apiDelete } from '@/store/api';
import GatUtils from '@/app-components/GatUtils';

export default {
  name: 'AgencyLocationForm',
  props: {
    isNew: {
      type: Boolean,
      default: false,
    },
    agencyLocationId: {
      type: Number,
      default: -1,
    },
  },
  components: {},
  data() {
    return {
      saving: false,
      orgItem: {},
      item: {
        TSA_ID: null,
        TSA_SERIAL: null,
        TSA_AGENCY: null,
        TSA_SHORTNAME: null,
        AGENCY_COUNTRY: null,
        TSA_LAT: null,
        TSA_LONG: null,
        TSA_ZOOM: null,
      },
      isValid: true,
      isLoading: false,
    };
  },

  activated() {
    this.item = {}; // reset item
    this.orgItem = {}; // reset item
    if (!this.isNew) {
      this.getItem();
    }
  },

  created() {},
  deactivated() {},

  watch: {},

  computed: {
    googleMapUrl() {
      if (this.item.TSA_LONG && this.item.TSA_LAT) {
        let zoom = this.item.TSA_ZOOM * 2;
        if (!zoom) {
          zoom = 9;
        }
        return `https://maps.google.com/maps?q=loc:${this.item.TSA_LAT}, ${this.item.TSA_LONG}&z=${zoom}&ie=UTF8&iwloc=&output=embed`;
      }
      return '';
    },

    valueChanged() {
      return !this.globalObjectsAreAlike(this.orgItem, this.item);
    },
  },

  methods: {
    duplicateItem() {
      this.isNew = true;
      this.orgItem = {};
      this.item.TSA_ID = undefined;
      this.item.TSA_SHORTNAME = undefined;
      this.item.TSA_LAT = undefined;
      this.item.TSA_LONG = undefined;
    },
    getItem() {
      this.isLoading = true;
      apiGet(`/agencylocation/${this.agencyLocationId}`).then((result) => {
        // remove progressbar
        this.isLoading = false;
        this.item = result;
        this.orgItem = GatUtils.makeCopyViaJson(this.item);
      });
    },

    saveItem() {
      this.$refs.form.validate();
      if (this.isValid) {
        this.saving = true;
        // set isLoading to true to show progressbar if response is not immidiate
        this.isLoading = true;

        apiPut('/agencylocation/', { orgValue: this.orgItem, value: this.item }).then((result) => {
          // remove progressbar
          this.isLoading = false;
          this.saving = false;
          if (result.resultCategory == 1) {
            this.item = {}; // reset item
            this.orgItem = {}; // reset item
            this.isNew = false;
            this.$nextTick(() => {
              this.$router.push('/crm/agency-locations');
            });
          }
        });
      }
    },

    deleteItem(id) {
      // set isLoading to true to show progressbar if response is not immidiate
      this.isLoading = true;
      apiDelete(`/agencylocation/${id}`).then((result) => {
        // remove progressbar
        this.isLoading = false;
        if (result.resultCategory == 1) {
          this.item = {}; // reset item
          this.orgItem = {}; // reset item
          this.isNew = false;
          this.$nextTick(() => {
            this.$router.push('/crm/agency-locations');
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
