<template>
  <div>
    <v-radio-group
      v-model="tableChoice"
      :row="!this.$vuetify.breakpoint.xsOnly"
      :class="getClass"
      v-on:change="updateTableChoices()">
      <span id="showHeader">Show: </span>
      <v-radio label="Tasks & people" v-bind:value="0" v-on:click.stop></v-radio>
      <v-radio label="Tasks only" v-bind:value="1" v-on:click.stop></v-radio>
      <v-radio label="People only" v-bind:value="2" v-on:click.stop></v-radio>
    </v-radio-group>
  </div>
</template>

<script>
import { apiPost } from '@/store/api';

export default {
  name: 'CrewChangeFilterTablePicker',
  props: [],
  components: {},
  data() {
    return {
      tableChoice: null,
    };
  },

  mounted() {
    if (this.$store.state.user.userSettings.crewChange.showTables != null) {
      this.tableChoice = this.$store.state.user.userSettings.crewChange.showTables;
    } else {
      this.$store.dispatch('getCrewChangeTablesShow').then(() => {
        this.tableChoice = this.$store.state.user.userSettings.crewChange.showTables;
      });
    }
  },

  created() {},

  watch: {
    tableChoice() {
      if (this.tableChoice != null) {
        window.messageBus.$emit('showTableinCCM', this.tableChoice);
      }
    },
  },

  computed: {
    getClass() {
      if (!this.$vuetify.breakpoint.xsOnly) {
        return 'slotBtn pt-1 pr-1';
      }
      return '';
    },
  },

  methods: {
    updateTableChoices() {
      let IntValue = 0;
      if (this.tableChoice === 0) {
        IntValue = 0;
      } else if (this.tableChoice === 1) {
        IntValue = 1;
      } else if (this.tableChoice === 2) {
        IntValue = 2;
      }
      const savedInfo = {
        TypeName: 'web-ccm-grid-show',
        Name: this.$store.state.user.userName,
        IntValue,
      };
      apiPost('savedInfo', savedInfo);
    },
  },
};
</script>

<style scoped>
#showHeader {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.54);
  margin-right: 6px;
}
.slotBtn {
  position: absolute;
  top: 0.4rem;
  right: 0px;
  margin-top: 0px;
}
</style>
