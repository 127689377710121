/* eslint-disable no-param-reassign */
// eslint-disable-next-line @typescript-eslint/naming-convention
const vuex_toolbar = {
  state: {
    title: '',
    tabs: undefined,
    backNavigation: [],
    useToolbarNavBtns: false,
    navBtnName: '',
    peviousNavBtnState: { name: '', show: false },
  },

  mutations: {
    setBackNavigation(state, data) {
      state.backNavigation = data;
    },
    setToolbarNavBtns(state, data) {
      if (data.previousState) {
        state.useToolbarNavBtns = state.peviousNavBtnState.show;
        state.navBtnName = state.peviousNavBtnState.name;
      } else {
        state.useToolbarNavBtns = data.show;
        state.navBtnName = data.name;
        if (!data.doNotRegisterState) {
          state.peviousNavBtnState = data;
        }
      }
    },
    setToolbarTitle(state, title) {
      state.title = title;
    },
    setToolbarTitleWithSecondary(state, data) {
      let { title } = data;
      if (data.secondary) {
        title = `${title} (${data.secondary})`;
      }
      state.title = title;
    },

    setToolbarTabs(state, tabs) {
      state.tabs = tabs;
    },
  },

  actions: {},

  getters: {},
};

export default vuex_toolbar;
