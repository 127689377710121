<template>
  <v-dialog :value="visible" persistent max-width="600">
    <gat-form ref="form">
      <v-card class="px-3">
        <v-card-subtitle class="py-0 px-0 my-0 text-overline">Port call {{ cardSubtitle }}</v-card-subtitle>
        <v-card-title class="py-0 pt-0 my-0"
          ><span style="text-transform: uppercase; margin-right: 4px">{{ cardTitleType }}</span>
          {{ cardTitle }}</v-card-title
        >
        <v-layout wrap>
          <gat-client-select
            size="xs12"
            label="Client"
            v-model="hotlistItem.CLIENT_ID"
            :autofocus="!hotlistItem.CLIENT_ID"
            :required="true"
            customItem
            showClientWarning
            showClientCreditLimit />
          <gat-select
            v-model="clientSelect"
            :items="$store.state.helper.clientTypes"
            label="Role"
            textFieldName="NAME"
            codeFieldName="ID"
            size="xs12 sm6"
            :required="true">
          </gat-select>
          <gat-edit
            v-model="hotlistItem.WAP_USE_WM"
            label="Use webmodule"
            :checkbox="true"
            :valuechecked="1"
            :valueUnchecked="0"
            size="ml"></gat-edit>
          <gat-dyna-fields
            v-model="hotlistItem.dynaValues"
            componentName="pDynaFields.HotlistDyna"
            container="pDynaFields"></gat-dyna-fields>
        </v-layout>

        <!-- <record-presenter :value="hotlistItem" /> -->

        <v-card-actions>
          <v-btn text @click="deleteDialog = true">Delete</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close">Cancel</v-btn>
          <v-btn color="primary" @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>

      <v-dialog v-model="deleteDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="title">Remove this client from the list?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="deleteDialog = false">Cancel</v-btn>
            <v-btn text color="primary" @click="deleteItem">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </gat-form>
  </v-dialog>
</template>

<script>
import GatUtils from '@/app-components/GatUtils';
import GatClientSelect from '@/app-components/GatClientSelect.vue';
import GatDynaFields from '@/app-components/dynafields/GatDynaFields.vue';
import { apiPut, apiDelete } from '@/store/api';

export default {
  name: 'HotlistForm',
  props: ['value', 'visible'],
  components: { GatClientSelect, GatDynaFields },
  data() {
    return {
      hotlistItem: {},
      deleteDialog: false,
    };
  },

  activated() {},

  computed: {
    cardTitleType() {
      return this.value.H_ID ? 'Edit ' : 'Add ';
    },
    cardTitle() {
      return ' Client contact';
    },
    cardSubtitle() {
      return this.$store.state.portcall.callDetails.PORTCALL_NUMBER;
    },
    clientSelect: {
      get() {
        const id = this.hotlistItem.CLIENT_ROLE_ID || null;
        return id;
      },
      set(newValue) {
        this.hotlistItem.CLIENT_ROLE_ID = newValue;
      },
    },
  },

  created() {},

  watch: {
    value(val) {
      if (!val.dynaValues) {
        // eslint-disable-next-line no-param-reassign
        val.dynaValues = [];
      }
      this.hotlistItem = GatUtils.makeCopyViaJson(val);
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },
    deleteItem() {
      const id = this.hotlistItem.H_ID;
      apiDelete(`hotlist/${id}`).then(() => {
        this.$store.commit('removeHotlistItem', id);
      });

      this.$emit('close');
    },
    save() {
      if (this.$refs.form.validate()) {
        apiPut('hotlist', {
          value: this.hotlistItem,
          orgValue: this.value,
          dynaValues: this.hotlistItem.dynaValues,
          orgDynaValues: this.value.dynaValues,
        }).then((result) => {
          if (result.payload) {
            this.$store.commit('setHotlist', result.payload);
          }
        });

        this.$emit('close');
      }
    },
  },
};
</script>

<style scoped></style>
