/* eslint-disable no-prototype-builtins */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
// eslint-disable-next-line import/no-cycle
import { apiPost, apiGet, apiDelete } from './api';

function duplicateViaJson(obj) {
  let result = {};
  if (obj) {
    const jsonStr = JSON.stringify(obj);
    result = JSON.parse(jsonStr);
  }
  return result;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const vuex_gatcomponents = {
  state: {
    gridViews: [],
    defaultGridViews: {},
    input_dense: true,
    calendarSettings: { weekNumberInCalendar: 'iso', weekStartDay: 'monday' },
  },

  mutations: {
    removeGatGridView(state, data) {
      const idx = state.gridViews.findIndex((item) => item.name == data.name);
      if (idx >= 0) {
        state.gridViews.splice(idx, 1);
      }
      const savedInfo = {
        TypeName: `web_grid_view.${data.viewGroupName}.${data.name}`,
      };
      if (data.isGlobal) {
        savedInfo.Default = true;
      }
      apiDelete('savedInfo', savedInfo);
    },

    setDefaultGridViews(state, data) {
      state.defaultGridViews = data;
    },

    setGatGridView(state, data) {
      const view = duplicateViaJson(data);
      const idx = state.gridViews.findIndex((item) => item.name == view.name);
      if (idx >= 0) {
        state.gridViews.splice(idx, 1, view);
      } else {
        state.gridViews.push(view);
      }

      const viewData = duplicateViaJson(data);
      delete viewData.isMyDefault; // skal ikke lagres på denne måten (view er felles, myDefault er per bruker)
      delete viewData.isDefault;

      let savedInfo = {
        TypeName: `web_grid_view.${data.viewGroupName}.${data.name}`,
        StrValue: JSON.stringify(viewData),
      };
      if (data.isGlobal) {
        savedInfo.Default = true;
      }
      apiPost('savedInfo', savedInfo);

      // my default endret?
      if (data.isMyDefault) {
        if (
          !(
            state.defaultGridViews.hasOwnProperty(data.viewGroupName) &&
            state.defaultGridViews[data.viewGroupName] == data.name
          )
        ) {
          savedInfo = {
            TypeName: `web_default_grid_view.${data.viewGroupName}`,
            StrValue: data.name,
            StrValue2: data.viewGroupName,
          };
          apiPost('savedInfo', savedInfo);
          state.defaultGridViews[data.viewGroupName] = data.name;
        }
      } else if (
        state.defaultGridViews.hasOwnProperty(data.viewGroupName) &&
        state.defaultGridViews[data.viewGroupName] == data.name
      ) {
        savedInfo = {
          TypeName: `web_default_grid_view.${data.viewGroupName}`,
          StrValue: data.name,
          StrValue2: data.viewGroupName,
        };
        apiDelete('savedInfo', savedInfo);
        delete state.defaultGridViews[data.viewGroupName];
      }
    },

    setGatGridViews(state, data) {
      state.gridViews = data;
    },

    setWeekNumberInCalendar(state, weekNoSettings) {
      state.calendarSettings.weekNumberInCalendar = weekNoSettings;
    },
    setWeekStartDay(state, weekStartDay) {
      state.calendarSettings.weekStartDay = weekStartDay;
    },
  },

  actions: {
    getAllGatGridViews({ commit }) {
      return new Promise((resolve) => {
        const getAllGridViews = apiGet('savedinfo/web_grid_view.').then((data) => {
          if (data.length > 0) {
            const views = [];
            data.map((savedInfo) => {
              const view = JSON.parse(savedInfo.MEMOVALUE1);
              // sort columns
              view.columns.sort((a, b) => a.index - b.index);
              views.push(view);
            });
            commit('setGatGridViews', views);
          }
        });
        const getAllDefaultGridViews = apiGet('savedinfo/web_default_grid_view.').then((data) => {
          const defaultViews = {};
          data.map((savedInfo) => {
            if (savedInfo && savedInfo.MEMOVALUE2) {
              defaultViews[savedInfo.MEMOVALUE2] = savedInfo.MEMOVALUE1;
            }
          });
          commit('setDefaultGridViews', defaultViews);
        });
        Promise.allSettled([getAllGridViews, getAllDefaultGridViews]).finally(() => {
          resolve();
        });
      });
    },
  },

  getters: {},
};

export default vuex_gatcomponents;
