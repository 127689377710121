import Vue from 'vue';
import gsComponents from '@gatship/gs-components';
import rg4js from 'raygun4js';
import { v4 as uuidv4 } from 'uuid';
import { isValidBrowser, browserName, browserVersion } from './store/browserDetect';
import App from './App.vue';
import router from './router/router';
import store from './store/app-store';
import vuetify from './plugins/vuetify';
import { ApplicationMixin } from './app-components/mixins/applicationMixin';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import './registerServiceWorker';
import { enforceMaxChars } from './globalFlags';

rg4js('apiKey', 'xELDU0prx0XpaNEKSTGdvQ');
rg4js('detach');
rg4js('onBeforeSend', (payload) => { 
  if (window.location.hostname === 'localhost'){
    return false;
  }
  const result = {...payload};
  result.Details.Version = store.state.application.version || 'Unknown';
  return result;
});

// https://stackoverflow.com/questions/66120513/property-does-not-exist-on-type-window-typeof-globalthis
declare global {
  interface Window {
    messageBus?: any;
  }
}

if (isValidBrowser()) {
  gsComponents.install(Vue, { enforceMaxChars });
  Vue.mixin(ApplicationMixin);
  window.messageBus = new Vue({});

  Vue.config.productionTip = false;

  Vue.config.errorHandler = (err, vm, info) => {
    const guid = uuidv4();
    window.messageBus.$emit('showMessage', { type: 'error', text: 'An error occured', subText: err.message || '', traceId: guid, icon: true });        
    if (window.location.hostname === 'localhost'){
      console.log("Vue ErrorHandler",err);
      return;
    }    
    const serial = store.state.globalSettings.SGL_SERIAL || '';
    const db = localStorage.getItem('db') || '';
    const path = window.location.href || '';
    rg4js('send', {
      error: err,
      customData: [{traceId:guid},{serialNo:serial},{db},{path},{ info }],
      tags: [`TraceId:${guid}`,`SerialNo:${serial}`],
    });    
  };

  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount('#app');
} else {
  document.write(
    `<p style='text-align:center'>${browserName()} ${browserVersion()} is not supported for the GAT-Ship Web Module. <br> Please update your browser or use another browser.</p>`
  );
}
