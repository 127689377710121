<template>
  <div style="position: relative">
    <div
      style="position: absolute; bottom: 15px; right: 0"
      class="d-flex flex-column"
      v-if="!$vuetify.breakpoint.xsOnly">
      <v-btn
        small
        class="pa-0 mr-1 mb-1"
        width="30px"
        max-width="30px"
        min-width="30px"
        @click="setUserZoom(userZoom + 1)"
        >+</v-btn
      >
      <v-btn
        small
        class="pa-0 mr-1 mb-3"
        width="30px"
        max-width="30px"
        min-width="30px"
        @click="setUserZoom(userZoom - 1)"
        >-</v-btn
      >
    </div>
    <iframe class="vtmap" :src="url"> </iframe>
  </div>
</template>

<script>
export default {
  name: 'VesselCurrentAIS',
  props: ['imo', 'portcallStatus'],
  components: {},
  data() {
    return {
      userZoom: undefined,
      isUserZoom: false,
    };
  },

  created() {
    this.userZoom = this.map_zoom;
  },

  watch: {},

  computed: {
    vesselTrackerSerialNo() {
      return this.$store.state.globalSettings.SGL_SERIAL;
    },
    agencySettings() {
      if (this.$store.state.agencySettings) {
        const index = this.$store.state.agencySettings.findIndex(
          (item) => item.ID == this.$store.state.application.agencyIdSelected
        );
        return this.$store.state.agencySettings[index];
      }
      return [];
    },
    map_height() {
      return 150;
    },
    map_zoom() {
      if (this.isUserZoom) {
        this.isUserZoom = false;
        return +this.userZoom;
      }
      // Expected
      if (this.portcallStatus === 0) {
        if (this.agencySettings && this.agencySettings.VT_ZOOM_EXP) {
          return this.agencySettings.VT_ZOOM_EXP;
        }
        return 6;
      }
      // Anchored
      if (this.portcallStatus === 1) {
        if (this.agencySettings && this.agencySettings.VT_ZOOM_ANCHORED) {
          return this.agencySettings.VT_ZOOM_ANCHORED;
        }
        return 15;
      }
      // Berthed
      if (this.portcallStatus === 2) {
        if (this.agencySettings && this.agencySettings.VT_ZOOM_BERTHED) {
          return this.agencySettings.VT_ZOOM_BERTHED;
        }
        return 15;
      }
      if (this.agencySettings && this.agencySettings.VT_DEFAULT_ZOOM) {
        return this.agencySettings.VT_DEFAULT_ZOOM;
      }
      return 5;
    },

    showMapControls() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return 1;
      }
      return 0;
    },

    url() {
      let u = '';
      if (this.imo && this.imo.length > 0 && this.vesselTrackerSerialNo) {
        // return "https://www.vesseltracker.com/static-sites/vt-partners/?serial=AR21Q940J8GQ0G809B&imo="+this.imo+"&zoom="+this.map_zoom;
        u = `https://www.vesseltracker.com/vesselmap/gatship.html?imo=${this.imo}&z=${this.map_zoom}&h=${this.map_height}&w=${this.map_width}&c=${this.showMapControls}&t=1&serial=${this.vesselTrackerSerialNo}&version=wm2.0`;
      }
      return u;
    },
  },

  methods: {
    setUserZoom(value) {      
      let result = value;
      if (result > 18) {
        result = 18;
      }

      if (result < 1) {
        result = 1;
      }
      this.isUserZoom = true;
      this.userZoom = +result;
    },

  },
};
</script>

<style scoped>
.vtmap {
  width: 100%;
  height: 165px;
  border: none;
  margin: 0px;
  padding: 0px;
  margin-bottom: -10px;
}
</style>
