// eslint-disable-next-line import/no-cycle
import { apiGet, apiPut } from '@/store/api';

export const actions = {
  checkOutAttendanceItem({ commit }, { orgValue, value }) {
    return new Promise((resolve, reject) => {
      apiPut(`attendance/checkout/${orgValue.ID}/${+1}`, {
        orgValue,
        value,
      })
        .then((result) => {
          if (result.resultCategory == 1) {
            commit('setAttendanceItemCheckout', result.payload);
            commit('setInAttendance', {});
            resolve(result.payload);
          } else if (result.resultCategory == 2) {
            // Attendance has already been checked out.
            resolve('No changes');
          } else {
            reject(result);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAttendanceCodes({ commit }) {
    return new Promise((resolve, reject) => {
      apiGet('/attendance/codes')
        .then((data) => {
          commit('setAttendanceCodes', data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getBerthedPortcalls({ commit }) {
    return new Promise((resolve, reject) => {
      apiGet('attendance/portcalls/')
        .then((data) => {
          commit('setBerthedPortcalls', data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getInAttendance({ commit }) {
    return new Promise((resolve, reject) => {
      apiGet('attendance/isActive/')
        .then((data) => {
          commit('setInAttendance', data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getPreviousAttendances({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      apiGet('/attendances')
        .then((data) => {
          commit('setPreviousAttendances', data);
          dispatch('getInAttendance');
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getPortcallAttendances({ commit }, callId) {
    return new Promise((resolve, reject) => {
      apiGet(`/attendances/portcall/${callId}`)
        .then((data) => {
          commit('setPortcallAttendaces', data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
