<template>
  <div>

    <h2>An error occured.</h2>
    <br>
    <b>{{lastError.message}}</b><br>
    {{lastError.stack}}

  </div>
</template>

<script>
  export default {
    name: 'ErrorPage',

    computed:{
      lastError(){
        return this.$store.state.application.lastError;
      }
    },
    }
</script>

<style scoped>

</style>
