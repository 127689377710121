
import Vue from 'vue';
// eslint-disable-next-line import/no-cycle
import { apiGet, apiPut } from '@/store/api';
import EmailGenerator from '@/app-components/EmailGenerator.vue';
import { v4 as uuidv4 } from 'uuid';
import GatUtils from '@/app-components/GatUtils';
import WmButtonGroup from '../../../app-components/WmButtonGroup.vue';

export default Vue.extend({
    name: 'ProspectsList',
    props: {
      callId: [String, Number]
    },
    components: {WmButtonGroup, EmailGenerator, GatUtils},

    data() {
        return  {
             prospectItems : [],
             selectedIds : [],
             emailTemplates : [],
             emailTemplateGuid : undefined,
             showEmailStatusDialog : false,
        };
    },

    computed:{
      callDetails() {
        return this.$store.state.portcall.callDetails;
      },
      columns() {
        const result = [];
        result.push({ header: 'Name', field: 'CHO_NAME', hideOnPhone: false});
        result.push({ header: 'Subject', field: 'CHO_SUBJECT', hideOnPhone: false});
        result.push({ header: 'Updated', field: 'Updated', hideOnPhone: false});
        result.push({ header: 'Updated by', field: 'CHO_LAST_UPDATED_BY', hideOnPhone: false});
        result.push({ header: 'Client', field: 'Client', hideOnPhone: true});
        result.push({ header: 'Cargo', field: 'Cargo', hideOnPhone: true});
        result.push({ header: 'SOF', field: 'Sof', hideOnPhone: true});

        return result;
      },
      selectedProspectId(){        
        return this.selectedIds[0];
      },
      emailTemplateItems() {
        const templates = [...this.emailTemplates];
        let result : any = [];
        if (Array.isArray(templates) && templates.length > 0) {
          result = templates.map((item : any) => ({
            caption: item.TITLE,
            eventName: 'createEmailClick',
            icon: 'mdi-file-outline',
            ID: item.ID,
          }));
        }
        return result;
      },
      applicationStatus(){
        return this.$store.getters.getAppStatus;
      },
    },

    activated(){
      if(this.callId)
        this.getProspectLines(this.callId);
    },

    created(){
    },

    watch:{
      callId: {
        handler(val) {
          this.getProspectLines(val);
        },
        immediate: true,
      },
    },

    methods:{
      hasAgencySetting(value : string) {
        value.trim();
        let result = 0;
        const portcallAgency = this.callDetails.SETUP_ID; // Agency to which the current portcall belongs.
        if (portcallAgency) {
          const idx = this.$store.state.agencySettings.findIndex((item : any) => item.ID == portcallAgency); // Find agencySettings for portcallAgency
          if (idx >= 0) {
            const agency = this.$store.state.agencySettings[idx]; // Store all the agency settings.
            result = agency[value]; // Check the agency setting for the given argument.
          }
        }
        return result == 1;
      },
      getProspectLines(portCallId : string | number){
        this.prospectItems = [];     
        this.emailTemplates = [];
        
        apiGet(`prospects/${portCallId}`).then((response : any) => {
          this.prospectItems = response;
          this.$store.commit('setTabHasData', { tabName: 'PROSPECTS', value: response.length });
        });

        apiGet(`mail/templates/prospects/${this.callDetails.SETUP_ID}`).then((result : any) => {
          if (result) {
            this.emailTemplates = result;
          }
        });

        const param = GatUtils.makeCopyViaJson(this.$store.state.portcall.sendEmailProspects);
        this.$store.commit('setSendEmailProspects', undefined);
        this.sendEmail(param);
      },
      onRowClicked(item : any){
        if (item && item.CHO_ID)
          this.$router.push({ name: 'prospectDetails', params: { prospectId: item.CHO_ID } });
      },
      gridSelectionChanged(item : any){
        this.$store.commit('setAppStatusSelectedProspectId', item[0]);
        this.selectedIds = item;
      },
      createEmailClick(item : any){
        if (this.callId) {
          const guid = uuidv4();
          const templateId = item.ID;
          const location = 'portcall';
          const foreignKey = this.callId;
          const appStatus = JSON.stringify(this.applicationStatus);
          const param = { guid, templateId, location, foreignKey, appStatus };
          this.sendEmail(param);
        }
      },
      sendEmail(param : any){
        if (param && param.guid && param.location && param.foreignKey && param.appStatus && param.templateId){
          const {guid} = param;
          apiPut('document/generate/', param).then(() => {
            this.emailTemplateGuid = guid;
            this.showEmailStatusDialog = true;
          });
        }
      },
      wmButtonGroup() {
        const result = [];
        result.push({
          btnType: 'CREATE_DOC',
          docType: 19,
          agencyId: this.callDetails.SETUP_ID,
          applicationStatus: this.applicationStatus,
          location: 'portcall',
          foreignKey: this.callId,
          disabled: this.selectedIds.length !== 1,
        });        
        result.push({
          btnType: 'CUSTOM',
          caption: 'Create email',
          icon: 'mdi-email',
          menuItems: this.emailTemplateItems,
          disabled: this.selectedIds.length !== 1 || this.emailTemplateItems.length === 0,
        });        
        return result;
      },
      formatProspectSemicolonString(item : any) {
        let values = null;
        let value = item;
        if (item) {
          values = item.split(';');
          if (typeof values == 'object' && values.length >= 2) {
            // eslint-disable-next-line prefer-destructuring
            value = values[0];
          }
          values.splice(0, 1);
        }
        return { value, values };
      },
    },
});
