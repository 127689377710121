/* eslint-disable no-param-reassign */
export const mutations = {
  setReportData(state, { reportName, reportData }) {
    const payload = { reportName, reportData };
    const idx = state.reportData.findIndex((item) => item.reportName === reportName);
    if (idx >= 0) {
      state.reportData[idx].reportData = reportData;
    } else {
      state.reportData.push(payload);
    }
  },
  setDashboardGroups(state, data) {
    state.main.dashboardGroups = data;
  },
  setEmbeddedDashboardReports(state, { location, reports, sorting }) {
    state[location].reports = reports;
    if (!state[location].settingsRetrieved) {
      state[location].settingsRetrieved = false;
    }
    if (!state[location].sorting && sorting) {
      state[location].sorting = sorting;
    }
  },
  // eslint-disable-next-line consistent-return
  setDashboardSorting(state, { location, dashboardGroupName, sorting, settingName }) {
    if (!location) return console.error('Location was not provided.');
    if (!settingName) settingName = 'user';
    if (dashboardGroupName) {
      // For dashboard groups.
      const idx = state[location].dashboardGroups.findIndex(
        (group) => group.NAME.trim().replace(' ', '').toLowerCase() === dashboardGroupName
      );
      if (idx >= 0) {
        state[location].dashboardGroups[idx].sorting[settingName] = sorting;
      }
    } else {
      // For embedded dashboards.
      const stateCopy = { ...state[location] };
      stateCopy.sorting[settingName] = sorting;
      state[location] = stateCopy;
    }
  },
  // eslint-disable-next-line consistent-return
  setReportSettings(state, { location, dashboardGroupName, reportName, settings, settingName }) {
    if (!location || !reportName) return console.error('location and/or reportName was not provided.');
    if (!settingName) settingName = 'userSettings';
    if (dashboardGroupName) {
      // For dashboard groups.
      const idx = state[location].dashboardGroups.findIndex(
        (group) => group.NAME.trim().replace(' ', '').toLowerCase() === dashboardGroupName
      );
      if (idx >= 0) {
        const idx2 = state[location].dashboardGroups[idx].reports.findIndex(
          (report) => report.reportName === reportName
        );
        if (idx2 >= 0) {
          state[location].dashboardGroups[idx].reports[idx2][settingName] = settings; // set the settings.
          if (!state[location].dashboardGroups[idx].settingsRetrieved) {
            state[location].dashboardGroups[idx].settingsRetrieved = true;
          }
        }
      }
    } else {
      // For embedded dashboards.
      const idx = state[location].reports.findIndex((report) => report.reportName === reportName);
      if (idx >= 0) {
        state[location].reports[idx][settingName] = settings;
        if (!state[location].settingsRetrieved) {
          state[location].settingsRetrieved = true;
        }
      }
    }
  },
};
