<template>
  <record-presenter :value="$store.state" />
</template>

<script>
export default {
  name: 'ApplicationState',
  props: [],
  components: {},
  data() {
    return {};
  },

  created() {},

  watch: {},

  methods: {},
};
</script>

<style scoped></style>
