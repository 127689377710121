<template>
  <GatGroup class="pa-0" title="SOF details" :collapsed="true">
    <!-- {{value}} -->
    <v-layout wrap>
      <gat-edit label="Title" v-model="value.TITLE" :maxChars="1000" :readonly="readOnly" :disabled="disabled" />
      <gat-edit label="From / to" v-model="value.FROM_TO" :maxChars="50" :readonly="readOnly" :disabled="disabled" />
      <gat-edit
        label="Page footer"
        v-model="value.PAGE_FOOTER"
        :maxChars="50"
        :readonly="readOnly"
        :disabled="disabled" />

      <gat-select
        label="Quay"
        v-model="value.QUAY_ID"
        :items="berths"
        codeFieldName="DOCK_ID"
        :maxChars="0"
        :readonly="readOnly"
        :disabled="disabled">
      </gat-select>
      <gat-select
        label="Anchorage"
        v-model="value.ANCHORAGE"
        :items="anchorage"
        :maxChars="0"
        :readonly="readOnly"
        :disabled="disabled">
      </gat-select>

      <gat-edit
        label="Sort order"
        v-model="value.SORT_ORDER"
        size="s"
        :maxChars="0"
        :readonly="readOnly"
        :disabled="disabled" />
    </v-layout>
    <v-layout wrap>
      <gat-edit
        label="Cargo description"
        v-model="value.CARGO_DESCRIPTION"
        :rows="5"
        size="m"
        :maxChars="2500"
        :readonly="readOnly"
        :disabled="disabled" />
      <gat-edit
        label="Remarks"
        v-model="value.REMARKS"
        :rows="5"
        size="l"
        :maxChars="2500"
        :readonly="readOnly"
        :disabled="disabled" />
    </v-layout>
    <template slot="compact">
      <v-layout wrap>
        <gat-compact-field :value="value.TITLE" size="s" label="Title" class="hidden-sm-and-down" />
        <gat-compact-field :value="value.FROM_TO" size="s" label="From / to" />
        <gat-compact-field :value="value.PAGE_FOOTER" size="s" label="Page footer" class="hidden-sm-and-down" />
        <gat-compact-field :value="getQuayName(value.QUAY_ID)" size="s" label="Quay" />
        <gat-compact-field :value="getAnchrorageName(value.ANCHORAGE)" size="s" label="Anchorage" />
        <gat-compact-field :value="value.SORT_ORDER" size="s" label="Sort order" />
        <gat-compact-field
          :value="value.CARGO_DESCRIPTION"
          size="l"
          label="Cargo description"
          :rows="5"
          class="hidden-sm-and-down" />
        <gat-compact-field :value="value.REMARKS" size="l" label="Remarks" :rows="5" class="hidden-sm-and-down" />
      </v-layout>
    </template>
  </GatGroup>
</template>

<script>
export default {
  name: 'SofDetail',
  props: {
    value: [Object, Array],
    berths: [Object, Array],
    anchorage: [Object, Array],
    readOnly: Boolean,
    disabled: Boolean,
  },
  components: {},
  data() {
    return {};
  },

  created() {},

  watch: {},

  methods: {
    getQuayName(dockId) {
      return this.lookupCode(dockId, this.berths, 'DOCK_ID');
    },
    getAnchrorageName(ancId) {
      return this.lookupCode(ancId, this.anchorage);
    },
  },
};
</script>

<style scoped></style>
