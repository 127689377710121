<template>
  <v-navigation-drawer fixed permanent right class="elevation-1">
    <template v-slot:prepend>
      <div class="d-flex flex-column" ref="dashSettingsTop">
        <div>
          <v-btn small text class="right" color="primary" @click="$emit('close-clicked')"
            ><v-icon>close</v-icon>close</v-btn
          >
        </div>
      </div>
    </template>
    <template v-slot:append> </template>

    <v-list>
      <v-list-item link @click="$emit('refreshAllDashboards')">
        <v-list-item-icon><v-icon>mdi-cached</v-icon></v-list-item-icon>
        <v-list-item-content><v-list-item-title>Reload dashboard</v-list-item-title></v-list-item-content>
      </v-list-item>
      <v-list-group :value="true" prepend-icon="mdi-sort" no-action @click.stop>
        <template v-slot:activator>
          <v-list-item-title>Sorting</v-list-item-title>
        </template>
        <v-layout row>
          <v-card class="elevation-0 ml-2" color="transparent">
            <gat-edit label="Show hidden charts" v-model="showHiddenCharts" toggle size="xs12" class="ml-2" />
            <v-card-title class="text-subtitle-2 pb-0 pt-0">Sort list by drag and drop</v-card-title>
            <v-list dense class="overflow-y-auto" style="max-height: calc(100vh - 315px)">
              <draggable v-model="charts" group="graphs" @start="drag = true" @end="drag = false" @sort="onSorted">
                <v-list-item
                  v-for="element in charts"
                  :key="element.title"
                  class="dashBorder mb-1"
                  v-show="!element.hidden || showHiddenCharts">
                  <v-card class="pa-1" width="100%" color="grey lighten-3">
                    <v-icon>poll</v-icon>
                    {{ element.title }}
                    <v-icon v-if="element.hidden" class="right">visibility_off</v-icon>
                  </v-card>
                </v-list-item>
              </draggable>
            </v-list>
            <v-btn
              text
              class="right mr-3 mb-2"
              small
              :disabled="dashboardHasSortingType.user.length === 0"
              @click="deleteDashSorting(false)"
              >reset sorting</v-btn
            >
          </v-card>
        </v-layout>
      </v-list-group>
      <v-list-group :value="false" prepend-icon="mdi-settings" @click.stop>
        <template v-slot:activator>
          <v-list-item-title>Settings</v-list-item-title>
        </template>

        <v-list-item
          dense
          link
          :disabled="!canSaveAsDefault"
          class="ml-2"
          @click="showConfirmationDialog('resetUserSettings')">
          <!--  -->
          <v-list-item-icon
            ><v-icon :disabled="!canSaveAsDefault">{{ mdiAccountCog }}</v-icon></v-list-item-icon
          >
          <v-list-item-content><v-list-item-title>Reset to default</v-list-item-title></v-list-item-content>
        </v-list-item>
        <div v-if="$store.getters.userRight('DAS.ADMIN')">
          <v-subheader class="pa-0 ma-0 ml-4" style="height: 30px">Default settings</v-subheader>
          <v-list-item
            dense
            link
            class="ml-2"
            :disabled="!canSaveAsDefault"
            @click="showConfirmationDialog('saveDefault')">
            <v-list-item-icon><v-icon :disabled="!canSaveAsDefault">mdi-content-save</v-icon></v-list-item-icon>
            <v-list-item-content><v-list-item-title>Save as default</v-list-item-title></v-list-item-content>
          </v-list-item>

          <v-list-item dense link @click="showConfirmationDialog('setAllUsers')" class="ml-2">
            <v-list-item-icon><v-icon>mdi-account-convert</v-icon></v-list-item-icon>
            <v-list-item-content><v-list-item-title>Set all users to default</v-list-item-title></v-list-item-content>
          </v-list-item>

          <v-list-item
            :disabled="!dashboardHasDefaultSettings"
            dense
            link
            class="ml-2"
            @click="showConfirmationDialog('deleteDefault')">
            <v-list-item-icon
              ><v-icon color="red lighten-1" :disabled="!dashboardHasDefaultSettings"
                >mdi-delete</v-icon
              ></v-list-item-icon
            >
            <v-list-item-content
              ><v-list-item-title :class="dashboardHasDefaultSettings ? 'red--text' : ''"
                >Delete default</v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
        </div>
      </v-list-group>
    </v-list>
    <gat-dialog
      v-if="defaultSettingsDialog.showDialog"
      v-model="defaultSettingsDialog.showDialog"
      :okButtonCaption="defaultSettingsDialog.okButtonCaption"
      :title="defaultSettingsDialog.title"
      :okButtonIsProminent="defaultSettingsDialog.okButtonIsProminent"
      @okClick="defaultSettingsDialog.functionToCall">
      {{ defaultSettingsDialog.description }}
    </gat-dialog>

    <!-- <v-divider></v-divider>     -->
  </v-navigation-drawer>
</template>

<script>
import { mdiAccountCog } from '@mdi/js';
import draggable from 'vuedraggable';

export default {
  name: 'DashboardSettings',
  props: {
    sortItems: Array,
    location: [String, Number],
    dashboardGroupName: String,
    dashSorting: Array,
    dashboardHasSortingType: Object,
  },
  components: { draggable },
  data() {
    return {
      showHiddenCharts: false,
      charts: [],
      mdiAccountCog,
      defaultSettingsDialog: {
        showDialog: false,
      },
    };
  },

  created() {},

  computed: {
    canSaveAsDefault() {
      if (this.dashboardHasSettingsType.userSettings || this.dashboardHasSortingType.user.length > 0) {
        return true;
      }
      return false;
    },

    dashboardHasSettingsType() {
      const result = {
        initialSettings: false,
        userSettings: false,
        defaultSetting: false,
      };
      if (this.charts.length > 0) {
        this.charts.forEach((report) => {
          if (report.reportName && this.location) {
            const settings = this.$store.getters.getChartSettings({
              location: this.location,
              dashboardGroupName: this.dashboardGroupName,
              reportName: report.reportName,
              getSettingsName: true,
            });
            if (!result.initialSettings) {
              result.initialSettings =
                Object.keys(settings.initialSettings).length > 0 &&
                Object.getPrototypeOf(settings.initialSettings) === Object.prototype;
            }
            if (!result.userSettings) {
              result.userSettings =
                Object.keys(settings.userSettings).length > 0 &&
                Object.getPrototypeOf(settings.userSettings) === Object.prototype;
            }
            if (!result.defaultSettings) {
              result.defaultSettings =
                Object.keys(settings.defaultSettings).length > 0 &&
                Object.getPrototypeOf(settings.defaultSettings) === Object.prototype;
            }
          }
        });
      }

      return result;
    },
    dashboardHasDefaultSettings() {
      if (this.dashboardHasSettingsType.defaultSettings || this.dashboardHasSortingType.default.length > 0) {
        return true;
      }
      return false;
    },

    currentDashboardGroup() {
      const group = {
        ...this.$store.state.dashboard[this.location].dashboardGroups.find(
          // eslint-disable-next-line @typescript-eslint/no-shadow
          (group) =>
            group.NAME.trim().replace(' ', '').toLowerCase() ===
            this.dashboardGroupName.trim().replace(' ', '').toLowerCase()
        ),
      };
      return group;
    },
  },

  watch: {
    showHiddenCharts(val) {
      this.$emit('show-hidden-charts', val);
    },
    sortItems: {
      immediate: true,
      handler(val) {
        this.charts = val;
      },
    },
  },

  methods: {
    showConfirmationDialog(type) {
      const result = {
        showDialog: true,
      };
      if (type == 'deleteDefault') {
        result.okButtonCaption = 'delete';
        result.title = 'Delete default settings';
        result.description =
          'This action will delete the default settings. Are you sure you want to delete the default settings?';
        result.functionToCall = () => this.deleteDefaultSettings();
      } else if (type == 'setAllUsers') {
        result.okButtonCaption = 'reset';
        result.title = 'Set all users to default settings';
        result.description =
          'This action will delete all user settings for every user. Are you sure you want to reset the users settings to default settings?';
        result.functionToCall = () => this.setAllUsersToDefaultSettings();
      } else if (type == 'saveDefault') {
        result.okButtonCaption = 'save';
        result.title = 'Save as default settings';
        result.description =
          'This action will overwrite the default settings. Are you sure you want to save the current settings as default settings?';
        result.functionToCall = () => this.saveAsDefaultSetup();
      } else if (type == 'resetUserSettings') {
        result.okButtonCaption = 'reset';
        result.title = 'Reset to default settings';
        result.description =
          'This action will remove your personal dashboard settings. Are you sure you want to remove your dashboard settings?';
        result.functionToCall = () => this.resetUserSettings();
      }
      this.defaultSettingsDialog = result;
    },
    deleteDefaultSettings() {
      const promises = [];
      if (this.dashboardHasSortingType.default.length > 0) {
        const sorting = this.deleteDashSorting(true);
        promises.push(sorting);
      }
      this.charts.forEach((report) => {
        if (report.reportName && this.location) {
          const settings = this.$store.getters.getChartSettings({
            location: this.location,
            dashboardGroupName: this.dashboardGroupName,
            reportName: report.reportName,
            getSettingsName: true,
          });
          if (
            Object.keys(settings.defaultSettings).length > 0 &&
            Object.getPrototypeOf(settings.defaultSettings) === Object.prototype
          ) {
            const promise = this.$store.dispatch('updateChartSettings', {
              location: this.location,
              dashboardGroupName: this.dashboardGroupName,
              reportName: report.reportName,
              settings: {},
              settingName: 'defaultSettings',
            });
            promises.push(promise);
          }
        }
      });
      if (promises.length > 0) {
        Promise.allSettled(promises).finally(() => {
          window.messageBus.$emit('showMessage', { type: 'success', predefined: 4 });
        });
      }
    },
    getAllDashboards() {
      this.$emit('refreshAllDashboards', true);
    },

    resetUserSettings() {
      const promises = [];
      if (this.dashboardHasSortingType.user) {
        const sorting = this.deleteDashSorting(false);
        promises.push(sorting);
      }
      this.charts.forEach((report) => {
        if (report.reportName && this.location) {
          const settings = this.$store.getters.getChartSettings({
            location: this.location,
            dashboardGroupName: this.dashboardGroupName,
            reportName: report.reportName,
            getSettingsName: true,
          });
          if (
            Object.keys(settings.userSettings).length > 0 &&
            Object.getPrototypeOf(settings.userSettings) === Object.prototype
          ) {
            const promise = this.$store.dispatch('updateChartSettings', {
              location: this.location,
              dashboardGroupName: this.dashboardGroupName,
              reportName: report.reportName,
              settings: {},
              settingName: 'userSettings',
            });
            promises.push(promise);
          }
        }
      });
      if (promises.length > 0) {
        Promise.allSettled(promises).finally(() => {
          window.messageBus.$emit('showMessage', { type: 'success', predefined: 4 });
        });
      }
    },

    setAllUsersToDefaultSettings() {
      const { location } = this;
      const { dashboardGroupName } = this;

      const promises = [];
      const sortingPromise = this.$store.dispatch('setAllUsersToDefaultSorting', { location, dashboardGroupName });
      promises.push(sortingPromise);

      const reports = [...this.charts];
      reports.forEach((report) => {
        if (report.reportName) {
          const { reportName } = report;
          const settingsPromise = this.$store.dispatch('setAllUsersToDefaultSettings', {
            location,
            dashboardGroupName,
            reportName,
          });
          promises.push(settingsPromise);
        }
      });
      Promise.allSettled(promises).finally(() => {
        window.messageBus.$emit('showMessage', { type: 'success', predefined: 2 });
      });
    },
    saveAsDefaultSetup() {
      // save
      /* this.$emit('setLoading', true); */
      const promises = [];
      const { location } = this;
      const { dashboardGroupName } = this;

      const sorting = this.dashboardHasSortingType.user;
      if (sorting.length > 0) {
        const sortingPromise = this.$store.dispatch('setDashSorting', {
          location,
          dashboardGroupName,
          sorting,
          isDefault: true,
        });
        promises.push(sortingPromise);
        const deleteSortingPromise = this.$store.dispatch('setDashSorting', {
          location,
          dashboardGroupName,
          sorting: [],
          isDelete: true,
        });
        promises.push(deleteSortingPromise);
      }
      this.charts.forEach((chart) => {
        let report = null;
        if (dashboardGroupName) {
          const group = { ...this.currentDashboardGroup };
          report = group.reports.find((item) => item.reportName === chart.reportName);
        } else if (location) {
          report = this.$store.state.dashboard[location].reports.find((item) => item.reportName === chart.reportName);
        }
        if (report) {
          const settings = report.userSettings;
          const { reportName } = report;

          const settingName = 'defaultSettings';

          if (Object.keys(settings).length > 0 && Object.getPrototypeOf(settings) === Object.prototype) {
            const settingsPromise = this.$store.dispatch('updateChartSettings', {
              location,
              dashboardGroupName,
              reportName,
              settings,
              settingName,
            });
            promises.push(settingsPromise);
            const deleteSettingsPromise = this.$store.dispatch('updateChartSettings', {
              location,
              dashboardGroupName,
              reportName,
              settings: {},
              settingName: 'userSettings',
            });
            promises.push(deleteSettingsPromise);
          }
        }
      });
      Promise.allSettled(promises).finally(() => {
        window.messageBus.$emit('showMessage', { type: 'success', predefined: 2 });
        this.$emit('setLoading', false);
      });
      /* if(!this.dashboardHasSortingType){
                  let sorting = this.dashSorting;
                  this.saveDashSorting(sorting, true);
              } */
    },

    onSorted() {
      const sorting = [];
      // eslint-disable-next-line array-callback-return
      this.charts.map((item) => {
        sorting.push(item.reportName);
      });
      this.saveDashSorting(sorting, false);
    },

    saveDashSorting(sorting, defaultSetting) {
      const data = {
        location: this.location,
        dashboardGroupName: this.dashboardGroupName,
        sorting,
        isDefault: defaultSetting,
      };
      this.$store.dispatch('setDashSorting', data);
    },
    deleteDashSorting(defaultSetting) {
      return new Promise((resolve) => {
        const data = {
          location: this.location,
          dashboardGroupName: this.dashboardGroupName,
          sorting: [],
          isDefault: defaultSetting,
          isDelete: true,
        };
        this.$store.dispatch('setDashSorting', data).finally(() => {
          window.messageBus.$emit('showMessage', { type: 'success', predefined: 4 });
          resolve();
        });
      });
    },
  },
};
</script>

<style scoped>
.scrolling-list {
  overflow-y: auto;
}
</style>
