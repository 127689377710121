<template>
  <v-layout wrap>
    <!-- eslint-disable-next-line -->
    <gat-compact-field
      v-if="compact"
      :size="getCompactSize(field)"
      v-for="field in fields"
      :key="field.ID"
      :value="getValue(field, true)"
      :label="field.CAPTION"
      :checkbox="field.FIELD_TYPE == 5" />
    <!-- eslint-disable-next-line -->
    <gat-dyna-field
      v-if="!compact"
      v-for="field in fields"
      :key="field.ID"
      :field="field"
      :value="getValue(field)"
      :readonly="readonly"
      :disabled="disabled"
      @input="setValue($event, field)"
      :portcallStatus="portcallStatus" />
  </v-layout>
</template>

<script>
import moment from 'moment';
import GatDynaField from './GatDynaField.vue';

export default {
  name: 'GatDynaFields',
  props: {
    compact: Boolean,
    compactSize: String,
    componentName: {
      type: String,
      required: true,
    },
    container: {
      type: String,
      required: true,
    },
    disabled: Boolean,
    filterByIds: Array,
    readonly: Boolean,
    tabId: Number,
    value: Array,
    portcallStatus: Number,
  },
  components: { GatDynaField },
  data() {
    return {};
  },

  computed: {
    fields() {
      const allFields = this.$store.state.helper.dynafields;
      const result = allFields.filter((field) => {
        let match = false;
        if (this.tabId) {
          match = field.WEB_TAB_PAGE_ID == this.tabId && field.COMPONENT_NAME == this.componentName;
        } else {
          match = field.COMPONENT_NAME == this.componentName;
          if (this.container) {
            match = match && field.CONTAINER == this.container;
          }
          if (this.filterByIds) {
            match = match && this.filterByIds.includes(field.ID);
          }
        }
        if (!this.$store.state.user.internal && field.WEB_EXTERNAL_USER_ACCESS == 0) {
          // removes field for external users if its hidden. (field is hidden)
          match = false;
        }
        if (this.container == 'portcallDetails' && field.WEB_USE_IN_WEBMODULE != 1) {
          match = false;
        }
        return match;
      });
      // field position (from classic) 0: follow previous line. 1: start on new line
      const newResult = [];
      for (let i = 0; result.length > i; i++) {
        if (result[i].WEB_POSITION == 1) {
          // iterate through the result and add a break object before current object if web_position is 1.
          newResult.push({ BREAK: true });
        }
        newResult.push(result[i]);
      }
      return newResult;
    },
  },

  created() {},

  watch: {},

  methods: {
    getCompactSize(field) {
      let result = 'm';
      if (this.compactSize) {
        return this.compactSize;
      }
      const size = field.WEB_FIELD_SIZE;
      if ((size && size == 'xs') || size == 's' || size == 'm' || size == 'l' || size == 'xl') {
        result = size;
      }

      return result;
    },

    formatISODateToISOTime(value) {
      if (value) {
        return moment(value);
      }
      return null;
    },
    // eslint-disable-next-line consistent-return
    getValue(field, dateAsText) {
      if (this.value) {
        const valueRec = this.value.find((value) => value.FIELD_ID == field.ID);
        if (valueRec) {
          switch (field.FIELD_TYPE) {
            case 2:
              return valueRec.VALUE_FLOAT;
            case 3:
              if (dateAsText) {
                return this.globalFormatDate(valueRec.VALUE_DATETIME);
              }
              return valueRec.VALUE_DATETIME;
            case 4: {
              // Time-edit
              const val = valueRec.VALUE_DATETIME;
              if (moment(val).isValid()) {
                return this.globalFormatDateTime(valueRec.VALUE_DATETIME, 'HH:mm');
              }
              return val;
            }
            case 5:
              return valueRec.VALUE_TEXT == 'Checked';

            // case 9: return (getSqlValue(valueRec.VALUE_TEXT));
            case 12: {
              const val = valueRec.VALUE_DATETIME;
              /* if (moment(val).isValid()) {
                return this.globalFormatDateTime(valueRec.VALUE_DATETIME);
              } */
              return val;
            }
            default:
              return valueRec.VALUE_TEXT;
          }
        }
      }
    },

    setValue(value, field) {
      const idx = this.value.findIndex((item) => item.FIELD_ID == field.ID);
      let valueRec = { FIELD_ID: field.ID, FIELD_TYPE: field.FIELD_TYPE || 'NEW' };
      if (idx >= 0) {
        valueRec = { ...valueRec, ...this.value[idx] };
      }
      switch (field.FIELD_TYPE) {
        case 2:
          valueRec.VALUE_FLOAT = value;
          break;
        case 3:
          valueRec.VALUE_DATETIME = value;
          break;
        case 4:
          // Time-edit
          valueRec.VALUE_DATETIME = value;
          break;
        case 5:
          if (value) {
            valueRec.VALUE_TEXT = 'Checked';
          } else {
            valueRec.VALUE_TEXT = 'Unchecked';
          }
          break;
        case 12:
          valueRec.VALUE_DATETIME = value;
          break;
        default:
          valueRec.VALUE_TEXT = value;
      }
      if (idx >= 0) {
        this.value.splice(idx, 1, valueRec);
      } else {
        this.value.push(valueRec);
      }
    },
  },
};
</script>

<style scoped></style>
