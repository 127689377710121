/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
import GatUtils from '@/app-components/GatUtils';
// eslint-disable-next-line import/no-cycle
import { apiDelete, apiGet, apiPost } from './api';

function combineCallDateAndTime(date, time) {
  if (date == null || date == '') {
    return null;
  }
  if (time == null) {
    return date;
  }
  return `${date}T${time}`;
}

function combineCallDateAndTimes(call) {
  call.ETD = combineCallDateAndTime(call.ETD_DATE, call.ETD_TIME);
  call.ATA = combineCallDateAndTime(call.ATA_DATE, call.ATA_TIME);
  call.ATD = combineCallDateAndTime(call.ATD_DATE, call.ATD_TIME);
  call.ETA = combineCallDateAndTime(call.ETA_DATE, call.ETA_TIME);
  return call;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const vuex_portcall = {
  state: {
    callDetails: { empty: true, extra: {}, tabsHasData: {} },
    callDetailsLoading: true,
    cargo: [],
    checklist: [],
    documents: [],
    expenses: [],
    hotlist: [],
    services: [],
    sofTitles: [],
    sofs: [],
    berths: [],
    lastCallsUsed: [],
    voyage: [],
    visitors: [],
    /** Used in the visitors list for external users. (has additional data) */
    visitorsExtended: [],
    crewChange: {
      people: {},
      tasks: {},
      filter: [],
    },
    expenseLocked: false,
    sendEmailProspects: undefined,
  },

  mutations: {
    setExpenseLocked(state, data) {
      state.expenseLocked = data;
    },
    setBerths(state, data) {
      state.berths = data;
    },
    removeBerthItem(state, id) {
      const result = [...state.berths];
      const idx = result.findIndex((item) => item.ID === id);
      if (idx >= 0) {
        result.splice(idx, 1);
      }
      state.berths = result;
    },
    // call details
    setCallDetails(state, data) {
      state.callDetails = data;
      state.berths = data.berths;
      this.commit('setCallDetailsLoading', false);
    },

    setSendEmailProspects(state, payload){
      state.sendEmailProspects = payload;
    },

    setCallSyncChanges(state, data) {
      const callDetails = { ...state.callDetails };
      callDetails.ETA = data.ETA;
      callDetails.ETA_DATE = data.ETA_DATE;
      callDetails.ETA_TIME = data.ETA_TIME;

      callDetails.ETD = data.ETD;
      callDetails.ETD_DATE = data.ETD_DATE;
      callDetails.ETD_TIME = data.ETD_TIME;

      callDetails.ATA = data.ATA;
      callDetails.ATA_DATE = data.ATA_DATE;
      callDetails.ATA_TIME = data.ATA_TIME;

      callDetails.ATD = data.ATD;
      callDetails.ATD_DATE = data.ATD_DATE;
      callDetails.ATD_TIME = data.ATD_TIME;

      callDetails.STATUS = data.STATUS;
      state.callDetails = callDetails;
    },

    setCallDetailsLoading(state, data) {
      state.callDetailsLoading = data;
    },

    // last calls used
    setLastPortCalls(state, data) {
      state.lastCallsUsed = data;
    },

    addToLastCallUsed(state, portcall) {
      const orgLastCalls = []; // stores the original saved info value.
      state.lastCallsUsed.map((item) => {
        // create the original saved info value. Used for comparison before post to savedInfo.
        const call = { ID: item.ID, PORTCALL_NUMBER: item.PORTCALL_NUMBER };
        call.vessel = {
          VESSEL_PREFIX: item.vessel.VESSEL_PREFIX,
          NAME: item.vessel.NAME,
        };
        orgLastCalls.push(call);
      });

      const idx = state.lastCallsUsed.findIndex((item) => item.ID == portcall.ID);
      if (idx >= 0) {
        state.lastCallsUsed.splice(idx, 1);
      }
      state.lastCallsUsed.unshift(portcall);
      if (state.lastCallsUsed.length > 3) {
        state.lastCallsUsed.pop();
      }

      const newLastCalls = [];
      state.lastCallsUsed.map((item) => {
        const call = { ID: item.ID, PORTCALL_NUMBER: item.PORTCALL_NUMBER };
        call.vessel = {
          VESSEL_PREFIX: item.vessel.VESSEL_PREFIX,
          NAME: item.vessel.NAME,
        };
        newLastCalls.push(call);
      });
      // check if there are any changes between original value and "new" value.
      if (JSON.stringify(newLastCalls) != JSON.stringify(orgLastCalls)) {
        // save to SavedInfo
        const savedInfo = {
          TypeName: 'web_last_calls',
          StrValue: JSON.stringify(newLastCalls),
        };
        apiPost('savedInfo', savedInfo);
      }
    },

    // clear call
    clearCall(state) {
      state.sofs = [];
      state.services = [];
      state.sofTitles = [];
      // state.callDetails = [];
      state.berths = [];
      state.check = [];
      state.voyage = [];
      state.visitors = [];
      state.crewChange.people = [];
      state.crewChange.tasks = [];
      state.crewChange.filter = [];
    },

    // tabHasData
    setTabHasData(state, { tabName, value }) {
      if (value != state.callDetails.tabsHasData[tabName]) {
        state.callDetails.tabsHasData[tabName] = value;
      }
    },
    // cargo
    setCargo(state, data) {
      state.cargo = data;
      state.callDetails.tabsHasData.GOODS = data.length;
    },

    addCargoItem(state, cargoItem) {
      const result = [...state.cargo];
      const idx = result.findIndex((item) => item.ID === cargoItem.ID);
      if (idx >= 0) {
        result.splice(idx, 1, cargoItem);
      } else {
        result.push(cargoItem);
      }
      state.cargo = result;
      state.callDetails.tabsHasData.GOODS = result.length;
    },

    removeCargoItem(state, id) {
      const result = [...state.cargo];
      const idx = result.findIndex((item) => item.ID === id);
      if (idx >= 0) {
        result.splice(idx, 1);
      }
      state.cargo = result;
      state.callDetails.tabsHasData.GOODS = result.length;
    },

    // checklist
    setChecklist(state, data) {
      state.checklist = data;
      state.callDetails.tabsHasData.CHECKLIST = data.length;
    },

    addChecklistItem(state, completeChecklistItem) {
      const result = [...state.checklist];
      const newItem = {
        ID: completeChecklistItem.ID,
        CHECKED: completeChecklistItem.CHECKED,
        ITEM: completeChecklistItem.ITEM,
        GROUPING: completeChecklistItem.GROUPING,
        STATUS: completeChecklistItem.STATUS,
        CHECKED_DATE: completeChecklistItem.CHECKED_DATE,
        SORT_ORDER: completeChecklistItem.CHECKLIST_NUMBER,
        REMINDER: completeChecklistItem.REMINDER,
        REM_TYPE: completeChecklistItem.REM_TYPE,
        REM_DATETIME: completeChecklistItem.REM_DATETIME,
        REM_REL_TYPE: completeChecklistItem.REM_REL_TYPE,
        REM_RELATIVE: completeChecklistItem.REM_RELATIVE,
        IGNORE_ITEM: completeChecklistItem.IGNORE_ITEM,
        COMMENTS: completeChecklistItem.COMMENTS,
      };
      result.push(newItem);
      state.checklist = result;
      state.callDetails.tabsHasData.CHECKLIST = result.length; // Set the tabsHasData value to the number of checklist records.
    },

    setChecklistItemChecked(state, obj) {
      const date = obj.CHECKED_DATE;
      const username = obj.CHECKED_BY;
      const status = obj.STATUS;

      const idx = state.checklist.findIndex((item) => item.ID == obj.ID);
      const item = state.checklist[idx].CHECKED;
      if (item) {
        state.checklist[idx].CHECKED = 0;
      } else {
        state.checklist[idx].CHECKED = 1;
      }
      state.checklist[idx].STATUS = status;
      state.checklist[idx].CHECKED_BY = username;
      state.checklist[idx].CHECKED_DATE = date;
    },

    setChecklistItemIgnored(state, obj) {
      const idx = state.checklist.findIndex((item) => item.ID == obj.ID);
      const item = state.checklist[idx].IGNORE_ITEM;
      item ? (state.checklist[idx].IGNORE_ITEM = 0) : (state.checklist[idx].IGNORE_ITEM = 1);
    },

    replaceChecklistItem(state, completeChecklistItem) {
      const idx = state.checklist.findIndex((item) => item.ID == completeChecklistItem.ID);
      if (idx >= 0) {
        // replace item
        const updatedItem = state.checklist[idx];
        updatedItem.CHECKED = completeChecklistItem.CHECKED;
        updatedItem.ITEM = completeChecklistItem.ITEM;
        updatedItem.GROUPING = completeChecklistItem.GROUPING;
        updatedItem.STATUS = completeChecklistItem.STATUS;
        updatedItem.CHECKED_DATE = completeChecklistItem.CHECKED_DATE;
        updatedItem.CHECKED_BY = completeChecklistItem.CHECKED_BY;
        updatedItem.SORT_ORDER = completeChecklistItem.CHECKLIST_NUMBER;
        updatedItem.REMINDER = completeChecklistItem.REMINDER;
        updatedItem.REM_TYPE = completeChecklistItem.REM_TYPE;
        updatedItem.REM_REL_TYPE = completeChecklistItem.REM_REL_TYPE;
        updatedItem.REM_RELATIVE = completeChecklistItem.REM_RELATIVE;
        updatedItem.REM_DATETIME = completeChecklistItem.REM_DATETIME;
        updatedItem.IGNORE_ITEM = completeChecklistItem.IGNORE_ITEM;
        updatedItem.COMMENTS = completeChecklistItem.COMMENTS;

        state.checklist.splice(idx, 1, updatedItem);
      }
    },

    removeChecklistItem(state, id) {
      const idx = state.checklist.findIndex((item) => item.ID == id);
      if (idx >= 0) {
        // replace item
        state.checklist.splice(idx, 1);
      }
      if (state.callDetails.tabsHasData.CHECKLIST) {
        state.callDetails.tabsHasData.CHECKLIST = state.checklist.length;
      }
    },

    // hotlist / clients
    setHotlist(state, data) {
      state.hotlist = data;
      state.callDetails.tabsHasData.HOTLIST = data.length;
    },

    replaceHotlistItem(state, hotlistItem) {
      let idx = -1;
      if (hotlistItem.H_ID > 0) {
        idx = state.hotlist.findIndex((item) => item.H_ID == hotlistItem.H_ID);
      } else if (hotlistItem.H_ID == -1) {
        idx = state.hotlist.findIndex((item) => item.CLIENT_ID == hotlistItem.CLIENT_ID);
      }
      if (idx >= 0) {
        state.hotlist.splice(idx, 1, hotlistItem);
      }
    },

    removeHotlistItem(state, id) {
      const result = [...state.hotlist];
      const idx = result.findIndex((item) => item.H_ID == id);
      if (idx >= 0) {
        result.splice(idx, 1);
      }
      state.hotlist = result;
      state.callDetails.tabsHasData.HOTLIST = result.length;
    },

    removeHotlistTempContact(state, id) {
      const result = [...state.hotlist];
      const idx = result.findIndex((item) => item.H_ID == -1 && item.CLIENT_ID == id);
      if (idx >= 0) {
        result.splice(idx, 1);
      }
      state.hotlist = result;
      state.callDetails.tabsHasData.HOTLIST = result.length;
    },

    // crewchange
    setCrewChangeFilter(state, data) {
      state.crewChange.filter = data;
    },

    setCrewChangePeople(state, data) {
      state.crewChange.people = data;
    },

    setCrewChangeTasks(state, data) {
      state.crewChange.tasks = data;
    },

    // expenses
    setExpenses(state, data) {
      state.expenses = data;
      state.callDetails.tabsHasData.EXPENSE = data.length;
    },

    addExpenseItem(state, data) {
      // ikke i bruk.
      const result = [...state.expenses];
      const idx = result.find((item) => item.EXP_ID == data.EXP_ID);
      if (idx >= 0) {
        result.splice(idx, 1, data);
      } else {
        result.push(data);
      }
      state.expenses = result;
      state.callDetails.tabsHasData.EXPENSE = result.length;
    },

    replaceExpenseItem(state, expenseObj) {
      const result = [...state.expenses];
      const idx = result.findIndex((item) => item.EXP_ID == expenseObj.EXP_ID);
      if (idx >= 0) {
        result.splice(idx, 1, expenseObj);
      }
      state.expenses = result;
      state.callDetails.tabsHasData.EXPENSE = result.length;
    },

    removeExpenseItem(state, id) {
      const result = [...state.expenses];
      const idx = result.findIndex((item) => item.EXP_ID == id);
      if (idx >= 0) {
        result.splice(idx, 1);
      }
      state.expenses = result;
      state.callDetails.tabsHasData.EXPENSE = result.length;
    },

    // services
    setServices(state, data) {
      state.services = data;
      state.callDetails.tabsHasData.SERVICES = data.length;
    },

    addServiceItem(state, data) {
      const result = [...state.services];
      const idx = result.findIndex((item) => item.SER_ID == data.SER_ID);
      if (idx >= 0) {
        result.splice(idx, 1, data);
      } else {
        result.push(data);
      }
      state.services = result;
      state.callDetails.tabsHasData.SERVICES = result.length;
    },

    removeServiceItem(state, id) {
      const result = [...state.services];
      const idx = result.findIndex((item) => item.SER_ID == id);
      if (idx >= 0) {
        result.splice(idx, 1);
      }
      state.services = result;
      state.callDetails.tabsHasData.SERVICES = result.length;
    },

    // sof
    clearSof(state) {
      state.sofs = [];
      state.sofTitles = [];
    },

    setSof(state, data) {
      const sof = GatUtils.makeCopyViaJson(data);

      const idx = state.sofs.findIndex((item) => item.ID == data.ID);
      if (idx >= 0) {
        state.sofs[idx] = sof;
      } else {
        state.sofs.push(sof);
      }
    },

    setSofTitles(state, data) {
      state.sofTitles = GatUtils.makeCopyViaJson(data);
      state.callDetails.tabsHasData.SOF = data.length;
    },

    updateSofTitle(state, { id, title }) {
      const titles = [...state.sofTitles];
      titles.map((item) => {
        if (item.ID === id) {
          item.TITLE = title;
        }
      });
      state.sofTitles = titles;
    },

    addSofTitleAndId(state, data) {
      // set the newly added sof title and id
      const result = [...state.sofTitles];
      result.push(data);
      state.sofTitles = result;
      state.callDetails.tabsHasData.SOF = result.length;
    },

    // visitors
    setVisitors(state, data) {
      state.visitors = data;
      state.callDetails.tabsHasData.VISITOR = data.length;
    },

    setVisitorsExtended(state, data) {
      state.visitorsExtended = data;
    },

    addVisitorItem(state, visitorItem) {
      const result = [...state.visitors];
      result.push(visitorItem);
      state.visitors = result;
      state.callDetails.tabsHasData.VISITOR = result.length;
      if (state.visitorsExtended.length > 0) {
        state.visitorsExtended = [];
      }
    },

    replaceVisitorItem(state, visitorItem) {
      const idx = state.visitors.findIndex((item) => item.VIS_ID === visitorItem.VIS_ID);
      state.visitors.splice(idx, 1, visitorItem);
      if (state.visitorsExtended.length > 0) {
        const idx2 = state.visitorsExtended.findIndex((item) => item.VIS_ID === visitorItem.VIS_ID);
        if (idx2 >= 0 && idx >= 0) {
          const orgItem = {
            ...state.visitorsExtended[idx2],
            ...state.visitors[idx],
          };
          state.visitorsExtended.splice(idx, 1, orgItem);
        }
      }
    },

    removeVisitorItem(state, visitorId) {
      let result = [...state.visitors];
      const idx = result.findIndex((item) => item.VIS_ID == visitorId);
      if (idx >= 0) {
        result.splice(idx, 1);
      }
      state.visitors = result;
      state.callDetails.tabsHasData.VISITOR = result.length;
      if (state.visitorsExtended.length > 0) {
        idx = -1;
        result = [...state.visitorsExtended];
        let idx = result.findIndex((item) => item.VIS_ID == visitorId);
        if (idx >= 0) {
          result.splice(idx, 1);
          state.visitorsExtended = result;
        }
      }
    },

    // voyage
    setVoyage(state, data) {
      state.voyage = data;
      state.callDetails.tabsHasData.ROUTE = data.length;
    },

    addVoyageItem(state, voyageItem) {
      const result = [...state.voyage];
      result.push(voyageItem);
      state.voyage = result;
      state.callDetails.tabsHasData.ROUTE = result.length;
    },

    replaceVoyageItem(state, voyageItem) {
      const idx = state.voyage.findIndex((item) => item.ID === voyageItem.ID);
      state.voyage.splice(idx, 1, voyageItem);
    },

    removeVoyageItem(state, voyageId) {
      const result = [...state.voyage];
      const idx = result.findIndex((item) => item.ID == voyageId);
      if (idx >= 0) {
        result.splice(idx, 1);
      }
      state.voyage = result;
      state.callDetails.tabsHasData.ROUTE = result.length;
    },
  },

  actions: {
    deleteExpense({ commit }, id) {
      apiDelete(`expense/${id}`).then((result) => {
        if (result.resultCategory == 1) {
          commit('removeExpenseItem', id);
        }
      });
    },

    deleteService({ commit }, id) {
      apiDelete(`service/${id}`).then((result) => {
        if (result.resultCategory == 1) {
          commit('removeServiceItem', id);
        }
      });
    },

    deleteServiceAndExpense({ commit }, { serviceId, expenseId }) {
      if (serviceId) {
        apiDelete(`service/${serviceId}`).then((result) => {
          if (result.resultCategory == 1) {
            commit('removeServiceItem', serviceId);
            if (expenseId) {
              apiDelete(`expense/${expenseId}`).then((result) => {
                if (result.resultCategory == 1) {
                  commit('removeExpenseItem', expenseId);
                }
              });
            }
          }
        });
      }
    },

    getBerths({ commit }, callId) {
      apiGet(`dock/berths/${callId}`).then((result) => {
        if (result.resultCategory == 1) {
          commit('setBerths', result.payload);
        }
      });
    },

    getCargoItems({ commit }, callId) {
      apiGet(`cargo/call/${callId}`).then((result) => {
        commit('setCargo', result);
      });
    },

    getChecklist({ commit }, callId) {
      apiGet(`checklist/call/${callId}`).then((result) => {
        commit('setChecklist', result);
      });
    },

    getCrewChangeFilter({ commit }, callId) {
      return new Promise((resolve, reject) => {
        apiGet(`savedinfo/web-ccm-grid-filter-${callId}`).then(
          (data) => {
            if (data.length > 0) {
              if (data[0].MEMOVALUE1.length > 0) {
                commit('setCrewChangeFilter', data[0].MEMOVALUE1);
                resolve(data);
              } else {
                commit('setCrewChangeFilter', {});
                resolve(data);
              }
            } else {
              commit('setCrewChangeFilter', {});
              resolve(data);
            }
          },
          (error) => {
            reject(error);
          }
        );
      });
    },

    getCrewChangePeople({ commit }, { callId, clientId }) {
      return apiGet(`crewchange/call/${callId}/people/${clientId}`).then((result) => {
        commit('setCrewChangePeople', result);
      });
    },

    getCrewChangeTasks({ commit }, { callId, clientId }) {
      return apiGet(`crewchange/call/${callId}/tasks/${clientId}`).then((result) => {
        commit('setCrewChangeTasks', result);
      });
    },

    getExpenses({ commit }, callId) {
      return apiGet(`expense/call/${callId}`).then((result) => {
        if (result) {
          // map expense credit flag & balance
          result.map((expense) => {
            expense.CLIENT_CREDIT_BALANCE = 0;
            if (expense.CLIENT_CREDIT_FLAG === '0') {
              expense.CLIENT_CREDIT_FLAG = +expense.CLIENT_CREDIT_FLAG;
            } else {
              const flagLimit = expense.CLIENT_CREDIT_FLAG.split('#');
              expense.CLIENT_CREDIT_FLAG = +flagLimit[0];
              expense.CLIENT_CREDIT_BALANCE = +flagLimit[1];
            }
          });
        }
        commit('setExpenses', result);
      });
    },

    getHotlist({ commit }, callId) {
      return new Promise((resolve, reject) => {
        apiGet(`hotlist/${callId}`).then(
          (data) => {
            commit('setHotlist', data);
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },

    getLastPortCalls({ commit }) {
      return new Promise((resolve, reject) => {
        apiGet('savedinfo/web_last_calls').then(
          (data) => {
            if (data.length > 0) {
              const lastCalls = JSON.parse(data[0].MEMOVALUE1);
              commit('setLastPortCalls', lastCalls);
              resolve(data[0]);
            } else {
              commit('setLastPortCalls', []);
              resolve(data[0]);
            }
          },
          (error) => {
            reject(error);
          }
        );
      });
    },

    getPortcallDetails({ commit, dispatch }, callId) {
      commit('setCallDetailsLoading', true);
      return new Promise((resolve) => {
        apiGet(`portcall/${callId}`).then((data) => {
          const portcall = combineCallDateAndTimes(data);
          if (portcall.vessel && !portcall.vessel.VESSEL_PREFIX) {
            portcall.vessel.VESSEL_PREFIX = '';
          }
          commit('setCallDetails', portcall);
          commit('addToLastCallUsed', portcall);
          dispatch('createApplicationStatusWhenPortcallIsLoaded', portcall);
          dispatch('isExpenseOnPortcallLocked', callId);
          /* if(state.lastCallsUsed.length > 0){ // prevent savedInfo to be updated on load. */
          /* } */
          resolve();
        });
      });
    },

    getPortcallSyncChanges({ commit }, callId) {
      return apiGet(`portcall/syncchanges/${callId}`).then((data) => {
        const changes = combineCallDateAndTimes(data.payload);
        commit('setCallSyncChanges', changes);
      });
    },

    getServices({ commit }, callId) {
      return apiGet(`service/call/${callId}`).then((result) => {
        commit('setServices', result);
      });
    },

    getSofTitles({ commit }, callId) {
      return apiGet(`sof/all/${callId}`).then((result) => {
        // eslint-disable-next-line array-callback-return
        result.map((sof) => {
          if (!sof.TITLE) {
            // eslint-disable-next-line no-param-reassign
            sof.TITLE = `SOF no ${sof.LINE_NO}`;
          }
        });
        commit('setSofTitles', result);
        /* if (Array.isArray(result) && result.length > 0 && result[0].ID) {
          commit("setAppStatusSelectedSofId", result[0].ID);
        } */
      });
    },

    getVoyage({ commit }, callId) {
      return apiGet(`/voyage/call/${callId}`).then((result) => {
        commit('setVoyage', result);
      });
    },

    getVisitors({ commit }, { callId, showAll = false }) {
      if (showAll) {
        return apiGet('/visitor/gate-keeper').then((result) => {
          commit('setVisitorsExtended', result);
        });
      }
      return apiGet(`/visitor/call/${callId}`).then((result) => {
        commit('setVisitors', result);
      });
    },
    async isExpenseOnPortcallLocked({ getters, rootState, commit }, callId) {
      if (rootState.globalSettings.SGL_EXP_LOCK_MONTHS_AFTER_ATD <= 0) {
        commit('setExpenseLocked', false);
        return false;
      }
      if (getters.userRight('EX.IGNORE_LOCKED_EXPENSES')) {
        commit('setExpenseLocked', false);
        return false;
      }
      let isLockedByAtd = 0;
      let isLockedByStatus = 0;
      // select CASE WHEN DATEDIFF(MONTH,ISNULL(P.ATD_DATE,''19000101''),GETDATE()) >= @monthsAfterAtd then 1 else 0 end as IsLockedByAtd
      // ,case when P.STATUS = 5 then 1 else 0 end as IsLockedByStatus
      // from PortCall P
      // where P.ID = @callId
      // SQL PARAM: @callId = portcallId
      // SQL PARAM: @monthsAfterAtd = SGL_EXP_LOCK_MONTHS_AFTER_ATD
      const result = await apiGet(`portcall/${callId}/is-locked-by-atd`);
      if (result) {
        isLockedByAtd = result.IsLockedByAtd;
        isLockedByStatus = result.IsLockedByStatus;
      }

      commit('setExpenseLocked', isLockedByAtd === 1 && isLockedByStatus === 1);
      return isLockedByAtd === 1 && isLockedByStatus === 1;
    },
  },

  getters: {
    currentWebAccessGroup(state, getters, rootState) {
      let result = rootState.helper.webAccessGroups.find((item) => item.WAG_NAME == state.callDetails.webAccessGroup);
      if (!result) {
        result = {};
      }
      return result;
    },
    getFirstSofTitleId(state) {
      const { sofTitles } = state;
      if (Array.isArray(sofTitles) && sofTitles.length > 0 && sofTitles[0].ID) {
        return sofTitles[0].ID;
      }
      return undefined;
    },
  },
};

export default vuex_portcall;
