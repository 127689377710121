<template>
  <gat-dialog
    v-model="dialogVisible"
    :cancelButtonCaption="noAction ? '' : 'CLOSE'"
    :okButtonCaption="noAction ? '' : 'OPEN EMAIL'"
    width="600"
    @okClick="goToEmail(doc.ID)"
    @cancelClick="close()"
    :okButtonDisabled="OkDisabled">
    <v-stepper :value="docStatus" class="elevation-0">
      <v-stepper-header>
        <v-stepper-step step="1" :complete="docStatus > 0">Added to queue</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="2" :complete="docStatus > 1">Generation started</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step v-if="docStatus <= 3" step="3" :complete="docStatus > 2">Email ready</v-stepper-step>
        <v-stepper-step
          v-if="docStatus == 4"
          step="3"
          :complete="docStatus > 2"
          complete-icon="mdi-close-circle-outline"
          color="red"
          ><span style="color: red">Generation failed</span></v-stepper-step
        >
      </v-stepper-header>
    </v-stepper>

    <span v-if="docStatus < 3">Your email should be ready in a few seconds.</span>
    <div v-else-if="docStatus == 3" class="ml-5 d-flex align-center">
      <div class="d-flex align-center">
        <div>{{ redirectText || 'Your email template is ready' }}</div>
        <!-- <span ><v-icon :color="getMdiFileIcon(doc.FILENAME).color">{{getMdiFileIcon(doc.FILENAME).icon}}</v-icon></span> -->
        <span> {{ doc.TITLE }} </span>
      </div>
    </div>
    <span v-else-if="docStatus == 4" class="ml-5">
      <span>Something went wrong with the email generation.</span>
    </span>
  </gat-dialog>
</template>

<script>
import { apiGet } from '@/store/api';
import { DocumentMixin } from './DocumentMixin';

export default {
  name: 'EmailStatus',
  props: {
    guid: {
      type: String,
    },
    showForm: {
      type: Boolean,
      default: false,
    },
    // If no action is needed from the user.
    noAction: Boolean,
  },
  components: {},
  mixins: [DocumentMixin],
  data() {
    return {
      dialogVisible: this.showForm,
      docStatus: 0,
      interval: null,
      doc: { FILENAME: 'Proforma with vouchers.pdf' },
      redirectText: '',
    };
  },

  computed: {
    OkDisabled() {
      return this.docStatus !== 3;
    },
  },

  mounted() {
    this.dialogVisible = this.showForm;
  },

  created() {},

  watch: {
    showForm(newValue) {
      this.dialogVisible = newValue;
    },
    guid(newValue, oldValue) {
      if (oldValue) {
        this.docStatus = 0;
      }
      if (newValue) {
        this.checkStatus();
      }
    },
  },

  methods: {
    checkStatus() {
      apiGet(`document/status/${this.guid}`).then((data) => {
        if (data && data.DQU_QUEUE_STATUS) {
          this.docStatus = data.DQU_QUEUE_STATUS;
        }
        if (this.$store.state.application.debugMode) {
          console.log(this.docStatus);
        }
        if (this.docStatus < 3) {
          setTimeout(this.checkStatus, 2000);
        } else {
          if (this.docStatus == 3) {
            this.doc.TITLE = data.TITLE;
            this.doc.ID = data.DQU_DOCUMENT_ID;
            this.$emit('documentCreated', this.doc);
            if (this.noAction) {
              this.redirectText = 'Redirecting...';
              setTimeout(() => {
                this.dialogVisible = false;
                this.redirectText = undefined;
              }, 2000);
            }
          }
          if (!this.dialogVisible) {
            this.$emit('documentReady', this.doc);
          }
        }
      });
    },
    close() {
      this.$emit('close');
    },

    goToEmail(docId) {
      this.close();
      this.$emit('goToEmailClicked', docId);
    },

    open() {},
  },
};
</script>

<style scoped></style>
