<template>
  <div>
    <gat-group title="Filter" :collapsed="true">
      <template slot="compact">
        <CrewChangeFilterTablePicker v-if="!this.$vuetify.breakpoint.xsOnly" v-show="userHasTaskRights" />
        <v-layout wrap>
          <template v-for="(_, index) in operationFilter">
            <v-chip
              v-if="operationFilter[index]"
              :key="'a' + index"
              class="ma-2"
              close
              @click:close="
                operationFilter = operationFilter.map((op, i) => {
                  return i === index ? false : op;
                });
                updateFilterSettings();
              ">
              {{ operations[index] }}
            </v-chip>
          </template>
          <template v-for="(_, index) in personFilter">
            <v-chip
              v-if="personFilter[index]"
              :key="'b' + index"
              class="ma-2"
              close
              @click:close="
                personFilter = personFilter.map((op, i) => {
                  return i === index ? false : op;
                });
                updateFilterSettings();
              ">
              {{ personTypes[index] }}
            </v-chip>
          </template>
          <template v-for="(_, index) in statusFilter">
            <v-chip
              v-if="statusFilter[index]"
              :key="'c' + index"
              class="ma-2"
              close
              @click:close="
                statusFilter = statusFilter.map((op, i) => {
                  return i === index ? false : op;
                });
                updateFilterSettings();
              ">
              {{ statusTypes[index] }}
            </v-chip>
          </template>
          <template v-for="(_, index) in taskFilter">
            <v-chip
              v-if="taskFilter[index]"
              :key="'d' + index"
              class="ma-2"
              close
              @click:close="
                taskFilter = taskFilter.map((op, i) => {
                  return i === index ? false : op;
                });
                updateFilterSettings();
              ">
              {{ tasks[index] }}
            </v-chip>
          </template>
        </v-layout>
      </template>
      <v-container>
        <v-row v-if="this.$vuetify.breakpoint.xsOnly">
          <gat-group :collapsable="false" class="flexContainer displayContainer">
            <v-container>
              <CrewChangeFilterTablePicker v-show="userHasTaskRights" />
            </v-container>
          </gat-group>
        </v-row>
        <v-row>
          <gat-group :collapsable="false" class="flexContainer">
            <v-container>
              <v-row>
                <v-col>
                  <h5>Operation</h5>
                  <v-switch
                    v-model="operationFilter[0]"
                    toggle
                    label="Embarking"
                    @change="updateFilterSettings()"></v-switch>
                  <v-switch
                    v-model="operationFilter[1]"
                    toggle
                    label="Disembarking"
                    @change="updateFilterSettings()"></v-switch>
                  <v-switch
                    v-model="operationFilter[2]"
                    toggle
                    label="Other"
                    @change="updateFilterSettings()"></v-switch>
                </v-col>
                <v-col>
                  <h5>Person type</h5>
                  <v-switch v-model="personFilter[0]" toggle label="Crew" @change="updateFilterSettings()"></v-switch>
                  <v-switch
                    v-model="personFilter[1]"
                    toggle
                    label="Passenger"
                    @change="updateFilterSettings()"></v-switch>
                  <v-switch v-model="personFilter[2]" toggle label="Other" @change="updateFilterSettings()"></v-switch>
                </v-col>
                <v-col>
                  <h5>Status</h5>
                  <v-switch
                    v-model="statusFilter[0]"
                    toggle
                    label="In Progress"
                    @change="updateFilterSettings()"></v-switch>
                  <v-switch
                    v-model="statusFilter[1]"
                    toggle
                    label="Completed"
                    @change="updateFilterSettings()"></v-switch>
                  <v-switch
                    v-model="statusFilter[2]"
                    toggle
                    label="Cancelled"
                    @change="updateFilterSettings()"></v-switch>
                </v-col>
                <v-col>
                  <h5>Task</h5>
                  <v-switch
                    v-model="taskFilter[0]"
                    toggle
                    label="Without transport"
                    @change="updateFilterSettings()"></v-switch>
                  <v-switch
                    v-model="taskFilter[1]"
                    toggle
                    label="Without accomodation"
                    @change="updateFilterSettings()"></v-switch>
                </v-col>
              </v-row>
            </v-container>
          </gat-group>
        </v-row>
        <v-row>
          <gat-group title="Display" :collapsable="false" class="flexContainer displayContainer">
            <v-container>
              <v-switch v-model="denseMode" toggle label="Dense tables" @change="updateDenseSetting()"></v-switch>
            </v-container>
          </gat-group>
        </v-row>
      </v-container>
    </gat-group>
  </div>
</template>

<script>
import { apiPost } from '@/store/api';
import CrewChangeFilterTablePicker from './CrewChangeFilterTablePicker.vue';

export default {
  name: 'CrewChangeFilter',
  props: {
    callId: [Number, String],
  },
  components: {
    CrewChangeFilterTablePicker,
  },
  data() {
    return {
      denseMode: false,
      operationFilter: [false, false, false],
      personFilter: [false, false, false],
      statusFilter: [false, false, false],
      taskFilter: [false, false],
      operations: ['Embarking', 'Disembarking', 'Other'],
      personTypes: ['Crew', 'Passenger', 'Other'],
      statusTypes: ['In Progress', 'Completed', 'Cancelled'],
      tasks: ['Without transport', 'Without accomodation'],
    };
  },

  activated() {
    if (
      this.$store.state.portcall.crewChange.filter != null &&
      this.$store.state.portcall.crewChange.filter.length > 0
    ) {
      this.setFilterSettings();
    } else {
      this.$store.dispatch('getCrewChangeFilter', this.callId).then((result) => {
        if (result.length === 0) {
          // No record in backend, clear trailing filter settings
          this.operationFilter = [false, false, false];
          this.personFilter = [false, false, false];
          this.statusFilter = [false, false, false];
          this.taskFilter = [false, false];
        } else {
          this.setFilterSettings();
        }
      });
    }
  },

  mounted() {
    if (this.$store.state.user.userSettings.crewChange.denseTables != null) {
      this.setDenseSetting();
    } else {
      this.$store.dispatch('getCrewChangeTablesDense').then(() => {
        this.setDenseSetting();
      });
    }
  },

  watch: {
    operationFilter() {
      window.messageBus.$emit('crewChangeFilterOperation', this.operationFilter);
    },
    personFilter() {
      window.messageBus.$emit('crewChangeFilterPerson', this.personFilter);
    },
    statusFilter() {
      window.messageBus.$emit('crewChangeFilterStatus', this.statusFilter);
    },
    taskFilter() {
      window.messageBus.$emit('crewChangeFilterTask', this.taskFilter);
    },
    denseMode() {
      window.messageBus.$emit('crewChangeFilterDense', this.denseMode);
    },
  },

  computed: {
    userHasTaskRights() {
      return this.$store.getters.userRight('CRCH.ACCESS') || this.$store.getters.userRight('CRCH.ACCESS_OWN_TASK');
    },
  },

  methods: {
    setFilterSettings() {
      if (this.$store.state.portcall.crewChange.filter.length > 0) {
        const storeFilter = JSON.parse(this.$store.state.portcall.crewChange.filter);
        this.operationFilter = storeFilter.operationFilter;
        this.personFilter = storeFilter.personFilter;
        this.statusFilter = storeFilter.statusFilter;
        this.taskFilter = storeFilter.taskFilter;
      }
    },
    updateFilterSettings() {
      const payload = {
        operationFilter: this.operationFilter,
        personFilter: this.personFilter,
        statusFilter: this.statusFilter,
        taskFilter: this.taskFilter,
      };
      const savedInfo = {
        TypeName: `web-ccm-grid-filter-${this.callId}`,
        Name: this.$store.state.user.userName,
        strValue: JSON.stringify(payload),
      };
      apiPost('savedInfo', savedInfo);
    },
    setDenseSetting() {
      this.denseMode = this.$store.state.user.userSettings.crewChange.denseTables;
      window.messageBus.$emit('crewChangeFilterDense', this.denseMode);
    },
    updateDenseSetting() {
      const savedInfo = {
        TypeName: 'web-ccm-grid-dense',
        Name: this.$store.state.user.userName,
        IntValue: this.denseMode ? 1 : 0,
      };
      apiPost('savedInfo', savedInfo);
    },
  },
};
</script>

<style scoped>
.container {
  white-space: nowrap !important;
}
.flexContainer {
  flex: 1 !important;
}
.displayContainer {
  margin-top: 0px;
}
</style>
