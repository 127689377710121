<template>
  <!-- FULLSCREEN chart item -->
  <div
    v-if="settings && showChart"
    :class="maximized ? 'pa-1 ' : 'pa-2 ' + chartSize"
    v-show="showChart"
    style="overflow: hidden">
    <v-dialog v-model="maximized" fullscreen v-if="maximized" overflow="hidden">
      <v-card
        @mouseover="hover = true"
        @mouseleave="hover = false"
        :class="maximized ? 'elevation-0' : ''"
        style="overflow: hidden; border-radius: 0">
        <div class="chartHeader d-flex d-flex-inline align-center" :style="'height:' + headerHeight + 'px;'">
          <span
            class="ml-3"
            :class="{ hidden: settings.hidden || (settings.hiddenOnPhone && $vuetify.breakpoint.smAndDown) }"
            >{{ title }}
          </span>
          <div class="chart-hover-menu ml-auto d-flex flex-row-reverse">
            <v-btn v-show="maximized" small text class="pa-0 mr-2 ml-1" color="primary" @click="maximized = false"
              ><v-icon>close</v-icon>close</v-btn
            >
            <chart-settings-menu
              :cardActive="hover || maximized"
              :settings="settings"
              :reportName="reportName"
              :maximized="maximized"
              @refresh="loadData"
              :location="location"
              :dashboardGroupName="dashboardGroupName" />
            <v-btn small text icon v-show="hover && !maximized" class="right" @click="maximized = true">
              <v-icon>fullscreen</v-icon>
            </v-btn>
            <v-btn small text icon v-show="hover && !maximized" class="right" @click="loadData">
              <v-icon>refresh</v-icon>
            </v-btn>
          </div>
        </div>
        <div
          v-if="chartIsLoading && !loadError"
          class="d-flex flex-grow-1 justify-center align-center"
          :style="'height:' + (getTableHeight - headerHeight - 9) + 'px'">
          <v-container
            style="width: inherit; height: inherit"
            class="d-flex flex-column flex-grow-1 justify-center align-center">
            <v-progress-circular indeterminate></v-progress-circular>
            <span class="ml-4 mt-2">Loading...</span>
          </v-container>
        </div>

        <div v-show="!chartIsLoading && !loadError">
          <GChart
            v-if="chartData && chartData.length > 0 && settings.chartType != 'Table'"
            :key="chartKey"
            class="chartBox"
            :class="{
              hidden: settings.hidden || (settings.hiddenOnPhone && $vuetify.breakpoint.smAndDown),
              maximized: maximized,
            }"
            :create-chart="drawGEOChart"
            :data="chartData"
            :options="chartOptions"
            :settings="chartSettings"
            :tableHeight="getTableHeight" />
          <!-- :resizeDebounce="500" -->
          <chart-table
            v-else
            :data="chartData"
            :maximized="maximized"
            :columns="columns"
            :class="{
              hidden: settings.hidden || (settings.hiddenOnPhone && $vuetify.breakpoint.smAndDown),
              maximized: maximized,
            }"
            :formating="chartOptions.formating"
            :stickyFirstColumn="stickyFirstColumn"
            :headerHeight="headerHeight"
            :tableHeight="getTableHeight" />
        </div>
        <v-alert v-if="loadError" outlined type="error">
          <v-row align="center">
            <v-col class="grow">Network error. Could not load data.</v-col>
            <v-col class="shrink">
              <v-btn small @click="loadData">Retry</v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-card>
    </v-dialog>
    <!-- normal chart item v-else -->
    <v-card
      v-if="!maximized"
      @mouseover="hover = true"
      @mouseleave="hover = false"
      :class="maximized ? 'elevation-0' : ''"
      style="overflow: hidden; width: 100%">
      <!-- v-resize="onResize" -->
      <div class="chartHeader d-flex d-flex-inline align-center" :style="'height:' + headerHeight + 'px;'">
        <span
          class="ml-3"
          style="white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis"
          :class="{ hidden: settings.hidden || (settings.hiddenOnPhone && $vuetify.breakpoint.smAndDown) }"
          >{{ title }}
        </span>
        <div class="chart-hover-menu ml-auto d-flex flex-row-reverse">
          <v-btn v-show="maximized" small text class="pa-0 mr-2 ml-1" color="primary" @click="maximized = false"
            ><v-icon>close</v-icon>close</v-btn
          >
          <chart-settings-menu
            :cardActive="hover || maximized"
            :settings="settings"
            :reportName="reportName"
            :maximized="maximized"
            @refresh="loadData"
            :location="location"
            :dashboardGroupName="dashboardGroupName" />
          <v-btn small text icon v-show="hover && !maximized" class="right" @click="maximized = true">
            <v-icon>fullscreen</v-icon>
          </v-btn>
          <v-btn small text icon v-show="hover && !maximized" class="right" @click="loadData">
            <v-icon>refresh</v-icon>
          </v-btn>
        </div>
      </div>
      <div
        v-if="chartIsLoading && !loadError"
        class="d-flex flex-grow-1 justify-center align-center"
        :style="'height:' + (getTableHeight - headerHeight - 9) + 'px'">
        <v-container
          style="width: inherit; height: inherit"
          class="d-flex flex-column flex-grow-1 justify-center align-center">
          <v-progress-circular indeterminate></v-progress-circular>
          <span class="ml-4 mt-2">Loading...</span>
        </v-container>
      </div>
      <div v-show="!chartIsLoading && !loadError">
        <GChart
          v-if="chartData && chartData.length > 0 && settings.chartType != 'Table'"
          :key="chartKey"
          class="chartBox"
          :class="{
            hidden: settings.hidden || (settings.hiddenOnPhone && $vuetify.breakpoint.smAndDown),
            maximized: maximized,
          }"
          :create-chart="drawGEOChart"
          :data="chartData"
          :options="chartOptions"
          :settings="chartSettings" />
        <!-- :resizeDebounce="500" -->
        <chart-table
          v-else
          :data="chartData"
          :maximized="maximized"
          :columns="columns"
          :class="{
            hidden: settings.hidden || (settings.hiddenOnPhone && $vuetify.breakpoint.smAndDown),
            maximized: maximized,
          }"
          :formating="chartOptions.formating"
          :stickyFirstColumn="stickyFirstColumn"
          :headerHeight="headerHeight"
          :tableHeight="getTableHeight"
          :settings="settings" />
      </div>
      <div v-if="loadError" class="d-flex flex-grow-1 justify-center align-center">
        <v-alert outlined type="error">
          <v-row align="center">
            <v-col class="grow">Network error. Could not load data.</v-col>
            <v-col class="shrink">
              <v-btn small @click="loadData">Retry</v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </div>
    </v-card>
  </div>
</template>

<script>
import { GChart } from 'vue-google-charts';
import ChartSettingsMenu from './ChartSettingsMenu.vue';
import ChartTable from './ChartTable.vue';

export default {
  name: 'chartItem',
  props: {
    title: String,
    reportName: [Number, String],
    columns: [Array, Object],
    showHidden: Boolean,
    dashboardGroupName: String,
    reload: Boolean,
    stickyFirstColumn: [Number, Boolean],
    height: [String, Number],
    width: [String, Number],
    showExternalDashboards: Boolean,
    location: String,
    chartUpdateKey: [String, Number],
    reportsLoadingErrors: Array,
  },
  components: { GChart, ChartSettingsMenu, ChartTable },
  data() {
    return {
      data: [],
      hover: false,
      chartKey: 1,
      chartSettings: {
        packages: ['geochart', 'corechart', 'table'],
        mapsApiKey: 'AIzaSyCwlZpSdQlHiGnqFhzgfuAeLhaecO_6BoY',
      },
      headerHeight: 30, // Fixed height of the header
      rowHeight: 250, // height of each row in pixels (correlates with CSS "grid-auto-rows" in Dashboard.vue)
      maximized: false,
      isLoading: false,
    };
  },

  computed: {
    loadingData() {
      return !this.getChartData;
    },
    chartIsLoading() {
      return !(!this.isLoading && !this.loadingData);
    },
    loadError() {
      if (this.reportsLoadingErrors.length > 0) {
        const found = this.reportsLoadingErrors.find((item) => item === this.reportName);
        if (found) {
          return true;
        }
      }
      return false;
    },
    settings() {
      let result = {};
      if (this.location && this.reportName) {
        result = this.$store.getters.getChartSettings({
          location: this.location,
          dashboardGroupName: this.dashboardGroupName,
          reportName: this.reportName,
        });
      }
      return result;
    },
    getCurrentHeightUsed() {
      return this.settings.size.height;
    },
    getCurrentWidthUsed() {
      return this.settings.size.width;
    },
    getTableHeight() {
      const heightInInteger = this.getCurrentHeightUsed;
      return this.rowHeight * (heightInInteger + 1); // +1 to account for sizes: 0 = small, 1 = medium, 2 = large, 3 = x-large
    },
    cardColor() {
      let result;
      if (this.settings.hidden) {
        result = 'grey';
      }
      return result;
    },

    darkTheme() {
      return this.$vuetify.theme.dark;
    },

    chartOptions() {
      let result = {};
      if (this.settings.options) {
        result = { ...this.settings.options };
      }
      if (this.darkTheme) {
        result.backgroundColor = { fill: 'transparent' };
        const chartTextStyle = { color: '#FFF' };

        if (!result.vAxis) {
          result.vAxis = {};
        }
        result.vAxis.textStyle = chartTextStyle;
        result.vAxis.titleTextStyle = chartTextStyle;
        result.vAxis.gridlines = { color: '#787878' };

        if (!result.hAxis) {
          result.hAxis = {};
        }
        result.hAxis.textStyle = chartTextStyle;
        result.hAxis.titleTextStyle = chartTextStyle;

        if (!result.legend) {
          result.legend = {};
        }
        result.legend.textStyle = chartTextStyle;
      }
      result.width = 'auto';
      if (!this.maximized) {
        result.height = this.getTableHeight - this.headerHeight - 9; // 9 is to adjust for margins etc. (can be adjusted later & must have the same value in G-Chart)
      } else {
        result.height = 'auto';
      }
      return result;
    },
    chartSize() {
      return `${this.getWidth(this.getCurrentWidthUsed)} ${this.getHeight(this.getCurrentHeightUsed)}`;
    },
    getChartData() {
      return this.$store.getters.getChartData({ reportName: this.reportName });
    },
    chartData() {
      let data = this.getChartData;
      if (data && data.length > 0) {
        data = this.structurData(data);
      } else {
        data = [];
      }
      return data;
    },

    chartType() {
      return this.settings.chartType;
    },

    showChart() {
      let result = true;
      if (this.showHidden) {
        return true;
      }
      if (this.settings.hidden) {
        result = false;
      }
      if (this.settings.hiddenOnPhone && this.$vuetify.breakpoint.smAndDown) {
        result = false;
      }

      return result;
    },
  },

  watch: {
    maximized(val) {
      this.$emit('onMaximized', { maximized: val, reportName: this.reportName });
    },
    reload() {
      this.loadData();
    },

    showChart(val) {
      if (val && this.data.length == 0) {
        this.loadData();
      }
      // this.increaseChartKey();
    },
    data() {
      this.increaseChartKey();
    },

    settings() {
      // triggers when chart settings has changed.
      this.increaseChartKey();
    },

    chartUpdateKey() {
      this.increaseChartKey();
    },
  },

  methods: {
    drawGEOChart(el, google) {
      if (el === undefined) {
        return;
      }
      // eslint-disable-next-line consistent-return
      return new google.visualization[this.chartType](el);
    },
    setLoading(val) {
      this.isLoading = val;
    },
    increaseChartKey() {
      this.chartKey++;
    },
    updateChartKey() {
      this.$emit('updateChartKey');
    },
    getWidth(width) {
      switch (width) {
        case 0:
          return 's-width';
        case 1:
          return 'm-width';
        case 2:
          return 'l-width';
        case 3:
          return 'xl-width';
        default:
          return 's-width';
      }
    },
    getHeight(height) {
      switch (height) {
        case 0:
          return 's-height';
        case 1:
          return 'm-height';
        case 2:
          return 'l-height';
        case 3:
          return 'xl-height';
        default:
          return 's-height';
      }
    },

    async loadData() {
      this.setLoading(true);
      const { reportName } = this;
      try {
        this.$emit('setreportsLoadingErrors', { remove: true, reportName });
        await this.$store.dispatch('fetchChartData', { reportName });
      } catch (error) {
        this.$emit('setreportsLoadingErrors', { add: true, reportName });
      }
      this.increaseChartKey();
      this.setLoading(false);
    },

    structurData(orgData) {
      const result = [];
      // add row data
      if (orgData) {
        for (let index = 0; index < orgData.length; index++) {
          const row = [];
          const element = orgData[index];
          // eslint-disable-next-line no-restricted-syntax, guard-for-in
          for (const propname in element) {
            row.push(element[propname]);
          }
          result.push(row);
        }
      }

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  overflow: hidden;
}
</style>

<style scoped lang="scss">
.hidden {
  opacity: 0.3;
}
.maximized {
  //min-height: 100%;
  min-width: 100%;
  min-height: 93vh;
}

.loadingSection {
  min-height: 200px;
}

::v-deep .chartHeader {
  background: inherit;
  .chart-hover-menu {
    position: relative;
    right: 0;
    top: 0;
    background: inherit;
  }
}
</style>
