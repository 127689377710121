<template>
  <v-dialog v-model="dialogVisible" persistent max-width="600">
    <v-card class="pa-3" v-show="!deleteDialog">
      <v-layout wrap>
        <GatSubGroup title="Vessel master" size="xs12">
          <gat-edit
            v-model="master.NAME"
            label="Name"
            size="xs6"
            :errorMsg="nameError"
            :autofocus="true"
            :readonly="!$store.state.user.internal" />
          <gat-select
            v-model="master.NATIONALITY"
            label="Nationality"
            size="xs6"
            :items="$store.state.helper.countries"
            :readonly="!$store.state.user.internal">
          </gat-select>
          <gat-edit
            v-model="master.CERTIFICATE"
            label="Certificate"
            size="xs6"
            :readonly="!$store.state.user.internal" />
          <gat-edit
            v-model="master.INACTIVE"
            label="Inactive"
            checkbox
            size="xs12"
            :checkedValue="1"
            :uncheckedValue="0 || null"
            :readonly="!$store.state.user.internal" />
        </GatSubGroup>
      </v-layout>
      <v-card-actions>
        <v-btn text @click="deleteDialog = true" :disabled="readonly">Delete</v-btn>
        <v-spacer></v-spacer>
        <v-btn :color="$store.state.user.internal ? 'success' : 'text'" text @click="close('cancel')">{{
          $store.state.user.internal ? 'Cancel' : 'Close'
        }}</v-btn>
        <v-btn color="success" @click="close('save')" :disabled="readonly">Save</v-btn>
      </v-card-actions>
    </v-card>

    <v-card v-if="deleteDialog">
      <v-card-title class="title">Remove this master from current vessel?</v-card-title>
      <!-- <v-card-text>If the consent has been withdrawn you should use the legal base value "Whithdawn"</v-card-text> -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="deleteDialog = false">Cancel</v-btn>
        <v-btn
          text
          color="primary"
          @click="
            deleteDialog = false;
            close('delete');
          "
          >Delete</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import GatUtils from '@/app-components/GatUtils';

export default {
  name: 'VesselMaster',
  props: ['value', 'showForm', 'readonly'],
  components: {},
  data() {
    return {
      dialogVisible: false,
      deleteDialog: false,
      nameError: '',
      master: {},
      masterOrg: {},
    };
  },

  mounted() {
    this.master = GatUtils.makeCopyViaJson(this.value);
    this.dialogVisible = this.showForm;
  },

  watch: {
    value(newValue) {
      this.master = GatUtils.makeCopyViaJson(newValue);
    },
    showForm(newValue) {
      this.dialogVisible = newValue;
    },
  },

  computed: {},

  methods: {
    close(btn) {
      if (btn == 'cancel' || this.formIsValid()) {
        this.dialogVisible = false;
        this.$emit('close', { btn, value: this.master });
      }
    },

    formIsValid() {
      let result = true;
      if (!this.master.NAME) {
        result = false;
        this.nameError = ['Required'];
      }
      return result;
    },
  },
};
</script>

<style scoped></style>
