<template>
  <v-dialog v-model="showDialog" persistent max-width="1000">
    <v-card class="pa-3">
      <gat-form
        v-model="isValid"
        ref="form"
        :readonly="!this.$store.getters.userRight('CH.NEW_AND_EDIT')"
        :isLoading="isLoading">
        <gat-select
          label="Checklist template"
          v-model="templates.ID"
          :items="templates"
          textFieldName="NAME"
          codeFieldName="ID"
          size="xxl"
          @item-selected="loadTemplateItems" />
      </gat-form>
      <gat-grid
        dense
        elevation="0"
        :compact="true"
        title="Checklist items"
        :items="templateItems"
        :columns="columns"
        :selectable="true"
        keyName="ID"
        @selectionChanged="selectedItemKeys = $event"
        :selectedKeys="selectedItemKeys"
        height="500px"
        min-width="500px" />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" text @click="$emit('close')"> Close </v-btn>
        <v-btn
          color="success"
          :disabled="this.selectedItemKeys.length == 0 || !this.$store.getters.userRight('CH.NEW_AND_EDIT')"
          @click="addChecklistItems">
          {{ setAddButtonDescription }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { apiGet, apiPost } from '@/store/api';

export default {
  name: 'checkListTemplateDialog',
  props: {
    showDialog: Boolean,
    callId: Number,
    agencyId: Number,
  },
  components: {},
  data() {
    return {
      isValid: true,
      templates: [],
      templateItems: [],
      selectedItemKeys: [],
      isLoading: false,
    };
  },

  watch: {
    agencyId: {
      handler(val) {
        if (val && this.showDialog) {
          this.loadChecklistTemplates(val);
        }
      },
      immediate: true,
    },
    showDialog: {
      handler(val) {
        if (val && this.agencyId) {
          this.loadChecklistTemplates(this.agencyId);
        }
      },
      immediate: true,
    },
  },

  computed: {
    // Columns for the gat-grid.
    columns() {
      const result = [];
      result.push({ header: 'Item name', field: 'NAME' });
      result.push({ header: 'Status', field: 'STATUS' });
      result.push({ header: 'Type', field: 'TYPE', hideOnPhone: true });
      result.push({ header: 'Reminder', field: 'REMINDER', hideOnPhone: true, checkbox: true });
      result.push({ header: 'Grouping', field: 'GROUPING', hideOnPhone: true });
      result.push({ header: 'Comments', field: 'COMMENTS', hideOnPhone: true });

      return result;
    },

    // Set the text field of the save btn.
    setAddButtonDescription() {
      let result = this.selectedItemKeys.length == 0 ? 'No items selected' : `add ${this.selectedItemKeys.length} item`;
      if (this.selectedItemKeys.length > 1) {
        result += 's';
      }
      return result;
    },
  },

  methods: {
    // adds the selected items to the checklist. Triggered by the event @ save btn.
    addChecklistItems() {
      for (let i = 0; i < this.selectedItemKeys.length; i++) {
        const itemId = this.selectedItemKeys[i];
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const item = this.templateItems.find((item) => item.ID == itemId);
        // eslint-disable-next-line @typescript-eslint/no-shadow, array-callback-return
        this.createItem(item).map((item) => {
          // set isLoading to true to show progressbar if response is not immidiate
          this.isLoading = true;
          apiPost('checklist/', item).then((result) => {
            // remove progressbar
            this.isLoading = false;
            if (result.resultCategory == 1) {
              this.$store.commit('addChecklistItem', result.payload);
            }
          });
        });
      }
      this.selectedItemKeys = [];
      this.$emit('close');
    },

    // used in method addChecklistItems in order to exclude the ID of the object.
    createItem(item) {
      const result = [];
      result.push({
        PORTCALL_ID: this.callId,
        CHECKLIST_NUMBER: item.NUMBER_TEXT,
        ITEM: item.NAME,
        STATUS: item.STATUS,
        TYPE: item.TYPE,
        COMMENTS: item.COMMENTS,
        HISTORY: item.HISTORY,
        REMINDER: item.REMINDER,
        REM_TYPE: item.REM_TYPE,
        REM_RELATIVE: item.REM_RELATIVE,
        REM_REL_TYPE: item.REM_REL_TYPE,
        GROUPING: item.GROUPING,
      });
      return result;
    },

    loadChecklistTemplates(agencyId) {
      // set isLoading to true to show progressbar if response is not immidiate
      this.isLoading = true;
      apiGet(`checklist/templates/${agencyId}`).then((result) => {
        // remove progressbar
        this.isLoading = false;
        this.templates = result;
      });
    },

    loadTemplateItems(object) {
      if (object.ID) {
        const id = object.ID;
        // set isLoading to true to show progressbar if response is not immidiate
        this.isLoading = true;
        apiGet(`checklist/template/${id}`)
          .then((result) => {
            // remove progressbar
            this.isLoading = false;
            this.templateItems = result;
          })
          .finally(() => this.selectAllItems());
      }
    },

    // used in method loadTemplateItems to set all the items to selected when a new template is loaded.
    selectAllItems() {
      this.selectedItemKeys = [];
      for (let i = 0; i < this.templateItems.length; i++) {
        this.selectedItemKeys.push(this.templateItems[i].ID);
      }
    },
  },
};
</script>

<style scoped></style>
