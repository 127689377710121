import { apiGetFile } from '@/store/api';

export const DocumentMixin = {
  components: {},

  methods: {
    download(docId, fileName) {
      if (docId) {
        // && this.$store.state.globalSettings.SGL_DOC_ENGINE_VER
        apiGetFile(`document/download/${docId}`, fileName).then(() => {});
      }
    },

    getMdiFileIcon(fileName) {
      let result = {
        icon: 'mdi-file',
        color: 'grey',
      };
      if (!fileName) {
        return result;
      }

      if (fileName.endsWith('docx') || fileName.endsWith('rtf') || fileName.endsWith('doc')) {
        result = { icon: 'mdi-file-word-box', color: 'blue darken-2' };
      } else if (fileName.endsWith('xlsx') || fileName.endsWith('xls') || fileName.endsWith('csv')) {
        result = { icon: 'mdi-file-excel-box', color: 'green darken-2' };
      } /* else if (fileName.endsWith("txt") || fileName.endsWith("csv")) {
        return iconLocation + "txt.png";
      }  */ else if (fileName.endsWith('pdf')) {
        result = { icon: 'mdi-file-pdf-box', color: 'red darken-2' };
      } else if (fileName.endsWith('jpg') || fileName.endsWith('jpeg')) {
        result = { icon: 'mdi-file-jpg-box', color: 'yellow darken-3' };
      } else if (fileName.endsWith('png')) {
        result = { icon: 'mdi-file-png-box', color: 'yellow darken-3' };
      }
      return result;
    },

    // Changed to getMdiFileIcon
    /* getFileIconName(fileName) {
      let iconLocation = "/fileicons/";
      if (!fileName) {
        return iconLocation + "gen_file.png";
      }

      if (
        fileName.endsWith("docx") ||
        fileName.endsWith("rtf") ||
        fileName.endsWith("doc")
      ) {
        return iconLocation + "docx.png";
      } else if (
        fileName.endsWith("xlsx") ||
        fileName.endsWith("xls") ||
        fileName.endsWith("csv")
      ) {
        return iconLocation + "docx.png";
      } else if (fileName.endsWith("txt") || fileName.endsWith("csv")) {
        return iconLocation + "txt.png";
      } else if (fileName.endsWith("pdf")) {
        return iconLocation + "pdf.png";
      } else {
        return iconLocation + "gen_file.png";
      }
    }, */
  },
};
