<template>
  <div>
    <!-- <h3>Active calls</h3> -->
    <v-container class="ma-0 pa-0">
      <v-layout>
        <gat-select
          v-if="whiteBoards && whiteBoards.length > 1"
          v-model="whiteboardId"
          :items="whiteBoards"
          label="Whiteboard"
          textFieldName="name"
          codeFieldName="id">
        </gat-select>
        <gat-edit
          v-if="whiteBoards && whiteBoards.length > 1"
          v-model="maxRows"
          label="max rows"
          dataType="number"
          hide="phone"
          size="xs" />
        <gat-edit
          v-if="$store.state.user.internal && $store.getters.userRight('G.ACCESS_ALL_AGENCIES')"
          checkbox
          :label="isPhone ? 'all agencies' : 'Show port calls from all agencies'"
          :value="getShowAllAgencies"
          @input="showAllCallsChanged"></gat-edit>
      </v-layout>
    </v-container>
    <white-board
      v-if="whiteboard"
      :whiteBoard="whiteboard"
      :rowData="WhiteBoardData"
      :favCalls="favCalls"
      :maxRows="maxRows"
      :loading="loading"
      @reload="loadData" />
    <!-- <record-presenter :value="whiteboard" /> -->
  </div>
</template>

<script>
import WhiteBoard from '@/app-components/WhiteBoard.vue';
// eslint-disable-next-line import/no-cycle
import { apiPost, apiGet } from '@/store/api';

export default {
  name: 'PortCalls',
  props: {
    favoritesOnly: {
      default: false,
      type: Boolean,
    },
  },
  components: { WhiteBoard },
  data() {
    return {
      whiteboardId: 0,
      maxRows: 100,
      saveLastWhiteboard: true,
      loading: null,
    };
  },

  watch: {
    whiteboardId(val, oldVal) {
      if (val && val != oldVal) {
        if (this.saveLastWhiteboard) {
          const savedInfo = {
            TypeName: 'web_last_whiteboard',
            StrValue: val,
          };
          apiPost('savedInfo', savedInfo);
        } else {
          this.saveLastWhiteboard = true;
        }
      }
    },

    whiteboard: {
      handler(val, oldVal) {
        if (val && this.whiteboardId == 0) {
          this.saveLastWhiteboard = false;
          this.whiteboardId = val.id; // for å sette select ved default wb
        }
        if (val && val != oldVal) {
          this.loadData();
        }
      },
      immediate: true,
    },
  },

  computed: {
    getShowAllAgencies() {
      if (
        this.$store.state.user &&
        this.$store.state.user.userSettings &&
        this.$store.state.user.userSettings.whiteBoards
      ) {
        return this.$store.state.user.userSettings.whiteBoards.showCallsFromAllAgencies;
      }
      return false;
    },
    isPhone() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    whiteboard() {
      let wb = this.$store.state.whiteboard.whiteBoards.find((item) => item.id == this.whiteboardId);

      if (!wb && this.$store.state.whiteboard.whiteBoards.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        wb = this.$store.state.whiteboard.whiteBoards[0];
      }

      return wb;
    },
    whiteBoards() {
      return this.$store.state.whiteboard.whiteBoards;
    },
    WhiteBoardData() {
      if (this.favoritesOnly) {
        if (this.$store.state.whiteboard.whiteBoardData.length > 0 && this.$store.getters.favCalls.length > 0) {
          const result = [];
          this.$store.state.whiteboard.whiteBoardData.forEach((item) => {
            if (this.$store.getters.favCalls.indexOf(item.ID) >= 0) {
              result.push(item);
            }
          });

          return result;
        }
      } else {
        return this.$store.state.whiteboard.whiteBoardData;
      }
      return [];
    },
    favCalls() {
      return this.$store.getters.favCalls;
    },
  },

  activated() {
    if (this.$store.state.whiteboard.isDirty) {
      this.loadData();
    }
  },

  created() {
    apiGet('savedinfo/web_last_whiteboard').then((data) => {
      if (data.length > 0) {
        const wbId = parseInt(data[0].MEMOVALUE1, 10);
        if (wbId && this.whiteboardId != wbId) {
          this.saveLastWhiteboard = false;
          this.whiteboardId = wbId;
        }
      }
    });
    window.messageBus.$on('new-agency-selected', () => {
      this.loading = true;
      this.$store.dispatch('getWhiteBoards', this.$store.state.application.agencyIdSelected).then(() => {
        this.loadData();
      });
    });
  },

  methods: {
    loadData() {
      if (this.whiteboard) {
        this.loading = true;
        let includeAllAgencies = false;
        if (
          this.$store.state.user &&
          this.$store.state.user.userSettings &&
          this.$store.state.user.userSettings.whiteBoards
        ) {
          includeAllAgencies = this.$store.state.user.userSettings.whiteBoards.showCallsFromAllAgencies;
        }
        this.$store
          .dispatch('getWhiteBoardData', {
            whiteboardId: this.whiteboard.id,
            agencyId: this.$store.state.application.agencyIdSelected,
            maxRows: this.maxRows,
            includeAllAgencies,
          })
          .then(() => {
            this.loading = false;
            this.$store.commit('setWhiteBoardDataIsDirty', false);
          });
      }
    },

    showAllCallsChanged(showAll) {
      const newSettings = { ...this.$store.state.user.userSettings.whiteBoards };
      newSettings.showCallsFromAllAgencies = showAll;
      this.$store.commit('setWhiteBoardUserSettings', newSettings);
      this.loadData();
      const savedInfo = {
        TypeName: `web_whiteboard_includeAllAgencies`,
        StrValue: JSON.stringify(showAll),
      };
      apiPost('savedInfo', savedInfo);
    },
  },
};
</script>

<style scoped></style>
