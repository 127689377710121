/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/naming-convention */
// eslint-disable-next-line import/no-cycle
import { apiGet } from './api';

const vuex_helper = {
  state: {
    callTypes: [],
    cargoActions: [],
    cargoTypes: [],
    clientCategories: [],
    clientTypes: [],
    countries: [],
    consentTypes: { purposes: [], legalBases: [] },
    currencies: [],
    documentLabels: [],
    documentTemplates: [],
    dynafields: [],
    dynaWebTabs: [],
    hotlistTemporaryTypes: [],
    ownHarbours: [],
    ownTerminals: [],
    serviceTypes: [],
    teams: [],
    vesselTypes: [],
    webAccessGroups: [],
    worklineStatements: [],
    expenseTemplates: [],
    packageTypes: [],
    unitTypes: [],
    users: [],
    vatCodes: [],
    tabSequence: [],
    tarifs: [],
    towageCompanies: [],
    tabHasSqlScripts: {},
    worklineCargoes: [],
    crewChangeTypes: [],
    crewChangeRanks: [],
    pendingActivities: 0,
    // variable for the timeout interval.
    activitiesTimeout: undefined,
  },

  mutations: {
    initFixedValues(state) {
      state.clientCategories = [
        { value: 0, text: 'Customer / debitor' },
        { value: 1, text: 'Supplier / creditor' },
        { value: 2, text: 'Other' },
      ];
    },
    setCallTypes(state, data) {
      state.callTypes = data;
    },
    setCargoTypes(state, cargoTypes) {
      state.cargoTypes = cargoTypes;
    },
    setClientTypes(state, data) {
      state.clientTypes = data;
    },
    setConsentTypes(state, data) {
      const purposes = [];
      const legalBases = [];
      data.map((item) => {
        if (item.COT_TYPE == 0) {
          purposes.push(item);
        } else if (item.COT_TYPE == 1) {
          legalBases.push(item);
        }
      });
      state.consentTypes.purposes = purposes;
      state.consentTypes.legalBases = legalBases;
    },
    setCountries(state, countries) {
      state.countries = countries;
    },
    setCurrencies(state, data) {
      state.currencies = data;
    },
    setDocumentLabels(state, data) {
      state.documentLabels = data;
    },
    setDocumentTemplates(state, data) {
      state.documentTemplates = data;
    },
    setDynaFields(state, data) {
      state.dynafields = data;
    },
    setDynaWebTabs(state, data) {
      state.dynaWebTabs = data;
    },
    setExpenseTemplates(state, data) {
      const idx = state.expenseTemplates.findIndex((item) => item.agencyId == data.agencyId);
      if (idx >= 0) {
        state.expenseTemplates.splice(idx, 1, data);
      } else {
        state.expenseTemplates.push(data);
      }
    },
    setHotlistTemporaryTypes(state, data) {
      state.hotlistTemporaryTypes = data;
    },
    setOwnHarbours(state, data) {
      state.ownHarbours = data;
    },
    setOwnTerminals(state, data) {
      state.ownTerminals = data;
    },
    setPackageTypes(state, data) {
      state.packageTypes = data;
    },
    setServiceTypes(state, data) {
      state.serviceTypes = data;
    },
    setSqlScripts(state, data) {
      const result = {};
      for (let i = 0; data.length > i; i++) {
        const key = data[i].DASHBOARD.toUpperCase().trim();
        const value = data[i].SQL_SCRIPTS;
        // console.log(key, value);
        result[key] = +value;
      }
      state.tabHasSqlScripts = result;
    },

    setTabSequence(state, data) {
      state.tabSequence = data;
    },

    setTarifs(state, data) {
      state.tarifs = data;
    },
    setTeams(state, data) {
      state.teams = data;
    },
    setUnitTypes(state, data) {
      state.unitTypes = data;
    },
    setUsers(state, data) {
      state.users = data;
    },
    setVatCodes(state, data) {
      const idx = state.vatCodes.findIndex((item) => item.agencyId == data.agencyId);
      if (idx >= 0) {
        state.vatCodes.splice(idx, 1, data);
      } else {
        state.vatCodes.push(data);
      }
    },
    setVesselTypes(state, data) {
      state.vesselTypes = data;
    },
    setWebAccessGroups(state, data) {
      state.webAccessGroups = data;
    },
    setWorklineStatements(state, data) {
      state.worklineStatements = data;
    },
    setCargoActions(state, data) {
      state.cargoActions = data;
    },
    setTowageCompanies(state, data) {
      state.towageCompanies = data;
    },
    setDynaFieldSQLCombo(state, params) {
      const idx = state.dynafields.findIndex((item) => item.ID == params.ID);
      state.dynafields[idx].COMBO_ITEMS = params.COMBOITEMS;
    },
    setWorklineCargoes(state, data) {
      state.worklineCargoes = data;
    },
    setCrewChangeTypes(state, data) {
      state.crewChangeTypes = data;
    },
    setPendingActivities(state, data) {
      state.pendingActivities = data;
    },
    setcrewChangeRanks(state, data) {
      state.crewChangeRanks = data;
    },
  },

  actions: {
    getAllHelpers({ commit }) {
      return new Promise((resolve) => {
        apiGet('helper')
          .then((data) => {
            commit('setCallTypes', data.callTypes);
            commit('setWebAccessGroups', data.webAccessGroups);
            commit('setServiceTypes', data.serviceTypes);
            commit('setCargoActions', data.cargoActions);
            commit('setCargoTypes', data.cargoTypes);
            commit('setUnitTypes', data.unitTypes);
            commit('setPackageTypes', data.packageTypes);
            commit('setHotlistTemporaryTypes', data.hotlistTemporaryTypes);
            commit('setCrewChangeTypes', data.crewChangeTypes);
            commit('setcrewChangeRanks', data.crewChangeRanks);
          })
          .finally(() => {
            resolve();
          });
      });
    },

    getCallTypes({ commit }) {
      return apiGet('helper/callTypes/').then((data) => {
        commit('setCallTypes', data);
      });
    },

    getClientTypes({ commit }) {
      return new Promise((resolve) => {
        apiGet('helper/clientTypes/')
          .then((data) => {
            commit('setClientTypes', data);
          })
          .finally(() => {
            resolve();
          });
      });
    },

    getConsentTypes({ commit }) {
      return apiGet('helper/consentTypes').then((data) => {
        commit('setConsentTypes', data);
      });
    },
    getCountries({ commit }) {
      return new Promise((resolve) => {
        apiGet('helper/countries')
          .then((data) => {
            commit('setCountries', data);
          })
          .finally(() => {
            resolve();
          });
      });
    },

    getCurrencies({ commit }) {
      return new Promise((resolve) => {
        apiGet('helper/currencies')
          .then((data) => {
            commit('setCurrencies', data);
          })
          .finally(() => {
            resolve();
          });
      });
    },

    getDocumentLabels({ commit }) {
      return new Promise((resolve) => {
        apiGet('helper/documentLabels')
          .then((data) => {
            commit('setDocumentLabels', data);
          })
          .finally(() => {
            resolve();
          });
      });
    },

    getDocumentTemplates({ commit }) {
      return new Promise((resolve) => {
        apiGet('helper/documentTemplates/')
          .then((data) => {
            commit('setDocumentTemplates', data);
          })
          .finally(() => {
            resolve();
          });
      });
    },

    getDynaFields({ commit }) {
      return new Promise((resolve) => {
        apiGet('helper/dynafields')
          .then((data) => {
            commit('setDynaFields', data);
            // commit('setAppCriticalInitFinished',true); - removed 05.10-2021
          })
          .finally(() => {
            resolve();
          });
      });
    },

    getDynaWebTabs({ commit }) {
      return new Promise((resolve) => {
        apiGet('helper/dybaWebTabs')
          .then((data) => {
            commit('setDynaWebTabs', data);
          })
          .finally(() => {
            resolve();
          });
      });
    },

    getExpenseTemplates({ commit }, agencyId) {
      return apiGet(`helper/expenseTemplates/agencyId/${agencyId}`).then((data) => {
        commit('setExpenseTemplates', { agencyId, data });
      });
    },

    /* getInAttendance({commit}){
          return apiGet('attendance/isActive/').then(data=>{
              commit('setInAttendance',data);
              });
        }, */

    getOwnHarbours({ commit }) {
      return new Promise((resolve) => {
        apiGet('helper/ownHarbours')
          .then((data) => {
            commit('setOwnHarbours', data);
          })
          .finally(() => {
            resolve();
          });
      });
    },

    getOwnTerminals({ commit }) {
      return new Promise((resolve) => {
        apiGet('helper/ownTerminals')
          .then((data) => {
            commit('setOwnTerminals', data);
          })
          .finally(() => {
            resolve();
          });
      });
    },

    getServiceTypes({ commit }) {
      return apiGet('helper/serviceTypes/').then((data) => {
        commit('setServiceTypes', data);
      });
    },

    getSqlScripts({ commit }) {
      return new Promise((resolve) => {
        apiGet('helper/sqlScripts/')
          .then((data) => {
            commit('setSqlScripts', data);
          })
          .finally(() => {
            resolve();
          });
      });
    },

    getTabSequence({ commit }) {
      return new Promise((resolve) => {
        apiGet('helper/tabSequence/')
          .then((data) => {
            commit('setTabSequence', data);
          })
          .finally(() => {
            resolve();
          });
      });
    },

    getTarifs({ commit }) {
      return new Promise((resolve) => {
        apiGet('helper/priceTarifs/')
          .then((data) => {
            commit('setTarifs', data);
          })
          .finally(() => {
            resolve();
          });
      });
    },

    getTeams({ commit }) {
      return new Promise((resolve) => {
        apiGet('helper/teams')
          .then((data) => {
            commit('setTeams', data);
          })
          .finally(() => {
            resolve();
          });
      });
    },

    getOwnTowageCompanies({ commit }) {
      return apiGet('helper/towageCompanies').then((data) => {
        commit('setTowageCompanies', data);
      });
    },

    getUsers({ commit }) {
      return new Promise((resolve) => {
        apiGet('helper/users')
          .then((data) => {
            commit('setUsers', data);
          })
          .finally(() => {
            resolve();
          });
      });
    },

    getVatCodes({ commit }, agencyId) {
      return apiGet(`helper/vatCodes/agencyId/${agencyId}`).then((data) => {
        commit('setVatCodes', { agencyId, data });
      });
    },

    getVesselTypes({ commit }) {
      return new Promise((resolve) => {
        apiGet('helper/vesselTypes/')
          .then((data) => {
            commit('setVesselTypes', data);
          })
          .finally(() => {
            resolve();
          });
      });
    },

    getWorklineStatements({ commit }) {
      return new Promise((resolve) => {
        apiGet('sof/workStatements')
          .then((data) => {
            commit('setWorklineStatements', data);
          })
          .finally(() => {
            resolve();
          });
      });
    },

    getWorklineCargoes({ commit }, { callid, sofid }) {
      return new Promise((resolve) => {
        apiGet(`sof/worklineCargoes/${callid}/${sofid}`)
          .then((data) => {
            commit('setWorklineCargoes', data);
          })
          .finally(() => {
            resolve();
          });
      });
    },

    getPendingActivities({ commit, dispatch }) {
      return new Promise((resolve) => {
        let result;
        apiGet(`activity/pending`)
          .then((data) => {
            commit('setPendingActivities', data);
          })
          .finally(() => {
            if (this.activitiesTimeout) {
              clearTimeout(this.activitiesTimeout);
            }
            // Runs every 20 minutes since last time it was ran.
            this.activitiesTimeout = setTimeout(() => {
              dispatch('getPendingActivities');
            }, 60000 * 20);
            resolve(result);
          });
      });
    },
  },

  getters: {
    getCountryValueFromCountryName: (state) => (name) => {
      if (name) {
        const countries = [...state.countries];
        const found = countries.find((item) => item.text.toUpperCase() === name.toUpperCase());
        if (found && found.value) {
          return found.value;
        }
      }
      return undefined;
    },
    serviceStatuses() {
      return [
        {
          ID: 10,
          NAME: 'Not handled',
          icon: 'mdi-alert-circle',
          iconClass: 'red--text',
          class: 'red--text',
        },
        {
          ID: 20,
          NAME: 'Pending',
          icon: 'hourglass_empty',
          iconClass: 'blue--text',
        },
        {
          ID: 40,
          NAME: 'Closed',
          icon: 'check_circle',
          iconClass: 'green--text',
        },
      ];
    },
    expenseTemplates: (state) => (agencyId) => {
      let result = [];
      const idx = state.expenseTemplates.findIndex((item) => item.agencyId == agencyId);
      if (idx >= 0) {
        result = state.expenseTemplates[idx].data;
      }
      return result;
    },

    documentTemplates: (state) => (docType, agencyId) => {
      const result = [];
      const templates = state.documentTemplates.filter((item) => item.TYPE == docType);
      templates.map((item) => {
        if (!item.filter) {
          result.push(item);
        } else if (item.filter.includes(agencyId)) {
          result.push(item);
        }
      });
      return result;
    },
    teams: (state) => (agencyId) =>
      // eslint-disable-next-line consistent-return
      state.teams.filter((item) => {
        if (item.AF_AGENCY_ID === agencyId) return item;
        if (item.AF_AGENCY_ID === null) {
          return item;
        }
      }),
    allTeams: (state) => state.teams,
  },
};

export default vuex_helper;
