/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/naming-convention */
// eslint-disable-next-line import/no-cycle
import { apiPost, apiGet } from './api';

const vuex_application = {
  state: {
    appCriticalInitFinished: false,
    appTitle: 'GAT-Ship',
    appIsInitialized: false,
    agencyIdSelected: null,
    // "debugMode: true" endres til "debugMode: false" i bygge prosessen.
    debugMode: false,
    lastError: {},
    unsavedDataOnCurrentPage: false,
    unsavedDialogPage: undefined,
    unsavedPages: [],

    traversableList: {
      showCounter: false,
      currentIndex: undefined,
      items: [],
    },
    clients: {
      lastClientsUsed: [],
    },
    isCRM: false,
    version: '06.09.2023 14:44',
    varcharColumns: null,
    emailListIsDirty: false,
    /** Used for the document generator */
    appStatus: {
      PortCall: {},
      VesselId: undefined,
      SetupID: undefined,
    },
  },

  mutations: {
    setEmailListIsDirty(state, value) {
      if (typeof value === 'boolean') {
        state.emailListIsDirty = value;
      }
    },
    setAppCriticalInitFinished(state, value) {
      state.appCriticalInitFinished = value;
    },

    setAppTitle(state, title) {
      state.appTitle = title;
    },

    setAppIsInitialized(state, value) {
      state.appIsInitialized = value;
    },

    setLastError(state, error) {
      state.lastError = error;
    },

    /* setDrawerMenu(state,data){
            state.drawerMenu = data;
        }, */

    setNewAgency(state, data) {
      state.agencyIdSelected = data;
    },

    setTraversableList(state, data) {
      state.traversableList.items = data;
    },
    setUnsavedDataOnCurrentPage(state, status) {
      state.unsavedDataOnCurrentPage = status;
    },
    setUnsavedDialogPage(state, data) {
      state.unsavedDialogPage = data;
    },
    removePageSavedState(state, page) {
      const idx = state.unsavedPages.findIndex((item) => item.routeName == page.routeName);
      if (idx >= 0) {
        state.unsavedPages.splice(idx, 1);
      }
    },
    removeFromLastClientUsed(state, clientId) {
      const lastUsedClients = [...state.clients.lastClientsUsed];
      const idx = lastUsedClients.findIndex((item) => item.ID == clientId);
      if (idx >= 0) {
        lastUsedClients.splice(idx, 1);
      }
      state.clients.lastClientsUsed = lastUsedClients;
      const savedInfo = {
        TypeName: 'web_last_clients',
        StrValue: JSON.stringify(lastUsedClients),
      };
      apiPost('savedInfo', savedInfo);
    },
    addToLastClientUsed(state, client) {
      const idx = state.clients.lastClientsUsed.findIndex((item) => item.ID == client.ID);
      if (idx >= 0) {
        state.clients.lastClientsUsed.splice(idx, 1);
      }
      state.clients.lastClientsUsed.unshift(client);
      if (state.clients.lastClientsUsed.length > 3) {
        state.clients.lastClientsUsed.pop();
      }
      // save to SavedInfo
      const clients = [];
      // eslint-disable-next-line array-callback-return
      state.clients.lastClientsUsed.map((item) => {
        const obj = { ID: item.ID, NAME: item.NAME };
        clients.push(obj);
      });
      const savedInfo = {
        TypeName: 'web_last_clients',
        StrValue: JSON.stringify(clients),
      };
      apiPost('savedInfo', savedInfo);
    },
    setLastClientsUsed(state, data) {
      state.clients.lastClientsUsed = data;
    },
    setTraversableListIndex(state, data) {
      if (data < 0) {
        state.traversableList.showCounter = false;
      }
      state.traversableList.currentIndex = data + 1;
    },
    setTraversableListCounter(state, data) {
      state.traversableList.showCounter = data;
    },
    setPageSavedState(state, pageState) {
      const idx = state.unsavedPages.findIndex((item) => item.routeName == pageState.routeName);
      if (idx >= 0) {
        if (pageState.unSaved) {
          state.unsavedPages.splice(idx, 1, pageState);
        } else {
          state.unsavedPages.splice(idx, 1);
        }
      } else if (pageState.unSaved) {
        state.unsavedPages.push(pageState);
      }
    },
    setIsCrm(state, isCrm) {
      state.isCRM = isCrm;
    },
    setVarcharColumns(state, data) {
      state.varcharColumns = data;
    },
  },

  actions: {
    getLastClientsUsed({ commit }) {
      return new Promise((resolve) => {
        apiGet('savedinfo/web_last_clients')
          .then((data) => {
            if (data.length > 0) {
              const lastClients = JSON.parse(data[0].MEMOVALUE1);
              commit('setLastClientsUsed', lastClients);
            }
          })
          .finally(() => {
            resolve();
          });
      });
    },

    getVarcharColumns({ commit }) {
      return new Promise((resolve) => {
        apiGet('application/varcharColumns')
          .then((data) => {
            commit('setVarcharColumns', data);
          })
          .finally(() => {
            resolve();
          });
      });
    },

    initializeApp({ dispatch, commit, rootGetters, rootState }) {
      return new Promise((resolve) => {
        commit('initFixedValues');
        dispatch('getUser').then(() => {
          const promises = [];
          dispatch('getDarkModeSetting').then(() => {
            // set the darkmode based on the value retrivbed from the db (store value)
          });

          const getAppSettings = dispatch('getAppSettings');
          promises.push(getAppSettings);

          const getPendingActivities = dispatch('getPendingActivities');
          promises.push(getPendingActivities);

          const getDashboardGroups = dispatch('getDashboardGroups');
          promises.push(getDashboardGroups);
          const getAllGatGridViews = dispatch('getAllGatGridViews');
          promises.push(getAllGatGridViews);
          if (rootGetters.userRight('AT.WEB_ACCESS')) {
            const getBerthedPortcalls = dispatch('getBerthedPortcalls');
            promises.push(getBerthedPortcalls);
            const getInAttendance = dispatch('getInAttendance');
            promises.push(getInAttendance);
            const getAttendanceCodes = dispatch('getAttendanceCodes');
            promises.push(getAttendanceCodes);
          }
          const getLastPortCalls = dispatch('getLastPortCalls');
          promises.push(getLastPortCalls);
          const getLastClientsUsed = dispatch('getLastClientsUsed');
          promises.push(getLastClientsUsed);
          const getTarifs = dispatch('getTarifs');
          promises.push(getTarifs);
          const getAgencySettings = dispatch('getAgencySettings').then(() => {
            if (
              rootState.agencySettings &&
              rootState.agencySettings.length > 0 &&
              rootState.agencySettings[0].GROUP_NAME == 'crm'
            ) {
              commit('setIsCrm', true);
            }
            // else {
            //   document.title = 'GAT-Ship Web Module';
            // }
          });
          promises.push(getAgencySettings);

          const getVarcharColumns = dispatch('getVarcharColumns');
          promises.push(getVarcharColumns);
          const getDynaFields = dispatch('getDynaFields');
          promises.push(getDynaFields);
          const getDynaWebTabs = dispatch('getDynaWebTabs');
          promises.push(getDynaWebTabs);
          const getAllHelpers = dispatch('getAllHelpers');
          promises.push(getAllHelpers);
          const getDocumentTemplates = dispatch('getDocumentTemplates');
          promises.push(getDocumentTemplates);
          const getCountries = dispatch('getCountries');
          promises.push(getCountries);
          const getCurrencies = dispatch('getCurrencies');
          promises.push(getCurrencies);
          const getClientTypes = dispatch('getClientTypes');
          promises.push(getClientTypes);
          const getDocumentLabels = dispatch('getDocumentLabels');
          promises.push(getDocumentLabels);
          // dispatch('getFavorites');
          const getGlobalSettings = dispatch('getGlobalSettings').then(() => {
            // numeral.locale(rootState.settings.numberFormat);
            // });
            promises.push(getGlobalSettings);
            const getVesselTypes = dispatch('getVesselTypes');
            promises.push(getVesselTypes);
            const getOwnHarbours = dispatch('getOwnHarbours');
            promises.push(getOwnHarbours);
            const getOwnTerminals = dispatch('getOwnTerminals');
            promises.push(getOwnTerminals);
            const getTeams = dispatch('getTeams');
            promises.push(getTeams);
            const getUsers = dispatch('getUsers');
            promises.push(getUsers);
            const getSqlScripts = dispatch('getSqlScripts');
            promises.push(getSqlScripts);
            const getWorklineStatements = dispatch('getWorklineStatements');
            promises.push(getWorklineStatements);
            const getTabSequence = dispatch('getTabSequence');
            promises.push(getTabSequence);
            const getSavedInfoForWeb = dispatch('getSavedInfoForWeb');
            promises.push(getSavedInfoForWeb);
            const getWhiteBoards = dispatch('getWhiteBoards', rootState.application.agencyIdSelected);
            promises.push(getWhiteBoards);
            const getFavorites = dispatch('getFavorites');
            promises.push(getFavorites);
            // If external client, see if any upcoming tasks for your own firm
            if (rootState.user && !rootState.user.internal) {
              if (rootGetters.userRight('CRCH.ACCESS') || rootGetters.userRight('CRCH.ACCESS_OWN_TASK')) {
                if (!rootState.user.upcomingTasks.accommodation && !rootState.user.upcomingTasks.transport) {
                  const getNumUpcomingTasks = dispatch('getNumUpcomingTasks', rootState.user.externalClientId);
                  promises.push(getNumUpcomingTasks);
                }
              }

              if (rootGetters.userRight('SER.ACCESS')) {
                if (!rootState.user.upcomingTasks.service) {
                  const getNumUpcomingServices = dispatch('getNumUpcomingServices', rootState.user.externalClientId);
                  promises.push(getNumUpcomingServices);
                }
              }
            }
            Promise.allSettled(promises).finally(() => {
              if (rootState.application.debugMode)
                console.log('%cApp initialization complete.', 'background: #222; color: green');
              commit('setAppCriticalInitFinished', true);
              commit('setAppIsInitialized', true);
              resolve();
            });
          });
        });
      });
    },
  },

  getters: {
    getApplicationStatus(/* state */) {},
    getCurrentAgency(state) {
      return state.agencyIdSelected;
    },
  },
};

export default vuex_application;
