import { render, staticRenderFns } from "./VisitorItem.vue?vue&type=template&id=474ca4aa&scoped=true&"
import script from "./VisitorItem.vue?vue&type=script&lang=js&"
export * from "./VisitorItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "474ca4aa",
  null
  
)

export default component.exports