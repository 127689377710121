<template>
  <div>
    <CrewChangeFilter :callId="this.callId" />
    <CrewChangeTaskList
      v-if="userHasTaskRights"
      :callId="this.callId"
      title="Tasks"
      v-show="this.showTables === 0 || this.showTables === 1"
      :fullsize="this.showTables === 1" />
    <CrewChangePeopleList
      v-if="userHasPeopleRights"
      :callId="this.callId"
      v-show="this.showTables === 0 || this.showTables === 2"
      :fullsize="this.showTables === 2 || !userHasTaskRights" />
  </div>
</template>

<script>
import CrewChangeTaskList from './CrewChangeTaskList.vue';
import CrewChangePeopleList from './CrewChangePeopleList.vue';
import CrewChangeFilter from './CrewChangeFilter.vue';

export default {
  name: 'CrewChange',
  props: {
    callId: [Number, String],
  },
  components: {
    CrewChangeTaskList,
    CrewChangePeopleList,
    CrewChangeFilter,
  },
  data() {
    return {
      showTables: null,
    };
  },

  activated() {
    window.messageBus.$on('showTableinCCM', (tableChoice) => {
      this.showTables = tableChoice;
    });
  },

  computed: {
    userHasTaskRights() {
      return this.$store.getters.userRight('CRCH.ACCESS') || this.$store.getters.userRight('CRCH.ACCESS_OWN_TASK');
    },
    userHasPeopleRights() {
      return (
        this.$store.getters.userRight('CRCH.ACCESS') ||
        this.$store.getters.userRight('CRCH.ACCESS_OWN_TASK') ||
        this.$store.getters.userRight('CRCH.ACCESS_ALL_PERSONS')
      );
    },
  },

  methods: {},
};
</script>

<style scoped></style>
