<template>
  <div>
    <v-btn icon large>
      <v-avatar size="26px">
        <img src="/img/user_image.png" alt="User" @click="userMenuVisble = !userMenuVisble" />
      </v-avatar>
    </v-btn>
    <v-card v-if="userMenuVisble" max-width="400" class="userMenuCard" outlined tile light>
      <v-list-item>
        <v-list-item-avatar class="ma-0 pa-0" color="grey">
          <img src="/img/user_image.png" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="d-flex justify-start align-baseline text-h5 ma-0 pa-0 ml-3 mb-2">
            <span style="font-size: 20px !important; font-weight: 500 !important">{{
              $store.state.user.fullName
            }}</span>
          </v-list-item-title>
          <!-- <v-list-item-subtitle v-if="!this.$vuetify.breakpoint.smAndDown">{{selectedAgencyText.AGENT}} - {{selectedAgencyText.GROUP_NAME}}</v-list-item-subtitle> -->
          <span class="ml-3" style="font-size: 14px">{{ getUserName() }}</span>
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="pa-0 ma-0">
        <gat-select
          v-if="$store.state.user.internal"
          v-model="selectedAgency"
          :items="agencyItems"
          label="Agency"
          size="xxl"
          style="width: 100%"
          class="pa-0 ma-0"
          :disabled="!$store.getters.userRight('G.ACCESS_ALL_AGENCIES')"
          :noSearch="$vuetify.breakpoint.xsOnly" />
      </v-list-item>
      <v-divider></v-divider>

      <v-list-item>
        <v-btn small text class="mb-2 mr-2" color="primary" @click="userMenuVisble = false">close</v-btn>
        <v-btn small text class="mb-2 mr-2" color="primary" @click="showPasswordDlg = true">change password</v-btn>
        <v-btn small class="mb-2 mr-2" color="primary" @click="logout">logout</v-btn>
      </v-list-item>

      <!-- <v-card-text>

            </v-card-text> -->
    </v-card>
    <ChangePassword :showDialog="showPasswordDlg" @close="showPasswordDlg = false" :logOutOnCancel="false" />
  </div>
</template>

<script>
import { apiLogOut } from '@/store/api';

export default {
  name: 'UserMenu',
  props: {
    agencyItems: {
      type: Array,
    },
  },
  components: {
    ChangePassword: () => import('../views/ChangePassword.vue'), // Uses lambda function to import componet to ommit problem with the component used in Auth and UserMenu
  },
  data() {
    return {
      userMenuVisble: false,
      showPasswordDlg: false,
    };
  },

  computed: {
    selectedAgency: {
      get() {
        return this.$store.state.application.agencyIdSelected;
      },
      set(newValue) {
        const agencyId = newValue;
        this.$store.commit('setNewAgency', agencyId);
        window.messageBus.$emit('new-agency-selected', agencyId);
        // window.messageBus.$on('new-agency-selected',(agencyId)=>{
      },
    },
    /* selectedAgencyText(){
                let agency = this.$store.state.agencySettings.find(item=>{
                    return item.ID == this.$store.state.application.agencyIdSelected;
                });
                if(!agency){
                    agency = {}
                }
                return agency;
            } */
  },

  created() {},

  watch: {},

  methods: {
    getUserName() {
      let uname = this.$store.state.user.userNameLong;
      if (!this.$store.state.user.internal) {
        uname = uname.toLowerCase();
      }
      return uname;
    },
    logout() {
      this.userMenuVisble = false;
      apiLogOut();
      // Route to login form
      this.$router.push('/auth');
      this.$router.go();
    },
  },
};
</script>

<style scoped>
.userMenuCard {
  position: absolute;
  top: 35px;
  min-width: 350px;
  right: 0px;
  z-index: 1000 !important;
}
</style>
