<template>
  <div>{{ timeStamp }}</div>
</template>

<script>
export default {
  name: 'CountdownTimer',
  props: {
    startDateTime: {
      type: String,
    },
    endDateTime: {
      type: String,
    },
  },
  components: {},
  data() {
    return {
      timeStamp: null,
      timer: null,
    };
  },
  watch: {
    /* startDateTime(){
              if(this.startDateTime != null){
                  if(this.endDateTime != null){
                      if(this.startDateTime > this.endDateTime){
                          this.timeStamp = "negative";
                      }else {
                          this.updateClock();
                      }
                      } else {
                          this.timer = setInterval(this.updateClock, 1000); // runs method every second.
                      }
              } else {
                  this.timeStamp = null;
              }
          }, */
    endDateTime: {
      immediate: true,
      handler() {
        /* console.log(this.startDateTime);
                  console.log(this.endDateTime); */
        if (this.startDateTime != null) {
          if (this.endDateTime != null) {
            if (this.startDateTime > this.endDateTime) {
              this.timeStamp = null;
            } else {
              this.updateClock();
            }
          } else {
            // eslint-disable-next-line @typescript-eslint/no-implied-eval
            this.timer = setInterval(this.updateClock, 1000); // runs method every second.
          }
        } else {
          this.timeStamp = null;
        }
      },
    },
  },

  created() {},

  activated() {
    if (this.startDateTime != null) {
      if (this.endDateTime != null) {
        if (this.startDateTime > this.endDateTime) {
          this.timeStamp = null;
        } else {
          this.updateClock();
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/no-implied-eval
        this.timer = setInterval(this.updateClock, 1000); // runs method every second.
      }
    }
  },

  deactivated() {
    clearInterval(this.timer);
  },
  destroyed() {
    clearInterval(this.timer);
  },

  methods: {
    updateClock() {
      const today = this.globalFormatDate(Date.now(), 'YYYY-MM-DDTHH:mm:ss');
      let newDate = new Date(today);
      if (this.endDateTime) {
        newDate = new Date(this.endDateTime);
      }
      const newStamp = newDate.getTime();

      const startDateTime = new Date(this.startDateTime);
      const startStamp = startDateTime.getTime();

      let diff = Math.round((newStamp - startStamp) / 1000); // diff = time passed in seconds.
      if (diff < 0) {
        diff = Math.round((startStamp - newStamp) / 1000);
      }
      let d = Math.floor(diff / (24 * 60 * 60));
      diff -= d * 24 * 60 * 60;
      let h = Math.floor(diff / (60 * 60));
      diff -= h * 60 * 60;
      let m = Math.floor(diff / 60);
      diff -= m * 60;
      let s = diff;
      if (this.endDateTime) {
        /* if(s >= 30) {
                      m += 1
                      m = "~" + m;
                  } else {
                      s = "";
                  } */
        if (s != 0) {
          s += 's';
        } else {
          s = '';
        }
        if (m != 0) {
          m += 'min';
        } else {
          m = '';
        }
        if (m == 0) {
          m = s;
        }
        if (h != 0) {
          h += 'h';
        } else {
          h = '';
        }
        if (d != 0) {
          d += 'd';
        } else {
          d = '';
        }

        this.timeStamp = `${d} ${h} ${m}`;
      } else {
        if (s <= 9) {
          s = `0${s}`;
        }
        if (m <= 9) {
          m = `0${m}`;
        }
        if (h <= 9) {
          h = `0${h}`;
        }
        if (d != 0) {
          d += 'd';
        } else {
          d = '';
        }

        this.timeStamp = `${d} ${h}:${m}:${s}`;
      }
    },
  },
};
</script>
