<template>
  <div>
    <gat-grid
      :items="tasks"
      :columns="columns"
      :title="title"
      reloadBtn
      @reload-clicked="fetchData()"
      :loading="isLoading"
      viewGroupName="viewTaskList"
      :height="windowHeight"
      :fixedheader="true"
      :dense="denseMode"
      :selectable="isSelectable"
      keyName="ID"
      :selectedKeys="selectedItemKeys"
      @selectionChanged="showLinkedPeople($event)">
      <template slot="btns">
        <wm-button-group
          class="right mr-1"
          :buttons="wmButtonGroup"
          @createEmailClick="(val) => createEmailClick(val)" />
      </template>
      <template slot="cell" slot-scope="props">
        <div v-if="props.column.field == 'OPERATION'">
          <v-tooltip bottom v-if="props.value == 0">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="green" v-bind="attrs" v-on="on">mdi-airplane-landing</v-icon>
            </template>
            <span>Embarking</span>
          </v-tooltip>
          <v-tooltip bottom v-else-if="props.value == 1">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="red" v-bind="attrs" v-on="on">mdi-airplane-takeoff</v-icon>
            </template>
            <span>Disembarking</span>
          </v-tooltip>
          <v-tooltip bottom v-else-if="props.value == 2">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="blue" v-bind="attrs" v-on="on">mdi-circle-double</v-icon>
            </template>
            <span>Other</span>
          </v-tooltip>
        </div>
        <div v-else-if="props.column.field == 'STATUS'">
          <v-tooltip bottom v-if="props.value == 0">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="blue" v-bind="attrs" v-on="on">mdi-timer-sand-empty</v-icon>
            </template>
            <span>In progress</span>
          </v-tooltip>
          <v-tooltip bottom v-else-if="props.value == 1">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="green" v-bind="attrs" v-on="on">mdi-check-circle</v-icon>
            </template>
            <span>Completed</span>
          </v-tooltip>
          <v-tooltip bottom v-else-if="props.value == 2">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="red" v-bind="attrs" v-on="on">mdi-alert-circle</v-icon>
            </template>
            <span>Cancelled</span>
          </v-tooltip>
          <v-tooltip bottom v-if="props.row.LOCK_FLAG && props.row.LOCK_FLAG === 1">
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="ml-1" color="grey--darken-2" v-bind="attrs" v-on="on" small>mdi-lock</v-icon>
            </template>
            <span>Locked</span>
          </v-tooltip>
        </div>
        <div v-else-if="props.column.field == 'hasRemark'">
          <v-tooltip bottom v-if="props.value == 1">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-message-bulleted</v-icon>
            </template>
            <span>Remark</span>
          </v-tooltip>
        </div>
        <div v-else-if="props.column.field == 'Attachment'">
          <v-tooltip bottom v-if="props.value == 1">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-paperclip</v-icon>
            </template>
            <span>Attachments</span>
          </v-tooltip>
        </div>
        <div v-else-if="props.column.field == 'TransOrAccom'">
          <v-tooltip bottom v-if="props.value == 1">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-bed-empty</v-icon>
            </template>
            <span>Accommodation</span>
          </v-tooltip>
          <v-tooltip bottom v-else-if="props.value == 2">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-bus</v-icon>
            </template>
            <span>Transport</span>
          </v-tooltip>
        </div>
        <div v-else-if="props.column.field == 'CLIENT_NAME'">
          <router-link
            v-if="props.value"
            :to="{ name: 'clientDetails', params: { clientId: props.row.CLIENT_ID } }"
            class="gridLink"
            >{{ props.value }}</router-link
          >
        </div>
      </template>
    </gat-grid>
    <email-generator :portcallId="callId" location="crewchange task" :guid="emailTemplateGuid" />
  </div>
</template>

<script>
import { apiGet, apiPut } from '@/store/api';
import GatUtils from '@/app-components/GatUtils';
import EmailGenerator from '@/app-components/EmailGenerator.vue';

export default {
  name: 'CrewChangeTaskList',
  props: {
    callId: [Number, String],
    fullsize: [Boolean],
    title: [String],
  },
  components: { EmailGenerator },
  data() {
    return {
      isLoading: false,
      selectedItemKeys: [],
      taskByPeople: [],
      taskFilter: [],
      operationFilter: [],
      statusFilter: [],
      denseMode: false,
      upcomingTasks: {},
      emailTemplates: [],
      emailTemplateGuid: undefined,
    };
  },

  activated() {
    this.fetchData();
  },

  mounted() {
    window.messageBus.$on('tasksFilteredByPeople', (taskArray) => {
      this.selectedItemKeys = [];
      this.taskByPeople = GatUtils.makeCopyViaJson(taskArray);
    });

    window.messageBus.$on('crewChangeFilterOperation', (operationChoice) => {
      this.operationFilter = operationChoice;
    });

    window.messageBus.$on('crewChangeFilterStatus', (statusChoice) => {
      this.statusFilter = statusChoice;
    });

    window.messageBus.$on('crewChangeFilterTask', (taskChoice) => {
      this.taskFilter = taskChoice;
    });

    window.messageBus.$on('crewChangeFilterDense', (denseChoise) => {
      this.denseMode = denseChoise;
    });
  },

  created() {
    // this.fetchData();
  },

  watch: {
    // callId: {
    //   handler() {
    //     this.fetchData();
    //   },
    //   immediate: true,
    // },
    '$store.state.user.externalClientId': {
      handler() {
        this.fetchData();
      },
    },
  },

  computed: {
    isTransportPage() {
      return this.$route.path === '/transports';
    },
    isAccommodationPage() {
      return this.$route.path === '/accommodations';
    },
    columns() {
      const result = [];
      result.push({ header: 'Op.', field: 'OPERATION' });
      result.push({ header: 'Ty.', field: 'TransOrAccom' });
      result.push({ header: 'Status', field: 'STATUS' });
      result.push({ header: this.getHeaderForDescription, field: 'TypeBeskr', nowrap: true });
      result.push({ header: '#', field: 'AntConnections' });
      result.push({ header: 'Status', field: 'NAME', hideOnPhone: true, nowrap: true });

      if (this.$store.state.user.internal) {
        result.push({ header: 'Doc.', field: 'Attachment' });
        result.push({ header: 'Rem', field: 'hasRemark' });
      }

      result.push({ header: this.getHeaderForClient, field: 'CLIENT_NAME', nowrap: true });

      if (!this.isAccommodationPage) {
        result.push({ header: this.getHeaderForPlace, field: 'PICKUP_PLACE', nowrap: true });
      }

      result.push({ header: this.getHeaderForStart, field: 'DATETIME_START', dataType: 'datetime' });

      if (!this.isAccommodationPage) {
        result.push({ header: this.getHeaderForDestination, field: 'DESTINATION_PLACE' });
      }

      result.push({ header: this.getHeaderForEnd, field: 'DATETIME_END', dataType: 'datetime' });

      if (this.isAccommodationPage || this.isTransportPage) {
        result.push({ header: 'Portcall', field: 'PORTCALL_ID' });
        result.push({ header: 'Vessel', field: 'Vessel' });
      }

      // expense fields
      if (this.$store.getters.userRight('CRCH.SHOW_EXPENSES')) {
        // eslint-disable-next-line no-multi-assign, @typescript-eslint/no-use-before-define
        let templates;
        if ((this.isTransportPage || this.isAccommodationPage) && this.upcomingTasks?.usedExpenseTemplates) {
          templates = this.upcomingTasks.usedExpenseTemplates;
        } else if (this.$store.state.portcall.crewChange.tasks?.usedExpenseTemplates) {
          templates = this.$store.state.portcall.crewChange.tasks.usedExpenseTemplates;
        }
        // console.log('TEMPLATES::::::', templates);
        // console.log('ISTRANSPORT::::::', this.isTransportPage);
        // console.log('ISACCOMODATION::::::', this.isAccommodationPage);
        // console.log('upcomingTasks::::::', this.upcomingTasks);
        // console.log('usedExpenseTemplates::::::', this.$store.state.portcall.crewChange.tasks.usedExpenseTemplates);
        if (templates) {
          for (let i = 0; i < templates.length; i++) {
            result.push({
              header: `${templates[i].ELT_NAME} Qty`,
              field: `exp_quantity${templates[i].ELT_ID}`,
              footerFunction: { fn: 'sum' },
              dataType: 'number',
            });
            result.push({
              header: 'Price',
              field: `exp_price${templates[i].ELT_ID}`,
              dataType: 'number',
            });
            result.push({
              header: 'Amount',
              field: `exp_amount${templates[i].ELT_ID}`,
              footerFunction: { fn: 'sum' },
              dataType: 'number',
            });
          }
        }
        result.push({ header: 'Exp. total', field: 'totPrice', dataType: 'number', footerFunction: { fn: 'sum' } });
        result.push({ header: 'Debtor', field: 'Debtor', nowrap: true });
      }

      if (this.$store.state.user.internal) {
        result.push({ header: 'Last exported', field: 'LAST_EXPORTED', dataType: 'date' });
        result.push({ header: 'Last updated', field: 'LAST_UPDATED', dataType: 'date' });
      }
      result.push({ header: 'PO No', field: 'PO_NO', nowrap: true });
      return result;
    },

    tasks() {
      let tasks = [];
      if (this.isTransportPage || this.isAccommodationPage) {
        if (this.upcomingTasks.tasks && this.upcomingTasks.tasks.length > 0) {
          tasks = GatUtils.makeCopyViaJson(this.upcomingTasks.tasks);
        }
      } else {
        tasks = this.$store.state.portcall.crewChange.tasks.tasks;
      }
      let newTasks = null;

      if (tasks) {
        newTasks = GatUtils.makeCopyViaJson(tasks);
        newTasks = this.filterItems(newTasks, this.operationFilter, 'OPERATION');
        newTasks = this.filterItems(newTasks, this.statusFilter, 'STATUS');

        if (this.taskFilter[0] && !this.taskFilter[1]) {
          newTasks = newTasks.filter((task) => task.TransOrAccom !== 2);
        } else if (!this.taskFilter[0] && this.taskFilter[1]) {
          newTasks = newTasks.filter((task) => task.TransOrAccom !== 1);
        }

        if (this.taskByPeople.length > 0) {
          newTasks = newTasks.filter((task) => this.taskByPeople.includes(task.ID));
        }

        if (this.isTransportPage) {
          newTasks = newTasks.filter((task) => task.TransOrAccom != 1);
        } else if (this.isAccommodationPage) {
          newTasks = newTasks.filter((task) => task.TransOrAccom != 2);
        }
      }
      return newTasks || tasks;
    },
    windowHeight() {
      let height = 0;
      const defaultHeight = 30;
      const minHeight = 18;

      if (this.fullsize) {
        return 'calc(100vh - 170px)';
      }
      if (this.tasks && this.tasks.length == 1) {
        height = minHeight;
      } else if (this.tasks && this.tasks.length < 4) {
        height = this.tasks.length * 3 + minHeight;
      } else {
        height = defaultHeight;
      }
      window.messageBus.$emit('crewChangeTaskListHeight', height);

      return `${height}vh`;
    },
    agencyId() {
      return this.$store.state.portcall.callDetails.SETUP_ID;
    },
    wmButtonGroup() {
      const result = [];
      if ((!this.$store.state.user.internal && this.userCanGenerateDoc) || this.$store.state.user.internal) {
        result.push({
          btnType: 'CREATE_DOC',
          docType: 24,
          agencyId: this.agencyId,
          location: 'portcall',
          foreignKey: this.callId,
          applicationStatus: this.applicationStatus,
          disabled: !this.userCanGenerateDoc, // this.selectedItemKeys.length === 0 ||
        });
        if (this.$store.state.globalSettings.SGL_WM_EMAIL_PROVIDER !== 0 && this.$store.getters.userRight('EM.EDIT')) {
          result.push({
            btnType: 'CUSTOM',
            caption: 'Create email',
            icon: 'mdi-email',
            menuItems: this.emailTemplateItems,
            disabled: this.emailTemplateItems.length === 0,
          });
        }
      }
      return result;
    },
    userCanGenerateDoc() {
      const isInternal = this.$store.state.user.internal;
      if (isInternal) {
        return true;
      }
      const externalRights = this.$store.getters.userRight('CRCH.GENERATE_DOCUMENT');
      return externalRights;
    },
    isSelectable() {
      if (this.taskByPeople.length > 0) {
        return false;
      }
      return true;
    },
    applicationStatus() {
      return this.$store.getters.getAppStatus;
      /* let result = {};
              result.PortCall = {};
              result.PortCall.Id = this.callId;
              result.PortCall.CcmTaskIds = this.selectedItemKeys
              return result; */
    },

    getHeaderForDescription() {
      if (this.isTransportPage) {
        return 'Transportation type';
      }
      if (this.isAccommodationPage) {
        return 'Room type';
      }
      return 'Description';
    },
    getHeaderForClient() {
      if (this.isTransportPage) {
        return 'Transport company';
      }
      if (this.isAccommodationPage) {
        return 'Hotel';
      }
      return 'Client';
    },
    getHeaderForStart() {
      if (this.isTransportPage) {
        return 'Pickup';
      }
      if (this.isAccommodationPage) {
        return 'Checkin';
      }
      return 'Start';
    },
    getHeaderForEnd() {
      if (this.isTransportPage) {
        return 'Drop-off';
      }
      if (this.isAccommodationPage) {
        return 'Checkout';
      }
      return 'End';
    },
    getHeaderForPlace() {
      if (this.isTransportPage) {
        return 'Pickup/start place';
      }
      return 'Place';
    },
    getHeaderForDestination() {
      if (this.isTransportPage) {
        return 'Destination place';
      }
      return 'Destination';
    },
    clientId() {
      return this.$store.state.user.externalClientId;
    },
    emailTemplateItems() {
      const templates = [...this.emailTemplates];
      let result = [];
      if (Array.isArray(templates) && templates.length > 0) {
        result = templates.map((item) => ({
          caption: item.TITLE,
          eventName: 'createEmailClick',
          icon: 'mdi-file-outline',
          ID: item.ID,
        }));
      }
      return result;
    },
  },

  methods: {
    createEmailClick(item) {
      if (this.callId) {
        const guid = this.GUID();
        const templateId = item.ID;
        const location = 'portcall';
        const foreignKey = this.callId;
        const appStatus = JSON.stringify(this.applicationStatus);
        const param = { guid, templateId, location, foreignKey, appStatus };
        apiPut('document/generate/', param).then(() => {
          this.emailTemplateGuid = guid;
        });
      }
    },
    retrieveTasks(callId) {
      const clientId = this.getClientIdOrDefaults();
      this.isLoading = true;
      this.$store
        .dispatch('getCrewChangeTasks', { callId, clientId })
        .then(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    filterItems(tasks, filterArray, property) {
      const numOfOperationsToggled = filterArray.filter((op) => op === true).length;
      if (numOfOperationsToggled > 0 && numOfOperationsToggled != filterArray.length) {
        const newFilter = filterArray.reduce((result, element, index) => {
          if (element) {
            result.push(index);
          }
          return result;
        }, []);

        // eslint-disable-next-line no-param-reassign
        tasks = tasks.filter((task) => {
          if (newFilter.includes(task[property])) {
            return true;
          }
          return false;
        });
      }
      return tasks;
    },
    showLinkedPeople(taskId) {
      this.selectedItemKeys = taskId;
      this.$store.commit('setAppStatusCcmTaskIds', taskId);
      this.isLoading = true;
      let peopleArray = [];
      if (taskId.length == 0) {
        window.messageBus.$emit('peopleFilteredByTask', peopleArray);
        this.isLoading = false;
      } else {
        const selectedTaskItems = this.getTaskItemBySelectedKeys();
        peopleArray = this.getPeopleFromTasks(selectedTaskItems);

        if (peopleArray.length > 0) {
          window.messageBus.$emit('peopleFilteredByTask', peopleArray);
        } else {
          // Task selected but no assigned people > Clear people table
          window.messageBus.$emit('peopleFilteredByTask', ['x']);
        }
        this.isLoading = false;
      }
    },
    getClientIdOrDefaults() {
      if (this.$store.state.user.internal || this.$store.getters.userRight('CRCH.ACCESS')) {
        return 0;
      }
      return this.$store.state.user.externalClientId ? this.$store.state.user.externalClientId : null;
    },
    fetchData() {
      if (this.callId != 0) {
        if (
          !this.$store.state.portcall.crewChange.tasks.tasks ||
          !this.$store.state.portcall.crewChange.tasks.tasks.length > 0
        ) {
          this.retrieveTasks(this.callId);
        }
        apiGet(`mail/templates/crewchange task/${this.agencyId}`).then((result) => {
          if (result) {
            this.emailTemplates = result;
          }
        });
      } else {
        this.retrieveUpcomingTasks();
      }
    },
    getPeopleFromTasks(taskArray) {
      const people = [];
      for (let i = 0; i < taskArray.length; i++) {
        // eslint-disable-next-line no-restricted-syntax
        for (const prop in taskArray[i][0]) {
          if (prop.indexOf('Person_') === 0) {
            people.push(taskArray[i][0][prop]);
          }
        }
      }
      const uniquePeople = [...new Set(people)];
      return uniquePeople;
    },
    getTaskItemBySelectedKeys() {
      const tasks = [];
      for (let i = 0; i < this.selectedItemKeys.length; i++) {
        tasks.push(
          // eslint-disable-next-line @typescript-eslint/no-loop-func
          this.tasks.filter((obj) => obj.ID === this.selectedItemKeys[i])
        );
      }
      return tasks;
    },
    retrieveUpcomingTasks() {
      if (this.clientId != undefined) {
        this.isLoading = true;
        apiGet(`crewchange/upcomingTasks/${this.clientId}`)
          .then((result) => {
            this.upcomingTasks = GatUtils.makeCopyViaJson(result);
            this.isLoading = false;
          })
          .finally(() => {
            if (this.isAccommodationPage) {
              const firstAccom = this.upcomingTasks.tasks.find((task) => task.TransOrAccom == 1);
              if (firstAccom) {
                this.showLinkedPeople([firstAccom.ID]); // Select first task on Accommodation page
              }
            } else {
              const firstTransp = this.upcomingTasks.tasks.find((task) => task.TransOrAccom == 2);
              if (firstTransp) {
                this.showLinkedPeople([firstTransp.ID]); // Select first task on Transport page
              }
            }
          });
      }
    },
  },
};
</script>

<style scoped></style>
