<template>
  <div class="menu-list">
    <v-list
      dense
      class="sgrey lighten-4"
      nav
      :style="$vuetify.breakpoint.lgAndUp ? 'height: calc(100% - 50px);' : ''"
      style="overflow: auto">
      <drawer-menu-item
        v-for="(item, index) in menuItems"
        :key="index"
        v-show="!item.userRight || $store.getters.userRight(item.userRight)"
        :value="item" />
    </v-list>
    <div class="ml-1" v-show="$vuetify.breakpoint.lgAndUp">
      <div class="poweredBy">Powered by</div>
      <a href="https://www.gatship.com" target="_blank"> <img src="/img/gatship-logo.png" height="28px" /></a>
      <span class="text-caption">build: {{ $store.state.application.version }}</span>
    </div>
  </div>
</template>

<script>
import DrawerMenuItem from './DrawerMenuItem.vue';

export default {
  name: 'DrawerMenuItems',
  props: [],
  components: { DrawerMenuItem },
  data() {
    return {};
  },

  computed: {
    hasDashboardGroups() {
      return (
        this.$store.state.dashboard.main &&
        this.$store.state.dashboard.main.dashboardGroups &&
        this.$store.state.dashboard.main.dashboardGroups.length > 0
      );
    },
    dashboardGroups() {
      let result = [];
      if (this.hasDashboardGroups) {
        result = this.$store.state.dashboard.main.dashboardGroups;
      }
      return result;
    },
    attendanceCodes() {
      return this.$store.state.attendance.attendanceCodes;
    },
    debugMode() {
      return this.$store.state.application.debugMode;
    },
    isCRM() {
      return this.$store.state.application.isCRM;
    },
    menuItems() {
      const m = [];
      const { lastClientsUsed } = this.$store.state.application.clients;
      const { lastCallsUsed } = this.$store.state.portcall;

      if (this.isCRM) {
        // CRM MENU
        if (this.hasDashboardGroups) {
          m.push({
            icon: 'dashboard',
            text: 'Dashboard',
            url: `/dashboard/${this.dashboardGroups[0].NAME.trim().replace(' ', '').toLowerCase()}`,
            toPath: '/dashboard',
          });
        }
        if (this.attendanceCodes.length > 0) {
          const attendanceMenu = {
            icon: 'timer',
            text: 'Attendance',
            url: '/attendance',
            userRight: 'AT.WEB_ACCESS',
            model: false,
          };
          m.push(attendanceMenu);
        }
        m.push({
          icon: 'mdi-truck-delivery',
          text: 'Bestillinger',
          url: '/call/840673163/expenses?gridView=bestillinger',
        });
        const expMenu = {
          icon: 'inbox',
          text: 'Prosjekter',
          model: false,
          children: [
            { text: 'Prospects', url: '/calls', icon: 'mdi-view-list' },
            // { text: 'Gathering',url:'/call/840388645/expenses?gridView=Gathering' },
            { text: 'Moduler', url: '/call/836488078/expenses?gridView=moduler' },
            // { text: 'Konsulent Gatship 2021',url:'/call/876422177/expenses?gridView=Konsulent ufakturert' },
            { text: 'Konsulent Gatship', url: '/call/949826590/expenses?gridView=Konsulent ufakturert' },
            { text: 'Konsulent Politi', url: '/call/949868406/expenses?gridView=Tommy - politi' },
            // { text: 'Konsulent Visma',url:'/call/876469789/expenses?gridView=Visma prosjekt' },
            { text: 'Kurs', url: '/call/949849431/expenses?gridView=kurs' },
            { text: 'Port call refill', url: '/call/949863669/expenses?gridView=Refill' },
            { text: 'Hosting 2023', url: '/call/949807619/expenses?gridView=hosting' },
          ],
        };
        m.push(expMenu);
        if (this.$store.state.user.internal && (this.$store.getters.userRight('C.ACCESS') || this.$store.getters.userRight('C.NEW') || this.$store.getters.userRight('C.EDIT'))){
          const clientsMenu = {
            icon: 'people',
            text: 'Clients',
            url: '/clients',
            model: false,
            userRight: 'Internal-only',
            children: [
              { text: 'Starred', url: '/client/starred', icon: 'mdi-star' },
              { text: 'Search', url: '/clients', icon: 'mdi-magnify' },
              {
                text: 'Follow up',
                url: '/exipred-followups',
                icon: 'mdi-message-text-clock',
                userRight: 'C2.ACCESS_FOLLOW_UP',
              },
              { text: 'Agency locations', url: '/crm/agency-locations', icon: 'mdi-map-marker' },
            ],
          };
          for (let i = 0; lastClientsUsed.length > i; i++) {
            clientsMenu.children.push({
              text: lastClientsUsed[i].NAME,
              url: `/client/${lastClientsUsed[i].ID}/`,
              icon: 'history',
            });
          }
          m.push(clientsMenu);
        }

        m.push({ icon: 'settings', text: 'Settings', url: '/settings', divider: true });
        if (this.debugMode) {
          m.push({ icon: 'help', text: 'Help', routeName: 'Help', toPath: '/help' });
        }
      } else if (this.$store.state.user && this.$store.state.user.internal) {
        if (this.hasDashboardGroups) {
          m.push({
            icon: 'dashboard',
            text: 'Dashboard',
            url: `/dashboard/${this.dashboardGroups[0].NAME.trim().replace(' ', '').toLowerCase()}`,
            toPath: '/dashboard',
          });
        }

        m.push({ icon: 'mdi-calendar', text: 'Activities', url: '/activities' });

        if (this.attendanceCodes.length > 0) {
          const attendanceMenu = {
            icon: 'timer',
            text: 'Attendance',
            url: '/attendance',
            userRight: 'AT.WEB_ACCESS',
            model: false,
          };
          m.push(attendanceMenu);
        }

        const callMenu = {
          icon: 'inbox',
          text: 'Port calls',
          url: '/calls',
          model: false,
          children: [
            { text: 'Whiteboard', url: '/calls', icon: 'mdi-view-list' },
            { text: 'Starred', url: '/starred', icon: 'mdi-star' },
            { text: 'Search', url: '/search', icon: 'mdi-magnify' },
          ],
        };
        // Last used port calls
        for (let i = 0; lastCallsUsed.length > i; i++) {
          const lastCallItem = {
            text: `${lastCallsUsed[i].vessel.VESSEL_PREFIX} ${lastCallsUsed[i].vessel.NAME}`,
            callNo: lastCallsUsed[i].PORTCALL_NUMBER,
            url: `/call/${lastCallsUsed[i].ID}`,
            icon: 'history',
          };
          if (this.$store.state.user.internal) {
            callMenu.children.push(lastCallItem);
          } else {
            // External users should only see calls currently in their whiteboard.
            const whiteBoardItems = this.$store.state.whiteboard.whiteBoardData;
            if (whiteBoardItems) {
              const userHasCall = whiteBoardItems.find((item) => lastCallsUsed[i].ID === item.ID);
              if (userHasCall) {
                callMenu.children.push(lastCallItem);
              }
            }
          }
        }
        /* this.$store.state.portcall.lastCallsUsed.map(item=>{
                      callMenu.children.push({text:item.PORTCALL_NUMBER+' - '+item.vessel.VESSEL_PREFIX+' '+item.vessel.NAME,url:'/call/'+item.ID});
                  }); */
        m.push(callMenu);        
        m.push({ icon: 'mdi-ferry', text: 'Vessels', routeName: 'VesselList', toPath: '/vessels' });
        if (this.$store.state.user.internal && (this.$store.getters.userRight('C.ACCESS') || this.$store.getters.userRight('C.NEW') || this.$store.getters.userRight('C.EDIT'))){
          const clientsMenu = {
            icon: 'people',
            text: 'Clients',
            url: '/clients',
            model: false,
            userRight: 'Internal-only',
            children: [
              { text: 'Search', url: '/clients', icon: 'mdi-magnify' },
              { text: 'Starred', url: '/client/starred', icon: 'mdi-star' },
              {
                text: 'Follow up',
                url: '/exipred-followups',
                icon: 'mdi-message-text-clock',
                userRight: 'C2.ACCESS_FOLLOW_UP',
              },
            ],
          };
          for (let i = 0; lastClientsUsed.length > i; i++) {
            clientsMenu.children.push({
              text: lastClientsUsed[i].NAME,
              url: `/client/${lastClientsUsed[i].ID}/`,
              icon: 'history',
            });
          }
          m.push(clientsMenu);
        }

        m.push({ icon: 'settings', text: 'Settings', url: '/settings', divider: true });
        if (this.debugMode) {
          m.push({ icon: 'help', text: 'Help', routeName: 'Help', toPath: '/help' });
        }
      }
      // External users
      else if (this.$store.state.user && !this.$store.state.user.internal) {
        /* if(this.$store.state.dashboard.userDefReports.length > 0){ */
        if (this.hasDashboardGroups) {
          m.push({
            icon: 'dashboard',
            text: 'Dashboard',
            url: `/dashboard/${this.dashboardGroups[0].NAME.trim().replace(' ', '').toLowerCase()}`,
            toPath: '/dashboard',
          });
        }
        /* } */
        if (this.attendanceCodes.length > 0) {
          const attendanceMenu = {
            icon: 'timer',
            text: 'Attendance',
            url: '/attendance',
            userRight: 'AT.WEB_ACCESS',
            model: false,
          };
          m.push(attendanceMenu);
        }

        // let callMenu = { icon: 'inbox', text: 'Port visits',url:'/calls', model: false, children: [
        //             { text: 'Active calls',url:'/calls' },
        //             // { text: 'Favorites',url:'/favorites'},
        //         ] };
        // this.$store.state.portcall.lastCallsUsed.map(item=>{
        //     callMenu.children.push({text:item.PORTCALL_NUMBER+' - '+item.vessel.VESSEL_PREFIX+' '+item.vessel.NAME,url:'/call/'+item.ID});
        // });
        // m.push( callMenu);
        // m.push({ icon: 'mdi-ferry', text: 'Vessels',url:'/vessels' });
        const externalCallMenu = {
          icon: 'inbox',
          text: 'Port calls',
          url: '/calls',
          model: false,
          children: [
            { text: 'Whiteboard', url: '/calls', icon: 'mdi-view-list' },
            { text: 'Starred', url: '/starred', icon: 'mdi-star' },
            { text: 'Search', url: '/search', icon: 'mdi-magnify' },
          ],
        };
        for (let i = 0; lastCallsUsed.length > i; i++) {
          externalCallMenu.children.push({
            callNo: lastCallsUsed[i].PORTCALL_NUMBER,
            text: `${lastCallsUsed[i].vessel.VESSEL_PREFIX} ${lastCallsUsed[i].vessel.NAME}`,
            url: `/call/${lastCallsUsed[i].ID}`,
            icon: 'history',
          });
        }
        m.push(externalCallMenu);
        m.push({ icon: 'people', text: 'Your company', url: `/client/${this.$store.state.user.externalClientId}` });
        if (this.debugMode) {
          m.push({ icon: 'help', text: 'Help', routeName: 'Help', toPath: '/help' });
        }
        m.push({ icon: 'mdi-boom-gate-up-outline', text: 'Visitors', url: '/visitors', userRight: 'VI.GATEKEEPER' });
        if (this.$store.state.user.upcomingTasks.transport) {
          const externalClientTransportMenu = {
            icon: 'mdi-bus',
            text: 'Transports',
            url: '/transports',
          };
          m.push(externalClientTransportMenu);
        }
        if (this.$store.state.user.upcomingTasks.accommodation) {
          const externalClientAcccommodationMenu = {
            icon: 'mdi-bed-empty',
            text: 'Accommodations',
            url: '/accommodations',
          };
          m.push(externalClientAcccommodationMenu);
        }

        if (this.$store.state.user.upcomingTasks.service) {
          const externalClientServiceMenu = {
            icon: 'mdi-handshake',
            text: 'Services',
            url: '/services',
          };
          m.push(externalClientServiceMenu);
        }
        m.push({ icon: 'settings', text: 'Settings', url: '/settings', divider: true });
      }
      if (this.debugMode) {
        m.push({ icon: 'bug_report', text: 'Application state', routeName: 'AppState', toPath: '/AppState' });
        m.push({ icon: 'mdi-calendar-clock', text: 'Event log', routeName: 'EventLog', toPath: '/EventLog' });
      }
      return m;
    },
  },

  created() {},

  watch: {},

  methods: {},
};
</script>

<style scoped>
.poweredBy {
  font-size: 10px;
  color: gray;
}

.menu-list {
  height: inherit;
}
</style>
