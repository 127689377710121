<template>
  <div class="mb-12">
    <gat-form
      v-model="isValid"
      ref="form"
      :readonly="!$store.getters.userRight('CA.NEW_AND_EDIT')"
      :isLoading="isLoading">
      <gat-group title="General" :collapsed="isNew ? !expandedIfNew : false">
        <template slot="compact">
          <v-layout wrap>
            <gat-compact-field size="s" label="Action type" :value="actionDescription" />
            <gat-compact-field
              size="s"
              label="Cargo type"
              :value="lookupCode(cargoItem.CARGO_GROUP_ID, $store.state.helper.cargoTypes, 'ID', 'NAME')" />
            <gat-compact-field
              size="s"
              label="Unit of measure"
              :value="lookupCode(cargoItem.UNIT_ID, $store.state.helper.unitTypes, 'ID', 'CODE')" />
            <gat-compact-field
              size="s"
              label="Packages type"
              :value="lookupCode(cargoItem.PACKAGE_TYPE_ID, $store.state.helper.packageTypes, 'ID', 'NAME')" />
            <gat-compact-field size="s" label="Sort order" :value="cargoItem.SORT_ORDER" />
          </v-layout>
        </template>
        <v-layout wrap>
          <gat-select
            :value="actionDescription"
            label="Action type"
            :items="$store.state.helper.cargoActions"
            textFieldName="value"
            codeFieldName="value"
            size="s"
            :required="true"
            @item-selected="actionTypeChanged" />
          <gat-select
            v-model="cargoItem.CARGO_GROUP_ID"
            label="Cargo type"
            :items="cargoTyper"
            textFieldName="NAME"
            @item-selected="cargoTypeChanged"
            codeFieldName="ID"
            :required="true" />

          <gat-select
            v-model="cargoItem.UNIT_ID"
            label="Unit of measure"
            :items="$store.state.helper.unitTypes"
            textFieldName="CODE"
            @item-selected="selectedUnit = $event"
            codeFieldName="ID"
            size="s"
            :required="true" />
          <gat-select
            v-model="cargoItem.PACKAGE_TYPE_ID"
            label="Packages type"
            :items="$store.state.helper.packageTypes"
            textFieldName="NAME"
            @item-selected="selectedPackage = $event"
            codeFieldName="ID"
            size="s"
            :required="true" />
          <gat-edit v-model="cargoItem.SORT_ORDER" label="Sort order" size="s" dataType="number" />
        </v-layout>
      </gat-group>

      <gat-group title="Package and unit" v-if="showUnitInfo" :collapsed="isNew ? !expandedIfNew : false">
        <template slot="compact">
          <v-layout wrap>
            <gat-compact-field size="s" label="No of units" :value="cargoItem.NO_OF_PACKS" />
            <gat-compact-field
              size="s"
              label="Packing weight"
              :value="cargoItem.PACKING_WEIGHT"
              :appendText="selectedUnitType.CODE" />
            <gat-compact-field
              size="s"
              label="Net unit weight"
              :value="cargoItem.NET_WEIGHT_PACKS"
              :appendText="selectedUnitType.CODE" />
            <gat-compact-field
              size="s"
              label="Gross unit weight"
              :value="unitGrossWeight"
              :appendText="selectedUnitType.CODE" />
            <gat-compact-field
              size="s"
              label="Size of packs"
              :value="cargoItem.SIZE_OF_PACKS"
              Goods.SIZE_OF_PACKS
              :maxChars="getColumnLength('Goods.SIZE_OF_PACKS')" />
          </v-layout>
        </template>
        <v-layout wrap>
          <gat-edit v-model="cargoItem.NO_OF_PACKS" label="No of units" size="s" dataType="number" />
          <gat-edit
            v-model="cargoItem.PACKING_WEIGHT"
            label="Packing weight"
            :decimals="selectedUnitType.DECIMALS"
            size="s"
            dataType="number" />
          <gat-edit
            v-model="cargoItem.NET_WEIGHT_PACKS"
            label="Net unit weight"
            :decimals="selectedUnitType.DECIMALS"
            size="s"
            dataType="number" />
          <gat-edit
            :disabled="true"
            :value="unitGrossWeight"
            label="Gross unit weight"
            :decimals="selectedUnitType.DECIMALS"
            size="s"
            dataType="number" />
          <gat-edit
            v-model="cargoItem.SIZE_OF_PACKS"
            label="Size of packs"
            size="s"
            :maxChars="getColumnLength('Goods.SIZE_OF_PACKS')" />
        </v-layout>
      </gat-group>
      <gat-group title="Weight and measurement" :collapsed="isNew ? !expandedIfNew : false">
        <template slot="compact">
          <v-layout wrap>
            <gat-compact-field
              size="s"
              label="Net weight"
              :value="cargoItem.AMOUNT_WEIGHT"
              :appendText="selectedUnitType.CODE" />
            <gat-compact-field
              size="s"
              label="Total pack. weight"
              :value="cargoItem.TOTAL_PACK_WEIGHT"
              :appendText="selectedUnitType.CODE" />
            <gat-compact-field
              size="s"
              label="Gross weight"
              :value="totalGrossWeight"
              :appendText="selectedUnitType.CODE" />

            <gat-compact-field
              size="s"
              label="Measurement"
              :value="cargoItem.AMOUNT_MEASUREMENT"
              :appendText="selectedUnitType.CODE" />
            <gat-compact-field
              size="s"
              label="Outturn weight"
              :value="cargoItem.OUTTURN_WEIGHT"
              :appendText="selectedUnitType.CODE" />
          </v-layout>
        </template>
        <v-layout wrap>
          <gat-edit
            :disabled="showUnitInfo"
            v-if="selectedUnitType.UNITTYPE != 1"
            v-model="cargoItem.AMOUNT_WEIGHT"
            :decimals="selectedUnitType.DECIMALS"
            label="Net weight"
            size="s"
            dataType="number"
            id="amount_vekt" />
          <gat-edit
            :disabled="showUnitInfo"
            v-if="selectedUnitType.UNITTYPE != 1"
            v-model="cargoItem.TOTAL_PACK_WEIGHT"
            :decimals="selectedUnitType.DECIMALS"
            label="Total pack. weight"
            size="s"
            dataType="number" />
          <gat-edit
            :disabled="true"
            v-if="selectedUnitType.UNITTYPE != 1"
            v-model="cargoItem.AMOUNT_GROSS_WEIGHT"
            :decimals="selectedUnitType.DECIMALS"
            label="Gross weight"
            size="s"
            dataType="number" />

          <gat-edit
            v-if="selectedUnitType.UNITTYPE != 0"
            v-model="cargoItem.AMOUNT_MEASUREMENT"
            :decimals="selectedUnitType.DECIMALS"
            label="Measurement"
            size="s"
            dataType="number" />
          <gat-edit
            :disabled="showUnitInfo"
            v-model="cargoItem.OUTTURN_WEIGHT"
            :decimals="selectedUnitType.DECIMALS"
            label="Outturn weight"
            size="s"
            dataType="number" />
        </v-layout>
      </gat-group>

      <gat-group title="Details" :collapsed="isNew ? !expandedIfNew : false">
        <template slot="compact">
          <v-layout wrap>
            <gat-compact-field
              size="s"
              label="UN cargo no"
              :value="cargoItem.UN_NUMBER"
              :maxChars="getColumnLength('Goods.UN_NUMBER')" />
            <gat-compact-field
              size="s"
              label="IMO hazard no"
              :value="cargoItem.IMO_NUMBER"
              :maxChars="getColumnLength('Goods.IMO_NUMBER')" />
            <gat-compact-field size="s" label="Ref no" :value="cargoItem.REF_NO" :disabled="isNew" />
            <gat-compact-field
              size="s"
              label="Other info"
              :value="cargoItem.OTHER_INFO"
              :maxChars="getColumnLength('Goods.OTHER_INFO')" />
            <gat-compact-field
              size="s"
              label="SOF"
              :value="lookupCode(cargoItem.SOF_ID, $store.state.portcall.sofTitles, 'ID', 'TITLE')" />
            <gat-compact-field size="m" label="Marks and no" :value="cargoItem.MARKS_NUMBER" />
            <gat-compact-field size="l" label="Description (BsL text)" :value="cargoItem.BL_TEXT" />
          </v-layout>
        </template>
        <v-layout wrap>
          <gat-edit
            v-model="cargoItem.UN_NUMBER"
            label="UN cargo no"
            size="s"
            :maxChars="getColumnLength('Goods.UN_NUMBER')" />
          <gat-edit
            v-model="cargoItem.IMO_NUMBER"
            label="IMO hazard no"
            size="m"
            :maxChars="getColumnLength('Goods.IMO_NUMBER')" />
          <gat-edit v-model="cargoItem.REF_NO" label="Ref no" size="s" :disabled="isNew" dataType="number" />

          <gat-select
            v-model="cargoItem.SOF_ID"
            label="Statement of facts"
            size="s"
            :items="$store.state.portcall.sofTitles"
            codeFieldName="ID"
            textFieldName="TITLE" />
          <gat-edit
            v-model="cargoItem.OTHER_INFO"
            label="Other info"
            size="m"
            :maxChars="getColumnLength('Goods.OTHER_INFO')" />
        </v-layout>
        <v-layout wrap>
          <gat-edit
            v-model="cargoItem.MARKS_NUMBER"
            label="Marks and no"
            :rows="3"
            size="l"
            :maxChars="getColumnLength('Goods.MARKS_NUMBER')" />
          <gat-edit
            v-model="cargoItem.BL_TEXT"
            label="Description (BsL text)"
            :rows="3"
            size="l"
            :maxChars="getColumnLength('Goods.BL_TEXT')" />
        </v-layout>
      </gat-group>

      <gat-group title="User-defined fields" :collapsed="isNew ? !expandedIfNew : false">
        <template slot="compact">
          <v-layout wrap>
            <gat-compact-field
              size="s"
              :label="$store.getters.agencySettings(agencyId).CARGO_USER_TEXT_1"
              :value="cargoItem.USER_1"
              :maxChars="getColumnLength('Goods.USER_1')" />
            <gat-compact-field
              size="s"
              :label="$store.getters.agencySettings(agencyId).CARGO_USER_TEXT_2"
              :value="cargoItem.USER_2"
              :maxChars="getColumnLength('Goods.USER_2')" />
            <gat-compact-field
              size="s"
              :label="$store.getters.agencySettings(agencyId).CARGO_USER_TEXT_3"
              :value="cargoItem.USER_3"
              :maxChars="getColumnLength('Goods.USER_3')" />
            <gat-compact-field
              size="s"
              :label="$store.getters.agencySettings(agencyId).CARGO_USER_TEXT_4"
              :value="cargoItem.USER_4"
              :maxChars="getColumnLength('Goods.USER_4')" />
          </v-layout>
          <gat-dyna-fields
            v-model="cargoItem.dynaValues"
            componentName="pDynaFields.CargoDyna"
            container="pDynaFields"
            :compact="true"></gat-dyna-fields>
        </template>
        <v-layout wrap>
          <gat-edit
            v-model="cargoItem.USER_1"
            v-if="$store.getters.agencySettings(agencyId).CARGO_USER_TEXT_1"
            :label="$store.getters.agencySettings(agencyId).CARGO_USER_TEXT_1"
            size="m"
            :maxChars="getColumnLength('Goods.USER_1')" />
          <gat-edit
            v-model="cargoItem.USER_2"
            v-if="$store.getters.agencySettings(agencyId).CARGO_USER_TEXT_2"
            :label="$store.getters.agencySettings(agencyId).CARGO_USER_TEXT_2"
            size="m"
            :maxChars="getColumnLength('Goods.USER_2')" />
          <gat-edit
            v-model="cargoItem.USER_3"
            v-if="$store.getters.agencySettings(agencyId).CARGO_USER_TEXT_3"
            :label="$store.getters.agencySettings(agencyId).CARGO_USER_TEXT_3"
            size="m"
            :maxChars="getColumnLength('Goods.USER_3')" />
          <gat-edit
            v-model="cargoItem.USER_4"
            v-if="$store.getters.agencySettings(agencyId).CARGO_USER_TEXT_4"
            :label="$store.getters.agencySettings(agencyId).CARGO_USER_TEXT_4"
            size="m"
            :maxChars="getColumnLength('Goods.USER_4')" />
        </v-layout>
        <gat-dyna-fields
          v-model="cargoItem.dynaValues"
          componentName="pDynaFields.CargoDyna"
          container="pDynaFields"></gat-dyna-fields>
      </gat-group>

      <gat-group title="Orgin and destination" :collapsed="!isNew">
        <template slot="compact">
          <v-layout wrap>
            <!-- <gat-compact-field size="s" :label="portTitle" :value="selectedPort.NAME"  /> -->
            <gat-compact-field
              size="s"
              :label="quayTitle"
              :value="lookupCode(cargoItem.DOCK_ID, $store.state.portcall.berths, 'ID', 'NAME')" />
            <gat-compact-field size="s" label="Consignee" :value="consignee.NAME" />
          </v-layout>
        </template>
        <v-layout wrap>
          <gat-harbour-select
            v-model="cargoItem.PortId"
            v-if="cargoItem.ACTION == 1"
            :label="portTitle"
            size="m"
            @item-selected="selectedPort = $event"></gat-harbour-select>
          <gat-select
            v-model="cargoItem.DOCK_ID"
            :label="quayTitle"
            :items="
              $store.state.portcall.berths.filter((item) => {
                return item.BA_TYPE == 'Quay';
              })
            "
            codeFieldName="ID"
            textFieldName="NAME"
            :required="true"></gat-select>
          <gat-harbour-select
            v-model="cargoItem.PortId"
            v-if="cargoItem.ACTION != 1"
            :label="portTitle"
            size="m"
            @item-selected="selectedPort = $event"></gat-harbour-select>
          <gat-client-select
            label="Consignee"
            v-model="cargoItem.CONSIGNEE_ID"
            @item-selected="consignee = $event"
            customItem
            showClientWarning
            showClientCreditLimit />
        </v-layout>
      </gat-group>

      <gat-bottom-panel
        :orgValue="orgCargoItem"
        :newValue="cargoItem"
        :itemDescription="'cargo item '"
        :saveBtn="true"
        :deleteBtn="true"
        :buttonsEnabled="!saving"
        :deleteDisabled="!cargoItem.ID || !$store.getters.userRight('CA.DELETE')"
        :saveDisabled="!$store.getters.userRight('CA.NEW_AND_EDIT')"
        @saveClicked="saveItem"
        @deleteClicked="deleteItem"></gat-bottom-panel>
    </gat-form>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { apiGet, apiPut, apiDelete } from '@/store/api';
import GatHarbourSelect from '@/app-components/GatHarbourSelect.vue';
import GatClientSelect from '@/app-components/GatClientSelect.vue';
import GatDynaFields from '@/app-components/dynafields/GatDynaFields.vue';

export default {
  name: 'CargoItem',
  props: ['cargoId', 'callId', 'isNew'],
  components: { GatHarbourSelect, GatClientSelect, GatDynaFields },
  data() {
    return {
      expandedIfNew: false, // is set in activated/deactivated based on isNew prop.
      cargoItem: {},
      isValid: null,
      orgCargoItem: {},
      saving: false,
      consignee: {},
      selectedPort: {},
      selectedPackage: {},
      selectedUnit: {},
      isLoading: true,
    };
  },
  created() {
    // this.cargoItem.AMOUNT_WEIGHT = 0;
    // this.cargoItem.TOTAL_PACK_WEIGHT = 0;
  },

  activated() {
    this.$store.commit('setBackNavigation', [
      { text: 'port call', href: `/call/${this.callId}` },
      { text: 'cargo list', href: `/call/${this.callId}/cargo-list` },
    ]);
    this.expandedIfNew = this.isNew;
  },

  deactivated() {
    this.$store.commit('setBackNavigation', undefined);
    this.expandedIfNew = !this.isNew;
  },

  computed: {
    numberOfUnits() {
      return this.cargoItem.NO_OF_PACKS;
    },
    unitNetWeight() {
      return this.showUnitInfo ? this.cargoItem.NET_WEIGHT_PACKS : this.cargoItem.AMOUNT_WEIGHT;
    },
    unitPackingWeight() {
      return this.showUnitInfo ? this.cargoItem.PACKING_WEIGHT : this.cargoItem.TOTAL_PACK_WEIGHT;
    },
    unitGrossWeight() {
      let result = 0;
      const { unitNetWeight } = this;
      const { unitPackingWeight } = this;
      if (unitNetWeight) {
        result += unitNetWeight;
      }
      if (unitPackingWeight) {
        result += unitPackingWeight;
      }
      return result;
    },
    totalPackWeight() {
      let result = 0;
      const { numberOfUnits } = this;
      const { unitPackingWeight } = this;
      if (this.showUnitInfo) {
        result = numberOfUnits ? unitPackingWeight * numberOfUnits : unitPackingWeight;
      } else {
        result = unitPackingWeight;
      }
      return result;
    },
    totalNetWeight() {
      let result = 0;
      const { numberOfUnits } = this;
      const netUnitWeight = this.unitNetWeight;
      if (this.showUnitInfo) {
        if (numberOfUnits && netUnitWeight) {
          result = netUnitWeight * numberOfUnits;
        }
      } else {
        result = netUnitWeight;
      }
      return result;
    },
    totalGrossWeight() {
      let result = 0;
      const { numberOfUnits } = this;
      const { unitNetWeight } = this;
      // let unitGrossWeight = this.unitGrossWeight || 0;
      const { totalPackWeight } = this;
      // CHECK CARGO TYPE
      if (this.showUnitInfo) {
        result = unitNetWeight * numberOfUnits + totalPackWeight;
      } else {
        result = unitNetWeight + totalPackWeight;
      }
      return result;
    },
    actionDescription() {
      // eslint-disable-next-line default-case
      switch (this.cargoItem.ACTION) {
        case 0:
          return 'Loading';
        case 1:
          return 'Discharging';
        case 2:
          return this.cargoItem.OTHER_TEXT;
      }
      return '';
    },

    agencyId() {
      return this.$store.state.portcall.callDetails.SETUP_ID;
    },
    packs() {
      return this.cargoItem.NO_OF_PACKS;
    },
    portTitle() {
      let result = 'Port';
      if (this.cargoItem.ACTION == 0) {
        result = 'Discharging port';
      } else if (this.cargoItem.ACTION == 1) {
        result = 'Loading port';
      }
      return result;
    },
    quayTitle() {
      let result = 'Quay';
      if (this.cargoItem.ACTION == 0 || this.cargoItem.ACTION == 1) {
        result = `${this.actionDescription} quay`;
      }
      return result;
    },
    selectedPackageType() {
      // let result = this.$store.state.helper.packageTypes.find(item=>{
      //         return item.ID == this.cargoItem.PACKAGE_TYPE_ID;
      //     });

      const result = this.selectedPackage;
      if (!result) {
        return {};
      }
      return result;
    },
    selectedUnitType() {
      // let result = this.$store.state.helper.unitTypes.find(item=>{
      //     return item.ID == this.cargoItem.UNIT_ID;
      // });

      const result = this.selectedUnit;
      if (!result) {
        return {};
      }
      return result;
    },
    showUnitInfo() {
      return this.selectedPackageType.WEIGHT_TYPE == 0 || this.selectedPackageType.WEIGHT_TYPE == 1;
    },

    cargoTyper: {
      get() {
        return this.$store.state.helper.cargoTypes;
      },
    },
    TotPackWeight: {
      get() {
        return this.cargoTypenn.GROSS_WEIGHT - this.cargoTypenn.NET_WEIGHT;
      },
    },
  },

  watch: {
    unitGrossWeight(newVal) {
      if (newVal && !this.saving && !this.isLoading) {
        if (this.showUnitInfo) {
          this.cargoItem.GROSS_WEIGHT_PACKS = newVal;
        }
      }
    },
    totalNetWeight(newVal) {
      if (newVal && !this.saving && !this.isLoading) {
        this.cargoItem.AMOUNT_WEIGHT = newVal;
      }
    },
    totalGrossWeight(newVal) {
      if (newVal && !this.saving && !this.isLoading) {
        if (this.showUnitInfo) {
          this.cargoItem.AMOUNT_GROSS_WEIGHT = newVal;
        } else {
          this.cargoItem.AMOUNT_GROSS_WEIGHT = newVal;
        }
      }
    },
    totalPackWeight(newVal) {
      if (newVal && !this.saving && !this.isLoading) {
        this.cargoItem.TOTAL_PACK_WEIGHT = newVal;
      }
    },
    cargoId: {
      handler(newCargoId) {
        if (this.isNew) {
          const cargoItem = {};
          this.selectedPort = {};
          this.consignee = {};
          // Set default values
          cargoItem.DOCK_ID = this.$store.state.portcall.berths.filter((item) => item.BA_TYPE == 'Quay')[0].ID;
          cargoItem.GROSS_WEIGHT_PACKS = 0;
          cargoItem.AMOUNT_WEIGHT = 0;
          cargoItem.AMOUNT_GROSS_WEIGHT = 0;
          cargoItem.TOTAL_PACK_WEIGHT = 0;
          this.cargoItem = { ...cargoItem };
          this.orgCargoItem = this.duplicateViaJson(this.cargoItem);
          this.isLoading = false;
        } else {
          // set isLoading to true to show progressbar if response is not immidiate
          this.isLoading = true;
          apiGet(`/cargo/${newCargoId}`).then(
            (result) => {
              // remove progressbar
              this.cargoItem = result;
              this.orgCargoItem = this.duplicateViaJson(this.cargoItem);
              this.isLoading = false;
            },
            () => {
              this.isLoading = false;
            }
          );
        }
      },
      immediate: true,
    },

    // this.calculateTotalWeights();
    // },

    // packs(){
    // this.calculateTotalWeights();
    // },
  },

  methods: {
    actionTypeChanged(actionType) {
      this.cargoItem.ACTION = actionType.type;
      if (this.cargoItem.ACTION == 2) {
        this.cargoItem.OTHER_TEXT = actionType.value;
      }
    },

    cargoTypeChanged(cargoType) {
      if (this.isNew) {
        this.$set(this.cargoItem, 'UNIT_ID', cargoType.UNIT_ID);
        this.$set(this.cargoItem, 'PACKAGE_TYPE_ID', cargoType.PACKAGE_TYPE_ID);
        this.$set(this.cargoItem, 'BL_TEXT', cargoType.CARGO_DESCRIPTION);
        this.$set(this.cargoItem, 'PACKING_WEIGHT', cargoType.GROSS_WEIGHT_OF_PACK - cargoType.NET_WEIGHT_OF_PACK);
        this.$set(this.cargoItem, 'NET_WEIGHT_PACKS', cargoType.NET_WEIGHT_OF_PACK);
        this.$set(this.cargoItem, 'SIZE_OF_PACKS', cargoType.SIZE_OF_PACK);
        this.$set(this.cargoItem, 'AMOUNT_WEIGHT', cargoType.NET_WEIGHT);
        this.$set(this.cargoItem, 'TOTAL_PACK_WEIGHT', cargoType.GROSS_WEIGHT - cargoType.NET_WEIGHT);
        this.$set(this.cargoItem, 'OTHER_INFO', cargoType.OTHER_INFO);
        this.$set(this.cargoItem, 'UN_NUMBER', cargoType.UN_NUMBER);
        this.$set(this.cargoItem, 'IMO_NUMBER', cargoType.IMO_NUMBER);
        this.$set(this.cargoItem, 'USER_1', cargoType.USER_1);
        this.$set(this.cargoItem, 'USER_2', cargoType.USER_2);
        this.$set(this.cargoItem, 'USER_3', cargoType.USER_3);
        this.$set(this.cargoItem, 'USER_4', cargoType.USER_4);
      }
    },

    deleteItem() {
      // set isLoading to true to show progressbar if response is not immidiate
      this.isLoading = true;
      apiDelete(`/cargo/${this.cargoItem.ID}`).then((result) => {
        // remove progressbar
        this.isLoading = false;
        if (result.resultCategory == 1) {
          const id = this.cargoItem.ID;
          this.cargoItem = {};
          this.orgCargoItem = {};
          // delayed for å ungå at dialog om data endret vises (changed property må rekke å settes)
          this.$nextTick(() => {
            this.$store.commit('removeCargoItem', id);
            this.$router.push(`/call/${this.callId}/cargo-list`); // go back
          });
        }
      });
    },

    saveItem() {
      this.$refs.form.validate();
      if (this.isValid) {
        this.saving = true;
        let cargoItem = { ...this.cargoItem };
        if (!this.showUnitInfo && cargoItem.NO_OF_PACKS) {
          cargoItem.NO_OF_PACKS = null;
        }
        /* cargoItem.GROSS_WEIGHT_PACKS = this.cargoItem.NET_WEIGHT_PACKS + this.cargoItem.PACKING_WEIGHT;
                  cargoItem.NET_WEIGHT = this.cargoItem.NO_OF_PACKS * this.cargoItem.NET_WEIGHT_PACKS;
                  cargoItem.AMOUNT_GROSS_WEIGHT = (this.cargoItem.AMOUNT_WEIGHT + this.cargoItem.TOTAL_PACK_WEIGHT); */
        // set isLoading to true to show progressbar if response is not immidiate
        this.isLoading = true;
        // api post...
        apiPut('/cargo', {
          value: cargoItem,
          orgValue: this.orgCargoItem,
          dynaValues: this.cargoItem.dynaValues,
          orgDynaValues: this.orgCargoItem.dynaValues,
        }).then((result) => {
          // remove progressbar
          this.isLoading = false;
          cargoItem = result.payload;
          if (result.resultCategory == 1) {
            if (this.isNew) {
              this.cargoItem = {};
              this.orgCargoItem = {};
            } else {
              this.cargoItem = cargoItem;
              this.orgCargoItem = this.duplicateViaJson(this.cargoItem);
            }

            // delayed for å ungå at dialog om data endret vises (changed property må rekke å settes)
            this.$nextTick(() => {
              this.$store.commit('addCargoItem', cargoItem);
              // this.$router.push('/call/'+this.callId+'/cargo-list'); //go back
              this.$store.dispatch('getCargoItems', this.callId);
              this.$router.push({ name: 'cargoList', params: { callId: this.callId } });
              this.saving = false;
            });
          } else {
            this.saving = false;
          }
        });
      }
    },
  },
};
</script>

<style scoped></style>
