/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/naming-convention */
const vuex_appStatus = {
  state: {
    PortCall: undefined,
    VesselId: undefined,
    SetupID: undefined,
    selectedSofId: undefined,
    ServiceId: undefined,
    selectedProspectId: undefined,
    expense: {
      selectedIds: [],
      selectedClientId: undefined,
    },
    ServiceSelectedIds: [],
    VisitorIds: [],
    AttendanceSelectedIds: [],
    CcmPersonIds: [],
    CcmTaskIds: [],
    SelectedCargoIds: [],
  },
  mutations: {
    setPortcallApplicationStatus(state, payload) {
      const portcall = {};
      if (payload.ID) {
        portcall.Id = payload.ID;
      }
      state.PortCall = portcall;
    },

    setAppStatusSetupId(state, payload) {
      if (payload.SETUP_ID) {
        state.SetupID = payload.SETUP_ID;
      }
    },

    setAppStatusVesselId(state, payload) {
      if (payload.VESSEL_ID) {
        state.VesselId = payload.VESSEL_ID;
      }
    },

    setAppStatusSelectedSofId(state, payload) {
      state.selectedSofId = payload;
    },

    setAppStatusSelectedProspectId(state, payload){
      state.selectedProspectId = payload;
    },

    setAppStatusExpenseClientId(state, payload) {
      state.expense.selectedClientId = payload;
    },

    setAppStatusExpenseSelectedIds(state, payload) {
      state.expense.selectedIds = payload;
    },

    setAppStatusServiceSelectedIds(state, payload) {
      state.ServiceSelectedIds = payload;
    },

    setAppStatusServiceId(state, payload) {
      state.ServiceId = payload;
    },

    setAppStatusVisitorSelectedIds(state, payload) {
      state.VisitorIds = payload;
    },

    setAppStatusAttendanceSelectedIds(state, payload) {
      state.AttendanceSelectedIds = payload;
    },
    setAppStatusCcmPersonIds(state, payload) {
      state.CcmPersonIds = payload;
    },
    setAppStatusCcmTaskIds(state, payload) {
      state.CcmTaskIds = payload;
    },
    setAppStatusSelectedCargoIds(state, payload) {
      state.SelectedCargoIds = payload;
    },
  },
  actions: {
    createApplicationStatusWhenPortcallIsLoaded({ commit, dispatch }, payload) {
      // console.log("createApplicationStatusWhenPortcallIsLoaded:", payload);
      commit('setPortcallApplicationStatus', payload);
      commit('setAppStatusSetupId', payload);
      commit('setAppStatusVesselId', payload);
      dispatch('resetAppStatusSelection');
    },
    resetAppStatusSelection({ commit }) {
      commit('setAppStatusSelectedSofId', undefined);
      commit('setAppStatusSelectedProspectId', undefined);
      commit('setAppStatusExpenseClientId', undefined);
      commit('setAppStatusExpenseSelectedIds', []);
      commit('setAppStatusServiceSelectedIds', []);
      commit('setAppStatusVisitorSelectedIds', []);
      commit('setAppStatusAttendanceSelectedIds', []);
      commit('setAppStatusCcmPersonIds', []);
      commit('setAppStatusSelectedCargoIds', []);
    },
  },
  getters: {
    getAppStatus(state, getters) {
      const appStatus = {};
      if (state.PortCall) {
        appStatus.PortCall = { ...state.PortCall };
        // SofId
        if (state.selectedSofId) {
          appStatus.PortCall.SofId = state.selectedSofId;
        } else {
          const sofId = getters.getFirstSofTitleId;
          if (sofId) {
            appStatus.PortCall.SofId = sofId;
          }
        }
        if (state.selectedProspectId){
          appStatus.PortCall.ProspectsId = state.selectedProspectId;
        }
        if (state.ServiceId) {
          appStatus.PortCall.ServiceId = state.ServiceId;
        }
        // expense selected Id's
        if (Array.isArray(state.expense.selectedIds) && state.expense.selectedIds.length > 0) {
          appStatus.PortCall.Expense = {};
          appStatus.PortCall.Expense.SelectedIds = state.expense.selectedIds;
        }
        // expense selected client id
        if (state.expense.selectedClientId) {
          if (!appStatus.PortCall.Expense) {
            appStatus.PortCall.Expense = {};
          }
          appStatus.PortCall.Expense.selectedClientId = state.expense.selectedClientId;
        }

        // service selected Id's
        if (Array.isArray(state.ServiceSelectedIds) && state.ServiceSelectedIds.length > 0) {
          appStatus.PortCall.ServiceSelectedIds = state.ServiceSelectedIds;
        }

        // visitors selected Id's
        if (Array.isArray(state.VisitorIds) && state.VisitorIds.length > 0) {
          appStatus.PortCall.VisitorIds = state.VisitorIds;
        }

        // attendance selected Id's
        if (Array.isArray(state.AttendanceSelectedIds) && state.AttendanceSelectedIds.length > 0) {
          appStatus.PortCall.AttendanceSelectedIds = state.AttendanceSelectedIds;
        }

        // ccm person selected Id's
        if (Array.isArray(state.CcmPersonIds) && state.CcmPersonIds.length > 0) {
          appStatus.PortCall.CcmPersonIds = state.CcmPersonIds;
        }

        // ccm person selected Id's
        if (Array.isArray(state.CcmTaskIds) && state.CcmTaskIds.length > 0) {
          appStatus.PortCall.CcmTaskIds = state.CcmTaskIds;
        }

        // cargo selected Id's
        if (Array.isArray(state.SelectedCargoIds) && state.SelectedCargoIds.length > 0) {
          appStatus.PortCall.SelectedCargoIds = state.SelectedCargoIds;
        }
      }
      if (state.VesselId) {
        appStatus.VesselId = state.VesselId;
      }
      if (state.SetupID) {
        appStatus.SetupID = state.SetupID;
      }
      return appStatus;
    },
  },
};

export default vuex_appStatus;
