<template>
  <gat-form :isLoading="isLoading">
    <div>
      <dashboard-settings
        v-show="showSettings"
        :location="location"
        :dashboardGroupName="groupName"
        :dashSorting="dashboardSorting"
        :sortItems="sortItems"
        :dashboardHasSortingType="dashboardHasSortingType"
        @refreshAllDashboards="initializeDashboard(true)"
        @close-clicked="showSettings = false"
        @show-hidden-charts="
          (val) => {
            showHiddenCharts = val;
          }
        "
        @setLoading="setLoading" />
      <div
        class="grid-container"
        :style="!$vuetify.breakpoint.xsOnly && showSettings ? 'width: calc(100% - 260px) !important;' : ''"
        v-if="!isLoading">
        <chart-item
          v-for="item in reports"
          :key="item.title"
          :title="item.title"
          :reportName="item.reportName"
          :dashboardGroupName="dashboardGroupName"
          :columns="item.columns"
          :hidden="item.hidden"
          :hiddenOnPhone="item.hiddenOnPhone"
          :showHidden="showHiddenCharts"
          :chartUpdateKey="chartUpdateKey"
          :userDef="item.userDefReport"
          :stickyFirstColumn="item.freezeColumn"
          :location="location"
          :reportsLoadingErrors="reportsLoadingErrors"
          @updateChartKey="increaseChartKey"
          @setreportsLoadingErrors="setreportsLoadingErrors"
          @onMaximized="setMaximized" />
        <!-- :settings="getActiveSettings(item.settings, item.userSettings, item.defaultSettings)" -->
      </div>
      <v-fab-transition v-if="!embedded">
        <v-btn v-show="!showSettings" fab fixed bottom right @click="showSettings = true">
          <v-icon>settings</v-icon>
        </v-btn>
      </v-fab-transition>
    </div>
  </gat-form>
</template>

<script>
import ChartItem from './ChartItem.vue';
import DashboardSettings from './DashboardSettings.vue';

export default {
  name: 'Dashboard',
  props: {
    location: String, // name of location: main, expenses etc.
    embedded: {
      type: Boolean,
      default: false,
    },
    embeddedShowSettings: Boolean,
    updateChartKey: Number,
    groupName: String,
  },
  components: { ChartItem, DashboardSettings },
  data() {
    return {
      showHiddenCharts: false,
      showFullSceenChart: false,
      showSettings: false,
      chartUpdateKey: 0,
      refreshAllDashboards: false,
      isLoading: false,
      lastScrollY: 0,
      dashboardGroupName: null,
      reports: null,
      reportsBackup: null,
      reportsFetchedLocation: [],
      reportsLoadingErrors: [],
      groupDataRetrieved: [],
    };
  },
  activated() {},

  computed: {
    groupId() {
      let result = null;
      if (this.groupName && this.location) {
        if (
          this.$store.state.dashboard &&
          this.$store.state.dashboard[this.location] &&
          this.$store.state.dashboard[this.location].dashboardGroups
        ) {
          const group = this.$store.state.dashboard[this.location].dashboardGroups.find(
            // eslint-disable-next-line @typescript-eslint/no-shadow
            (group) => group.NAME.trim().replace(' ', '').toLowerCase() === this.groupName
          );
          if (group) {
            result = group.GROUP_ID;
          }
        }
      }
      return result;
    },
    sortItems() {
      if (this.reports && this.reports.length > 0) {
        const result = [];
        // eslint-disable-next-line array-callback-return
        this.reports.map((report) => {
          const sortItem = {};
          sortItem.reportName = report.reportName;
          sortItem.title = report.title;
          if (
            Object.keys(report.userSettings).length > 0 &&
            Object.getPrototypeOf(report.userSettings) === Object.prototype
          ) {
            sortItem.hidden = report.userSettings.hidden;
          } else if (
            Object.keys(report.defaultSettings).length > 0 &&
            Object.getPrototypeOf(report.defaultSettings) === Object.prototype
          ) {
            sortItem.hidden = report.defaultSettings.hidden;
          } else {
            sortItem.hidden = report.settings.hidden;
          }
          result.push(sortItem);
        });
        return result;
      }
      return [];
    },
    appCriticalInitFinished() {
      return this.$store.state.application.appIsInitialized;
    },
    reportsRetrieved() {
      return this.reportsFetchedLocation.findIndex((item) => item === this.location) >= 0;
    },
    dataRetrievedForGroup() {
      const found = this.groupDataRetrieved.findIndex((item) => item === this.groupId) >= 0;
      const all = this.groupDataRetrieved.findIndex((item) => item === -1) >= 0;
      return found || all;
    },
    dashboardReports() {
      let result = [];
      if (this.reportsRetrieved) {
        if (this.location && this.dashboardGroupName) {
          result = this.duplicateViaJson(
            this.$store.getters.getDashboardReports({
              location: this.location,
              dashboardGroupName: this.dashboardGroupName,
            })
          );
        } else if (this.location) {
          result = this.duplicateViaJson(this.$store.getters.getDashboardReports({ location: this.location }));
        }
      }
      return result;
    },
    dashboardHasSortingType() {
      let result = {
        initial: false,
        user: false,
        default: false,
      };
      if (this.reportsRetrieved) {
        if (this.location && this.dashboardGroupName) {
          result = this.duplicateViaJson(
            this.$store.getters.getDashboardSorting({
              location: this.location,
              dashboardGroupName: this.dashboardGroupName,
              sortingName: true,
            })
          );
        } else if (this.location) {
          result = this.duplicateViaJson(this.$store.getters.getEmbeddedDashboardSorting({ location: this.location }));
        }
      }
      return result;
    },
    dashboardSorting() {
      const sorting = [];
      const sortingTypes = this.dashboardHasSortingType;
      if (sortingTypes) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        sortingTypes.initial;
      }
      return sorting;
    },
  },

  watch: {
    dashboardReports: {
      immediate: true,
      handler(reports) {
        this.reports = reports;
      },
    },
    groupName: {
      immediate: true,
      handler(val) {
        this.reports = [];
        setTimeout(() => {
          if (val) {
            const formattedString = val.trim().replace(' ', '').toLowerCase();
            this.dashboardGroupName = formattedString;
            this.getDashboardSettings({ location: this.location, dashboardGroupName: val });
          }
        }, 100);
        this.fetchAllGroupData({ dashboardGroupId: this.groupId, forceReload: false });
      },
    },
    location: {
      immediate: true,
      handler() {
        if (!this.reportsRetrieved) {
          this.initializeDashboard();
        }
      },
    },
    showSettings() {
      if (this.embedded) {
        this.$emit('closeSettingsMenu');
      }
      if (!this.$vuetify.breakpoint.xsOnly) {
        setTimeout(() => {
          this.increaseChartKey();
        }, 100); // delay 100ms to account for the settings menu to open before updating the charts.
      }
    },
    embeddedShowSettings() {
      this.showSettings = true;
    },
    /* location:{
        immediate: true,
        handler(){
          if(!this.dashboardGroupName){
            this.getSettings()
          }
        }
      }, */
    updateChartKey() {
      this.increaseChartKey();
    },
  },

  methods: {
    setMaximized({ maximized, reportName }) {
      const { scrollY } = window;
      if (maximized) {
        this.lastScrollY = scrollY;
        this.reportsBackup = [...this.reports];
        const filteredReports = this.reports.filter((item) => item.reportName === reportName);
        this.reports = filteredReports;
      } else {
        this.reports = [...this.reportsBackup];
        this.$nextTick(() => {
          window.scroll(0, this.lastScrollY);
          this.lastScrollY = 0;
        });
      }
    },
    //* * Updates all the chart keys */
    increaseChartKey() {
      this.chartUpdateKey++;
    },

    setreportsLoadingErrors({ add, remove, reportName }) {
      const idx = this.reportsLoadingErrors.findIndex((item) => item === reportName);
      if (add) {
        if (idx < 0) {
          this.reportsLoadingErrors.push(reportName);
        }
      }
      if (remove) {
        if (idx >= 0) {
          this.reportsLoadingErrors.splice(idx, 1);
        }
      }
    },
    async getDashboardSettings({ location, dashboardGroupName, forceReload }) {
      const settingsRetrieved = this.$store.getters.dashboardSettingsRetrieved({ location, dashboardGroupName });

      if (forceReload || !settingsRetrieved) {
        await this.$store.dispatch('getDashboardSorting', { location, dashboardGroupName });

        // Get the dashboard settings, and then set loading to false.
        await this.$store.dispatch('getChartSettings', { location, dashboardGroupName });
      }
    },

    async fetchDataByIteration(forceReload) {
      for (let i = 0; this.dashboardReports.length > i; i++) {
        const { reportName } = this.dashboardReports[i];
        const chartHasData = this.$store.getters.getChartData({ reportName });
        // Get the dashboard data, and then set chart item loading to false.
        if (forceReload || typeof chartHasData === 'undefined') {
          const idx = this.reportsLoadingErrors.findIndex((item) => item === reportName);
          try {
            if (idx >= 0) {
              this.reportsLoadingErrors.splice(idx, 1);
            }
            // eslint-disable-next-line no-await-in-loop
            await this.$store.dispatch('fetchChartData', { reportName });
          } catch (error) {
            this.reportsLoadingErrors.push(reportName);
          }
        }
      }
      this.groupDataRetrieved.push(this.groupId);
    },

    async fetchAllGroupData({ dashboardGroupId, forceReload }) {
      if (!dashboardGroupId) {
        // eslint-disable-next-line no-param-reassign
        dashboardGroupId = this.groupId;
      }
      if (!this.dataRetrievedForGroup && !forceReload) {
        if (!dashboardGroupId) {
          setTimeout(() => {
            this.fetchAllGroupData({ dashboardGroupId, forceReload });
          }, 500);
          return;
        }
        if (dashboardGroupId === -1) {
          // if its all reports.
          const allAvailableGroups = [];
          // eslint-disable-next-line array-callback-return
          this.$store.state.dashboard[this.location].dashboardGroups.map((group) => {
            if (group.GROUP_ID != -1) allAvailableGroups.push(group.GROUP_ID);
          });
          for (let i = 0; allAvailableGroups.length > i; i++) {
            if (
              this.groupDataRetrieved.findIndex((retrieved) => allAvailableGroups[i] === retrieved) < 0 ||
              forceReload
            ) {
              // eslint-disable-next-line no-await-in-loop
              await this.$store.dispatch('fetchAllChartData', { dashboardGroupId: allAvailableGroups[i] });
              this.groupDataRetrieved.push(allAvailableGroups[i]);
            }
          }
          this.groupDataRetrieved.push(dashboardGroupId);
        } else {
          await this.$store.dispatch('fetchAllChartData', { dashboardGroupId });
          this.groupDataRetrieved.push(this.groupId);
        }
      }
    },

    async initializeDashboard(forceReload) {
      const dashboardGroupName = this.groupName;
      const { location } = this;
      if (this.appCriticalInitFinished && (dashboardGroupName || location)) {
        /* this.setLoading(true); */
        if (!dashboardGroupName) {
          await this.$store.dispatch('getEmbeddedDashboardReports', { location });
          this.reportsFetchedLocation.push(location);
          this.getDashboardSettings({ location, forceReload });
          this.fetchDataByIteration(forceReload);
        } else {
          this.reportsFetchedLocation.push(location);
          this.getDashboardSettings({ location, dashboardGroupName, forceReload });
          if (forceReload) {
            this.fetchDataByIteration(forceReload);
          }
        }
      } else {
        // wait .5s and check again.
        setTimeout(() => {
          this.initializeDashboard();
        }, 500);
      }
    },
    setLoading(val) {
      this.isLoading = val;
    },
  },
};
</script>
<style lang="scss" scoped>
.settingsBtn {
  position: fixed;
  right: 10px;
  top: 50px;
}
.DashSettings {
  padding-right: 270px;
}
.dash-table {
  flex-grow: 1;
  min-width: 200px;
  max-width: auto;
  background: grey !important;
}
.grid-container {
  transition: width 0.1s;
  display: grid;
  width: 100%;
  height: inherit;
  grid-template-columns: 25% 25% 25% 25%;
  grid-auto-rows: 250px; // height of each row, correlates with rowHeight in chartItem.vue
}

.s-height {
  grid-row: span 1;
}
.m-height {
  grid-row: span 2;
}
.l-height {
  grid-row: span 3;
}
.xl-height {
  grid-row: span 4;
}

.s-width {
  grid-column: span 1;
}
.m-width {
  grid-column: span 2;
}
.l-width {
  grid-column: span 3;
}
.xl-width {
  grid-column: span 4;
}

// MD
@media only screen and (max-width: 960px) {
  .grid-container {
    grid-template-columns: 33.33% 33.33% 33.33%;
  }
  .s-width {
    grid-column: span 1;
  }
  .l-width,
  .m-width {
    grid-column: span 2;
  }
  .xl-width {
    grid-column: span 3;
  }
}

// XS
@media only screen and (max-width: 600px) {
  .grid-container {
    grid-template-columns: 50% 50%;
  }
  .s-width,
  .m-width {
    grid-column: span 1;
  }
  .l-width,
  .xl-width {
    grid-column: span 2;
  }
  /* .s-height{
    grid-row: span 1;
  }
  .m-height, .l-height{
    grid-row: span 1;
  } */
}

@media only screen and (max-width: 500px) {
  .grid-container {
    transition: all 2s;
    grid-template-columns: 100%;
  }
  .s-width,
  .m-width,
  .l-width,
  .xl-width {
    grid-column: span 1;
  }

  /* .s-height, .m-height, .l-height{
    grid-row: span 1;
  } */
}
</style>
