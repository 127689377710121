<template>
  <div>
    <gat-grid
      :title="$vuetify.breakpoint.xsOnly ? 'Connected clients' : 'Clients connected to the port call'"
      class="elevation-0"
      :items="items"
      :columns="columns"
      :loading="loading"
      hide-empty-columns
      fixedheader
      noPadding
      height="calc(100vh - 135px)"
      reloadBtn
      @row-clicked="hotlistClicked"
      @reload-clicked="getHotlist(callId)">
      <template slot="cell" slot-scope="props">
        <div v-if="props.column.field == `CT_NAME`">
          <a v-if="$store.state.user.internal" class="gridLink" @click.stop="hotlistClicked(props.row)">{{
            props.value
          }}</a>
          <div v-else>{{ props.value }}</div>
        </div>

        <div v-else-if="props.column.field == 'C_NAME'" class="text-left d-flex align-center justify-start">
          <div v-if="props.row.SHOW_WARNING" @click.stop class="mr-2">
            <v-tooltip
              :bottom="$vuetify.breakpoint.smAndDown"
              :right="!$vuetify.breakpoint.smAndDown"
              open-on-click
              color="primary"
              class="tooltip-test">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="orange" v-bind="attrs" v-on="on">mdi-alert</v-icon>
              </template>
              <div v-if="props.row.WARNING">
                {{ props.row.WARNING }}
              </div>
              <div v-else>N/A</div>
            </v-tooltip>
          </div>

          <a v-if="$store.state.user.internal" class="gridLink" @click.stop="goToHotlistClient(props.row)">{{
            props.row.C_NAME
          }}</a>
          <div v-else>{{ props.row.C_NAME }}</div>
        </div>
        <div v-else-if="props.column.field == 'CLIENT_CREDIT_FLAG'">
          <!-- <v-icon color="green lighten-1">mdi-database</v-icon> -->
          <v-tooltip bottom v-if="+props.value > 0">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" :color="getClienCreditFlagColor(props.value)">mdi-flag</v-icon>
            </template>
            {{ props.row.CLIENT_CREDIT_BALANCE }}
            {{ $store.getters.agencySettings($store.state.portcall.callDetails.SETUP_ID).LocalCurrency }}
          </v-tooltip>
        </div>

        <div v-else-if="props.column.field == `C_EMAIL`">
          <a class="gridLink" :href="`mailto:${props.value}`" @click.stop>{{ props.value }}</a>
        </div>
        <div v-else-if="props.column.field == `RECIPIENTS` && props.row.H_ID > 0" @click.stop>
          <div>
            <SelectRecipients
              :value="props.row.RECIPIENTS"
              :client-id="props.row.CLIENT_ID"
              :hotlist-id="props.row.H_ID"
              :all-recipients="allRecipients"
              :call-id="callId"
              @recipients-updated="getHotlist" />
          </div>
        </div>
      </template>
      <template slot="btns">
        <wm-button-group
          class="right mr-1"
          :buttons="[
            {
              btnType: 'ADD',
              userRight: 'P.EDIT',
              openMenuOnHover: true,
              disabled: !$store.state.user.internal,
              hideCaptionOnPhone: false,
              menuItems: [
                { caption: 'Add client', eventName: 'addClientClick' },
                { caption: 'Add provisional contact', eventName: 'addTempContactClick' },
              ],
            },
          ]"
          @addClientClick="addClient"
          @addTempContactClick="addTempContact" />
      </template>
    </gat-grid>

    <!-- <record-presenter :value="this.$store.state.portcall.hotlist" /> -->

    <hotlist-form :value="hotlistItem" :visible="showDialog" @close="closeDialog" />
    <hotlist-temp-form :value="hotlistItem" :visible="showTempDialog" @close="closeDialog" />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { apiGet } from '@/store/api';
import HotlistForm from './HotlistForm.vue';
import HotlistTempForm from './HotlistTempForm.vue';
import SelectRecipients from './SelectRecipients.vue';

export default {
  name: 'Hotlist',
  props: ['callId'],
  components: { HotlistForm, HotlistTempForm, SelectRecipients },
  data() {
    return {
      hotlistItem: {},
      loading: false,
      showDialog: false,
      showTempDialog: false,
      allRecipients: [],
    };
  },

  computed: {
    items() {
      if (this.loading) {
        return [];
      }
      const result = [...this.$store.state.portcall.hotlist];
      return result;
    },
    columns() {
      const result = [];
      result.push({ header: 'Role', field: 'CT_NAME', hideOnPhone: false, class: 'px-4' });
      result.push({ header: 'Client', field: 'C_NAME', hideOnPhone: false, class: 'px-4' });

      result.push({ header: '', field: 'CLIENT_CREDIT_FLAG', width: '1%', class: 'px-0' });
      result.push({ header: 'Nationality', field: 'N_NAME', hideOnPhone: true, class: 'px-4' });
      result.push({
        header: 'Phone',
        linkUrl: 'tel:field[C_PHONE]',
        linkText: 'field[C_PHONE]',
        hideOnPhone: false,
        class: 'px-4',
      });
      result.push({ header: 'Email', field: 'C_EMAIL', hideOnPhone: true, class: 'px-4' });
      result.push({ header: 'Recipients', field: 'RECIPIENTS', class: 'px-4', neverHide: true });
      result.push({ header: 'Client no', field: 'C_ACCOUNT_NO', hideOnPhone: true, class: 'px-4' });
      if (this.$store.state.user.internal) {
        result.push({
          header: 'Fixed fee',
          field: 'C_FIXED_FEE',
          hideOnPhone: true,
          dataType: 'currency',
          class: 'px-4',
        });
        result.push({ header: 'Web access', field: 'WAP_USE_WM', hideOnPhone: true, checkbox: true, class: 'px-4' });
      }
      result.push({ header: 'Ranking', field: 'RANKING', hideOnPhone: true, class: 'px-4' });
      result.push({ header: 'Ranking comments', field: 'RANKING_COMMENT', hideOnPhone: true, class: 'px-4' });

      return result;
    },
  },

  created() {},

  watch: {
    callId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getHotlist();
        }
      },
    },
  },

  methods: {
    addClient() {
      this.hotlistItem = { PORTCALL_ID: parseInt(this.callId, 10) };
      this.showDialog = true;
    },
    addTempContact() {
      this.hotlistItem = { CLIENT_ID: 0, PORTCALL_ID: parseInt(this.callId, 10) };
      this.showTempDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
      this.showTempDialog = false;
      this.hotlistItem = {};
    },
    async getHotlist(callId = this.callId) {
      this.loading = true;
      const promises = [];
      promises.push(this.$store.dispatch('getHotlist', callId));
      promises.push(this.getAvaliableRecipients());
      await Promise.allSettled(promises);
      this.loading = false;
    },
    goToHotlistClient(client) {
      if (client.H_ID == -1) {
        this.hotlistItem = client;
        this.showTempDialog = true;
      } else {
        this.$router.push(`/client/${client.CLIENT_ID}`);
      }
    },
    getClienCreditFlagColor(value) {
      let result = '';
      switch (+value) {
        case 0:
          result = 'grey';
          break;
        case 1:
          result = 'red darken-1';
          break;
        case 2:
          result = 'orange darken-1';
          break;
        case 3:
          result = 'green darken-1';
          break;
        default:
          result = 'grey';
          break;
      }
      return result;
    },
    hotlistClicked(item) {
      if (!this.$store.state.user.internal) {
        return;
      }
      if (item.H_ID > 0) {
        apiGet(`hotlist/client/${item.H_ID}`).then((response) => {
          if (response) {
            this.hotlistItem = response;
            this.showDialog = true;
            this.$store.commit('replaceHotlistItem', response);
          }
        });
      } else if (item.H_ID == -2) {
        // terminal
      } else if (item.H_ID == -1) {
        // temp contact
        apiGet(`hotlist/temp/${item.CLIENT_ID}`).then((response) => {
          if (response) {
            this.hotlistItem = response;
            this.showTempDialog = true;
            this.$store.commit('replaceHotlistItem', response);
          }
        });
      }
    },
    async getAvaliableRecipients() {
      const response = await apiGet(`hotlist/recipients/${this.callId}`);
      this.allRecipients = [];
      if (response) {
        this.allRecipients = response;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-tooltip__content {
  opacity: 1 !important;
}
</style>
