import { render, staticRenderFns } from "./DocumentStatus.vue?vue&type=template&id=1073ab00&scoped=true&"
import script from "./DocumentStatus.vue?vue&type=script&lang=js&"
export * from "./DocumentStatus.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1073ab00",
  null
  
)

export default component.exports