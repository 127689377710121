<template>
  <v-dialog v-model="showDialog" persistent max-width="1000">
    <v-card class="pa-3">
      <gat-form
        v-model="isValid"
        ref="form"
        :readonly="!this.$store.getters.userRight('SER.NEW_AND_EDIT')"
        :isLoading="isLoading">
        <gat-select label="Service set" v-model="selectedServiceTemplate" :items="serviceTemplates" size="xxl" />
      </gat-form>
      <gat-grid
        dense
        :elevation="0"
        :compact="true"
        title="Service items"
        :items="items"
        :columns="columns"
        :selectable="true"
        keyName="SER_ID"
        :selectedKeys="selected"
        @selectionChanged="selected = $event"
        height="500px"
        min-width="500px" />
      <!-- {{items}} -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" text @click="$emit('close')"> Close </v-btn>
        <v-btn
          color="success"
          :disabled="selected.length == 0 || !this.$store.getters.userRight('SER.NEW_AND_EDIT') || isLoading"
          @click="addServiceItems(selected)">
          {{ addBtnText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { apiGet, apiPost } from '@/store/api';

export default {
  name: 'ServiceTemplateDialog',
  props: {
    showDialog: Boolean,
    callId: Number,
  },
  components: {},
  data() {
    return {
      isValid: false,
      isLoading: false,
      templates: [],
      selectedTemplateId: -1,
      items: [],
      selected: [],
    };
  },

  created() {
    /* this.getServiceTemplates(); */
  },

  watch: {
    agencyId: {
      immediate: true,
      handler() {
        this.getServiceTemplates();
        this.getServiceItems(this.selectedTemplateId);
      },
    },
    items: {
      handler(newVal) {
        if (this.selectedTemplateId == -1) {
          if (this.selected.length > 0) {
            this.selectAllItems(newVal);
          }
        } else {
          this.selectAllItems(newVal);
        }
      },
    },
  },
  activated() {},

  computed: {
    agencyId() {
      return this.$store.state.application.agencyIdSelected;
    },
    columns() {
      const result = [];
      result.push({ header: 'Name', field: 'SER_TEMPLATE_NAME' });
      result.push({ header: 'Service Type', field: 'STY_NAME' });
      result.push({ header: 'Description', field: 'SER_DESCRIPTION' });
      return result;
    },
    serviceTemplates() {
      const { templates } = this;
      const result = templates.map((obj) => {
        const newObj = {};
        if (obj.SSE_ID) {
          newObj.text = obj.SSE_NAME;
          newObj.value = obj.SSE_ID;
        }
        return newObj;
      });
      return result;
    },
    selectedServiceTemplate: {
      get() {
        return this.selectedTemplateId;
      },
      set(id) {
        if (id != this.selectedTemplateId) {
          this.selectedTemplateId = id;
          this.getServiceItems(id);
        }
      },
    },

    addBtnText() {
      let result = 'add ';
      if (this.selected && this.selected.length != 0) {
        result += this.selected.length;
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.selected.length <= 1 ? (result += ' item') : (result += ' items');
      }
      return result;
    },
  },

  methods: {
    addServiceItems(ids) {
      this.isLoading = true;
      apiPost('service/insertItem', { PORTCALL_ID: this.callId, SER_ID: ids }).then((result) => {
        /* console.log(result.payload); */
        if (result) {
          if (result.resultCategory == 1) {
            for (let i = 0; result.payload.length > i; i++) {
              if (result.payload[i].SER_EXP_ID) {
                this.$store.dispatch('getExpenses', this.callId);
                break;
              }
            }
          }
          this.$store.dispatch('getServices', this.callId);
          this.selected = [];
          this.isLoading = false;
          this.$emit('close');
        }
      });
    },
    getServiceTemplates() {
      /* this.isLoading = true; */
      if (this.agencyId) {
        apiGet(`service/templates/${this.agencyId}`).then((result) => {
          if (result) {
            const showAll = { SSE_ID: -1, SSE_NAME: 'Show All' };
            result.splice(0, 0, showAll);
            // remove progressbar
            /* this.isLoading = false; */
            this.templates = result;
            /* this.getServiceItems(showAll); */
          }
        });
      }
    },
    getServiceItems(id) {
      if (this.agencyId && id) {
        apiGet(`service/template/${this.agencyId}/${id}`).then((result) => {
          this.items = result;
        });
      }
    },
    selectAllItems(items) {
      this.selected = [];
      this.$nextTick(() => {
        for (let i = 0; items.length > i; i++) {
          this.selected.push(items[i].SER_ID);
        }
      });
    },
  },
};
</script>

<style scoped></style>
