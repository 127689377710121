/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-cycle
import { apiGet, apiPost, apiDelete } from '@/store/api';
import { GlobalMixin } from '@gatship/gs-components';
import { createReportObject } from '@/modules/dashboard/helper/dashboardHelper';

export const actions = {
  setAllUsersToDefaultSettings({ commit }, { location, dashboardGroupName, reportName }) {
    if ((!location && !reportName) || (!location && !dashboardGroupName && !reportName))
      return console.error('location, dashboard group or report name was not provided.');
    const urlExtension = dashboardGroupName
      ? `${location}.${dashboardGroupName.trim().replace(' ', '').toLowerCase()}.${reportName}`
      : `${location}.${reportName}`;
    return new Promise((resolve) => {
      apiDelete(`savedinfo/users/web_dashboard_${urlExtension}`).finally(() => {
        commit('setReportSettings', {
          location,
          dashboardGroupName,
          reportName,
          settings: {},
          settingName: 'userSettings',
        });
        resolve();
      });
    });
  },

  setAllUsersToDefaultSorting({ commit }, { location, dashboardGroupName }) {
    if (!location || (!location && !dashboardGroupName))
      return console.error('location or dashboard group was not provided.');
    const urlExtension = dashboardGroupName
      ? `${location}.${dashboardGroupName.trim().replace(' ', '').toLowerCase()}`
      : location;
    return new Promise((resolve) => {
      apiDelete(`savedinfo/users/web_dashboard_sorting.${urlExtension}`).finally(() => {
        commit('setDashboardSorting', {
          location,
          dashboardGroupName,
          sorting: [],
          settingName: 'user',
        });
        resolve();
      });
    });
  },

  setDashSorting({ commit, getters }, { location, dashboardGroupName, sorting, isDelete, noSave, isDefault }) {
    if (!location || (!location && !dashboardGroupName))
      return console.error('location or dashboard group was not provided.');

    return new Promise((resolve) => {
      const urlExtension = dashboardGroupName
        ? `${location}.${dashboardGroupName.trim().replace(' ', '').toLowerCase()}`
        : location;
      const savedInfo = {
        TypeName: `web_dashboard_sorting.${urlExtension}`,
        StrValue: JSON.stringify(sorting),
      };

      if (isDefault) {
        savedInfo.default = true;
      }
      // Check if the sorting is equal to initial sorting.
      const initialSorting = getters.getDashboardSorting({
        location,
        dashboardGroupName,
        sortingName: true,
      }).initial;
      // if isDelete or it is equal to initial sorting, delete them from the DB.

      if (isDelete || GlobalMixin.methods.globalObjectsAreAlike(initialSorting, sorting)) {
        apiDelete('savedInfo', savedInfo).then(() => {
          commit('setDashboardSorting', {
            location,
            dashboardGroupName,
            sorting: [],
            settingName: isDefault ? 'default' : 'user',
          });
          resolve();
        });
      } else if (!noSave) {
        apiPost('savedInfo', savedInfo).then(() => {
          commit('setDashboardSorting', {
            location,
            dashboardGroupName,
            sorting,
            settingName: isDefault ? 'default' : 'user',
          });
          resolve();
        });
      } else {
        commit('setDashboardSorting', {
          location,
          dashboardGroupName,
          sorting,
          settingName: isDefault ? 'default' : 'user',
        });
        resolve();
      }
    });
  },
  updateChartSettings({ commit, getters }, { location, dashboardGroupName, reportName, settings, settingName }) {
    if ((!location && !reportName) || (!location && !dashboardGroupName && !reportName))
      return console.error('location, dashboard group or report name was not provided.');
    const urlExtension = dashboardGroupName
      ? `${location}.${dashboardGroupName.trim().replace(' ', '').toLowerCase()}.${reportName}`
      : `${location}.${reportName}`;
    if (!settingName) {
      settingName = 'userSettings';
    }
    return new Promise((resolve) => {
      const savedInfo = {
        TypeName: `web_dashboard_${urlExtension}`,
        StrValue: JSON.stringify(settings),
        StrValue2: reportName,
      };
      if (settingName === 'defaultSettings') {
        savedInfo.Default = true;
      }
      const payload = {
        location,
        dashboardGroupName,
        reportName,
        settings,
        settingName,
      };
      // Check if the settings are equal to initial settings.
      const { initialSettings } = getters.getChartSettings({
        location,
        dashboardGroupName,
        reportName,
        getSettingsName: true,
      });
      const changes = GlobalMixin.methods.getObjectChanges(initialSettings, settings);
      // if settings are empty or they are equal to initial settings, delete them from the DB.
      if (JSON.stringify(settings) == JSON.stringify({}) || changes.length === 0) {
        // delete
        apiDelete('savedInfo', savedInfo)
          .then(() => {
            commit('setReportSettings', payload);
          })
          .finally(() => resolve());
      } else {
        // save
        apiPost('savedInfo', savedInfo)
          .then(() => {
            commit('setReportSettings', payload);
          })
          .finally(() => resolve());
      }
    });
  },
  getChartSettings({ commit }, { location, dashboardGroupName }) {
    const urlExtension = dashboardGroupName
      ? `${location}.${dashboardGroupName.trim().replace(' ', '').toLowerCase()}`
      : `${location}`;
    return new Promise((resolve) => {
      apiGet(`dashboard/user-settings/web_dashboard_${urlExtension}`).then((data) => {
        if (data.length > 0) {
          data.map((savedInfo) => {
            let settingName = 'userSettings';
            let settings = {};
            const reportName = +savedInfo.REPORT_NAME;
            if (savedInfo.NAME === 'DefaultSettings') {
              settingName = 'defaultSettings';
            }
            if (savedInfo.REPORT_SETTINGS) {
              settings = JSON.parse(savedInfo.REPORT_SETTINGS);
            }
            commit('setReportSettings', {
              location,
              dashboardGroupName,
              reportName,
              settings,
              settingName,
            });
          });
        }
      });

      resolve();
    });
  },

  fetchAllChartData({ commit, getters }, { dashboardGroupId }) {
    if (!dashboardGroupId) {
      return console.error('group name not present.');
    }
    dashboardGroupId = +dashboardGroupId;
    return new Promise((resolve, reject) => {
      const agencyId = getters.getCurrentAgency;
      apiGet(`/dashboard/group-data/${dashboardGroupId}/${agencyId}`)
        .then((result) => {
          // console.log(
          //   `%cDASHBOARD API: dashboard/group-data/${dashboardGroupId}/${agencyId}`,
          //   'background: #222; color: white'
          // );
          result.map((report) => {
            const { reportName } = report;
            const { reportData } = report;
            commit('setReportData', { reportName, reportData });
          });
          resolve(result);
        })
        .catch(() => {
          reject();
        });
    });
  },

  fetchChartData({ commit, getters }, { reportName }) {
    return new Promise((resolve, reject) => {
      const agencyId = getters.getCurrentAgency;
      apiGet(`/dashboard/report/user-def/${reportName}/${agencyId}`)
        .then((result) => {
          // console.log(
          //   `%cDASHBOARD API: dashboard/report/user-def/${reportName}/${agencyId}`,
          //   'background: #222; color: white'
          // );
          let reportData = result.data;
          if (reportData === null) {
            reportData = '';
          }
          commit('setReportData', { reportName, reportData });
          resolve(reportData);
        })
        .catch(() => {
          reject();
        });
    });
  },

  getDashboardSorting({ commit }, { location, dashboardGroupName }) {
    let urlExtension = location;
    if (dashboardGroupName) {
      urlExtension = `${location}.${dashboardGroupName.trim().replace(' ', '').toLowerCase()}`;
    }
    return new Promise((resolve) => {
      const userSettings = apiGet(`savedinfo/web_dashboard_sorting.${urlExtension}`).then((data) => {
        if (data.length > 0) {
          data.map((savedInfo) => {
            if (savedInfo.NAME != 'DefaultSettings') {
              const sorting = JSON.parse(savedInfo.MEMOVALUE1);
              commit('setDashboardSorting', {
                location,
                dashboardGroupName,
                sorting,
                settingName: 'user',
              });
            }
          });
        }
      });
      const defaultSettings = apiGet(`savedinfo/default/web_dashboard_sorting.${urlExtension}`).then((data) => {
        if (data.length > 0) {
          data.map((savedInfo) => {
            const sorting = JSON.parse(savedInfo.MEMOVALUE1);
            commit('setDashboardSorting', {
              location,
              dashboardGroupName,
              sorting,
              settingName: 'default',
            });
          });
        }
      });
      Promise.allSettled([userSettings, defaultSettings]).then(() => {
        resolve();
      });
    });
  },

  getDashboardGroups({ commit /* , dispatch */ }) {
    return new Promise((resolve) => {
      apiGet('dashboard/groups').then((data) => {
        if (data.length > 0) {
          // Create the first group which should contain all available reports.
          const dashboardGroups = [
            {
              GROUP_ID: -1,
              NAME: 'All',
              SORT_ORDER: 10000,
              reports: [],
            },
          ];

          // allReports stores all the reports (no duplicates).
          const allReports = [];

          // Add all the other groups from the get-result.
          data.forEach((item) => {
            dashboardGroups.push(item);
          });

          // Iterate through the groups to assign new values.
          dashboardGroups.forEach((group) => {
            group.sorting = {
              initial: [],
              user: [],
              default: [],
            };
            const groupReports = [];
            group.reports.forEach((report) => {
              const newReport = createReportObject(report);
              groupReports.push({ ...newReport });
              const reportFoundInAllReports = allReports.find((item) => item.reportName == newReport.reportName);
              if (!reportFoundInAllReports) {
                allReports.push({ ...newReport }); // make copy of report object.
              }
            });
            group.reports = groupReports;
          });
          // assign the allReports array to the first dashboard group.
          dashboardGroups[0].reports = allReports;

          // Finally add initial sorting
          dashboardGroups.forEach((group) => {
            group.reports.map((report) => {
              const reportSorting = {
                reportName: report.reportName,
                sorting: report.sorting,
              };
              group.sorting.initial.push(reportSorting);
            });
            const filteredResult = group.sorting.initial.filter((item) => item.sorting > -10000);
            const finalResult = filteredResult.map((res) => res.reportName);
            group.sorting.initial = finalResult;
          });
          dashboardGroups.sort((a, b) => a.SORT_ORDER - b.SORT_ORDER);
          // groups with initial sorting has been set in the store
          commit('setDashboardGroups', dashboardGroups);
        }
        resolve();
      });
    });
  },

  getEmbeddedDashboardReports({ commit }, { location }) {
    const sorting = {
      initial: [],
      user: [],
      default: [],
    };
    return new Promise((resolve) => {
      apiGet(`dashboard/user-def-reports/${location}`).then((reports) => {
        // console.log(`%cDASHBOARD API: dashboard/user-def-reports/${location}`, 'background: #222; color: white');
        if (reports.length > 0) {
          // re-map report object.
          reports.forEach((report, index) => {
            reports[index] = createReportObject(report);
          });

          // assign sorting values.
          reports.map((report) => {
            const reportSorting = {
              reportName: report.reportName,
              sorting: report.sorting,
            };
            sorting.initial.push(reportSorting);
          });

          const filteredResult = sorting.initial.filter((item) => item.sorting > -10000);
          const finalResult = filteredResult.map((res) => res.reportName);
          sorting.initial = finalResult;
        }
        commit('setEmbeddedDashboardReports', { location, reports, sorting });
        resolve(reports);
      });
    });
  },
};
