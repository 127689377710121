/* eslint-disable @typescript-eslint/naming-convention */
// eslint-disable-next-line import/no-cycle
import { actions } from './attendanceActions';
import { mutations } from './attendanceMutations';
import { getters } from './attendanceGetters';

const vuex_attendance = {
  state: {
    attendanceCodes: [],
    inAttendance: null,
    berthedPortcalls: [],
    previousAttendances: [],
    portcallAttendances: [],
  },
  mutations,
  actions,
  getters,
};

export default vuex_attendance;
