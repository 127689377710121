<template>
  <div>
    <gat-tab :items="tabs" :show-in-toolbar="true" />
    <!-- <record-presenter :value="webAccessGroup" />     -->
    <keep-alive>
      <router-view v-if="!loadingPortCall" :callId="callId" location="portcall" :parentId="callId" :accessGroup="webAccessGroup"></router-view>
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: 'PortCall',
  props: ['callId'],
  components: {},
  data() {
    return {
      loadingPortCall:true,
    };
  },

  computed: {
    selectedAgency() {
      return this.$store.state.application.agencyIdSelected;
    },
    callDetails() {
      return this.$store.state.portcall.callDetails;
    },
    traversableListItems() {
      return this.$store.state.whiteboard.whiteBoardData;
    },
    tabHasSqlScripts() {
      return this.$store.state.helper.tabHasSqlScripts;
    },
    tabs() {
      // tabHasData, set noTabData property if you want the no-data icon to work.
      const callCategoryLimit = this.callDetails.callCategoryLimitation;
      let caption = '';
      let sortOrderCaption = '';
      const result = [];
      if (!this.$store.state.application.isCRM) {
        sortOrderCaption = 'Details';
        caption = this.shortenCaptionOnMobileTo(sortOrderCaption, 'Call');
        result.push({ sortOrderCaption, caption, routeName: 'callDetails' });
      }

      if (
        (this.hasTabRight('TAB_GOODS') &&
          callCategoryLimit &&
          !callCategoryLimit.Cargo &&
          this.$store.state.user.internal) ||
        this.$store.getters.userRight('CA.ACCESS')
      ) {
        sortOrderCaption = 'Cargo';
        caption = this.shortenCaptionOnMobileTo(sortOrderCaption, 'Cargo');
        result.push({
          sortOrderCaption,
          caption,
          routeName: 'cargoList',
          routeParams: { callId: this.callId },
          noTabData: 'GOODS',
        });
      }
      if (
        (this.hasTabRight('TAB_CHECKLIST') && this.$store.state.user.internal) ||
        this.$store.getters.userRight('CH.ACCESS')
      ) {
        sortOrderCaption = 'Checklist';
        caption = this.shortenCaptionOnMobileTo(sortOrderCaption, 'Chk');
        result.push({
          sortOrderCaption,
          caption,
          routeName: 'checkList',
          routeParams: { callId: this.callId },
          noTabData: 'CHECKLIST',
        });
      }

      if (
        (this.hasTabRight('TAB_HOTLIST') && this.$store.state.user.internal) ||
        this.$store.getters.userRight('H.ACCESS')
      ) {
        sortOrderCaption = 'Clients';
        caption = this.shortenCaptionOnMobileTo(sortOrderCaption, 'Clnt');
        result.push({
          sortOrderCaption,
          caption,
          routeName: 'hotlist',
          routeParams: { callId: this.callId },
          noTabData: 'HOTLIST',
        });
      }

      if (
        this.hasTabRight('TAB_CREW_CHANGE_MODULE') &&
        !this.$store.state.application.isCRM &&
        this.$store.state.globalSettings.SGL_CREW_CHANGE_2021 === 1 &&
        (this.$store.state.user.internal ||
          this.$store.getters.userRight('CRCH.ACCESS') ||
          this.$store.getters.userRight('CRCH.ACCESS_OWN_TASK') ||
          this.$store.getters.userRight('CRCH.ACCESS_ALL_PERSONS'))
      ) {
        sortOrderCaption = 'Crew Change';
        caption = this.shortenCaptionOnMobileTo(sortOrderCaption, 'Crew');
        result.push({
          sortOrderCaption,
          caption,
          routeName: 'crewChange',
          routeParams: { callId: this.callId },
          noTabData: 'CREWCHANGE',
        }); // noTabData:this.tabHasData("CREWCHANGE")
      }

      if (
        (this.hasTabRight('TAB_DOCUMENTS') && this.$store.state.user.internal) ||
        this.$store.getters.userRight('DOC.ACCESS')
      ) {
        sortOrderCaption = 'Documents';
        caption = this.shortenCaptionOnMobileTo(sortOrderCaption, 'Doc');
        result.push({ sortOrderCaption, caption, routeName: 'callDocs', noTabData: 'DOCUMENT' });
      }

      if (
        (this.hasTabRight('TAB_EXPENSES') && this.$store.state.user.internal) ||
        this.$store.getters.userRight('EX.ACCESS') /* this.webAccessGroup.WAG_EXPENSES > 0 */
      ) {
        sortOrderCaption = 'Expenses';
        caption = this.shortenCaptionOnMobileTo(sortOrderCaption, 'Exp');
        result.push({
          sortOrderCaption,
          caption,
          routeName: 'callExpenses',
          routeParams: { callId: this.callId },
          noTabData: 'EXPENSE',
        });
      }

      if (
        this.hasTabRight('TAB_SERVICES') &&
        !this.$store.state.application.isCRM &&
        (this.$store.state.user.internal ||
          this.$store.getters.userRight('SER.ACCESS')) /* this.webAccessGroup.WAG_SERVICES > 0 */
      ) {
        sortOrderCaption = 'Services';
        caption = this.shortenCaptionOnMobileTo(sortOrderCaption, 'Serv');
        result.push({
          sortOrderCaption,
          caption,
          routeName: 'callServices',
          routeParams: { callId: this.callId },
          noTabData: 'SERVICES',
        });
      }

      if (
        this.hasTabRight('TAB_SOF') &&
        !this.$store.state.application.isCRM &&
        callCategoryLimit &&
        !callCategoryLimit.SOF &&
        (this.$store.state.user.internal ||
          this.$store.getters.userRight('SOF.ACCESS')) /* this.webAccessGroup.WAG_SOF > 0 */
      ) {
        sortOrderCaption = 'SOF';
        caption = this.shortenCaptionOnMobileTo(sortOrderCaption, 'sof');
        result.push({
          sortOrderCaption,
          caption,
          routeName: 'callSof',
          routeParams: { callId: this.callId },
          noTabData: 'SOF',
        });
      }

      if (
        this.hasTabRight('TAB_VISITORLIST') &&
        !this.$store.state.application.isCRM &&
        (this.$store.state.user.internal || this.$store.getters.userRight('VI.ACCESS'))
      ) {
        sortOrderCaption = 'Visitors';
        caption = this.shortenCaptionOnMobileTo(sortOrderCaption, 'Vstr');
        result.push({
          sortOrderCaption,
          caption,
          routeName: 'visitorList',
          routeParams: { callId: this.callId },
          noTabData: 'VISITOR',
        });
      }

      if (
        this.hasTabRight('TAB_ROUTE') &&
        !this.$store.state.application.isCRM &&
        (this.$store.state.user.internal || this.$store.getters.userRight('RO.ACCESS'))
      ) {
        sortOrderCaption = 'Voyage';
        caption = this.shortenCaptionOnMobileTo(sortOrderCaption, 'Voy');
        result.push({
          sortOrderCaption,
          caption,
          routeName: 'voyageList',
          routeParams: { callId: this.callId },
          noTabData: 'ROUTE',
        });
      }

      if (!this.$store.state.application.isCRM && this.$store.getters.userRight('AT.WEB_ACCESS')) {
        sortOrderCaption = 'Attendance';
        caption = this.shortenCaptionOnMobileTo(sortOrderCaption, 'Att');
        result.push({
          sortOrderCaption,
          caption,
          routeName: 'portcallAttendance',
          routeParams: { callId: this.callId },
          noTabData: 'ATTENDANCE',
        });
      }

      if (
        (this.hasTabRight('TAB_PROSPECTS') &&
          this.$store.state.user.internal)) {
        sortOrderCaption = 'Prospects';
        caption = this.shortenCaptionOnMobileTo(sortOrderCaption, 'Prosp');
        result.push({
          sortOrderCaption,
          caption,
          routeName: 'prospectsList',
          routeParams: { callId: this.callId },
          noTabData: 'PROSPECTS',
        });
      }

      if (this.hasTabRight('TAB_DASHBOARD') && this.$store.state.user.internal && this.tabHasSqlScripts.PORTCALL > 0) {
        sortOrderCaption = 'Dashboard';
        caption = this.shortenCaptionOnMobileTo(sortOrderCaption, 'Dash');
        result.push({ sortOrderCaption, caption, routeName: 'portcallDashboard', routeParams: {} });
      }
      if (
        (this.hasTabRight('TAB_EMAIL') && this.$store.state.user.internal) ||
        this.$store.getters.userRight('EM.ACCESS')
      ) {
        sortOrderCaption = 'E-mail';
        caption = this.shortenCaptionOnMobileTo(sortOrderCaption, 'Mail');
        result.push({ sortOrderCaption, caption, routeName: 'portcallEmailList', noTabData: 'MAIL_SENT' });
      }

      // Sort and arrange the sab sequence to be same as Classic
      result.forEach((tab) => {
        const order = this.$store.state.helper.tabSequence.find((seq) => tab.sortOrderCaption === seq.FSE_FIELDCAPTION);
        if (order != undefined) {
          // eslint-disable-next-line no-param-reassign
          tab.tabOrder = order.FSE_SORTORDER;
        } else {
          // eslint-disable-next-line no-param-reassign
          tab.tabOrder = tab.routeName != 'callDetails' ? 999 : 0;
        }
      });
      result.sort((a, b) => parseInt(a.tabOrder, 10) - parseInt(b.tabOrder, 10));

      return result;
    },
    webAccessGroup() {
      let result = this.$store.state.helper.webAccessGroups.find(
        (item) => item.WAG_NAME == this.callDetails.webAccessGroup
      );
      if (!result) {
        result = {};
      }
      return result;
    },
  },

  created() {
    window.messageBus.$on('toolbar_prev_clicked:portcall', () => {
      if (this.$store.state.whiteboard.whiteBoardData) {
        let idx = this.$store.state.whiteboard.whiteBoardData.findIndex((item) => item.ID == this.callId);
        if (idx >= 0) {
          idx -= 1;
          if (idx < 0) {
            idx = this.$store.state.whiteboard.whiteBoardData.length - 1;
          }
          const newCallId = this.$store.state.whiteboard.whiteBoardData[idx].ID;
          this.$router.push({ name: this.$route.name, params: { callId: newCallId } });
        }
      }
    });
    window.messageBus.$on('toolbar_next_clicked:portcall', () => {
      if (this.$store.state.whiteboard.whiteBoardData) {
        let idx = this.$store.state.whiteboard.whiteBoardData.findIndex((item) => item.ID == this.callId);
        if (idx >= 0) {
          idx += 1;
          if (idx >= this.$store.state.whiteboard.whiteBoardData.length) {
            idx = 0;
          }
          const newCallId = this.$store.state.whiteboard.whiteBoardData[idx].ID;
          this.$router.push({ name: this.$route.name, params: { callId: newCallId } });
        }
      }
    });
  },

  activated() {
    if (this.callDetails.vessel) {
      this.$store.commit(
        'setToolbarTitle',
        `${this.callDetails.vessel.VESSEL_PREFIX} ${this.callDetails.vessel.NAME} (${this.callDetails.PORTCALL_NUMBER})`
      );
    }
    if (this.traversableListItems.length != 0) {
      this.$store.commit('setTraversableList', this.traversableListItems);
      this.$store.commit('setTraversableListCounter', true);
      this.$store.commit('setToolbarNavBtns', { name: 'portcall', show: true });
    } else if (this.$store.state.application.debugMode) {
      console.log('No whiteboard data.');
    }
  },

  deactivated() {
    this.$store.commit('setToolbarTitle', '');
    this.$store.commit('setToolbarNavBtns', { name: '', show: false });
    this.$store.commit('setTraversableList', []);
    this.$store.commit('setTraversableListCounter', false);
  },

  watch: {
    callDetails(callData) {
      if (callData.vessel) {
        this.$store.commit(
          'setToolbarTitle',
          `${callData.vessel.VESSEL_PREFIX} ${callData.vessel.NAME} (${callData.PORTCALL_NUMBER})`
        );
      }
      const idx = this.traversableListItems.findIndex((item) => item.ID == this.callDetails.ID);
      this.$store.commit('setTraversableListIndex', idx);
    },
    callId: {
      handler(id) {
        if (this.callDetails.ID !== id && id) {
          this.$store.commit('clearCall');
          this.loadPortcallDetails(id);
        }
      },
      immediate: true,
    },
  },

  methods: {
    hasTabRight(tabRight) {
      tabRight.trim();
      let result = 0;
      const portcallAgency = this.callDetails.SETUP_ID; // Agency to which the current portcall belongs.
      if (portcallAgency) {
        const idx = this.$store.state.agencySettings.findIndex((item) => item.ID == portcallAgency); // Find agencySettings for portcallAgency
        if (idx >= 0) {
          const agency = this.$store.state.agencySettings[idx]; // Store all the agency settings.
          result = agency[tabRight]; // Check the agency setting for the given argument.
        }
      }
      return result == 1;
    },
    loadPortcallDetails(callId) {
      if (callId) {
        this.loadingPortCall = true;
        if (this.$store.state.portcall.lastCallsUsed.length <= 0) {
          // prevent last used portcalls from being overwritten on application load.
          this.$store.dispatch('getLastPortCalls').then(() => {
            this.$store.dispatch('getPortcallDetails', callId).then(() => {this.loadingPortCall = false;});
          });
        } else {
          this.$store.dispatch('getPortcallDetails', callId).then(() => {this.loadingPortCall = false;});
        }
        this.$store.dispatch('getHotlist', callId);
        this.$store.dispatch('getSofTitles', callId);
      }
    },

    shortenCaptionOnMobileTo(orgValue, shortValue) {
      if (this.$vuetify.breakpoint.xsOnly) {
        return shortValue;
      }
      return orgValue;
    },
  },
};
</script>

<style scoped></style>
