export const dashboardGetters = {
  getDashboardReports:
    (state, getters) =>
    ({ location, dashboardGroupName }) => {
      if (!location) return console.error('location was not provided');
      let reports = [];
      if (dashboardGroupName) {
        // Dashboard groups.
        const idx = state[location].dashboardGroups.findIndex(
          (item) => item.NAME.trim().replace(' ', '').toLowerCase() === dashboardGroupName
        );
        if (idx >= 0) {
          reports = [...state[location].dashboardGroups[idx].reports] || [];
        }
      } else if (state[location] && state[location].reports) {
        reports = [...state[location].reports];
      }
      if (reports.length > 0) {
        const sorting = getters.getDashboardSorting({
          location,
          dashboardGroupName,
        });
        reports.sort((a, b) => {
          let ixA = sorting.findIndex((item) => item == a.reportName);
          let ixB = sorting.findIndex((item) => item == b.reportName);
          if (ixA < -10000) {
            ixA = 10000;
          }
          if (ixB < -10000) {
            ixB = 10000;
          }
          return ixA - ixB;
        });
      }
      return reports; // for embedded dashboards
    },

  getEmbeddedDashboardSorting:
    (state) =>
    ({ location }) => {
      if (!location) return console.error('location was not provided');
      const result = {
        user: [],
        default: [],
        initial: [],
      };
      if (state[location] && state[location].sorting) {
        result.initial = [...state[location].sorting.initial] || [];
        result.user = [...state[location].sorting.user] || [];
        result.default = [...state[location].sorting.default] || [];
      }
      return result;
    },

  getDashboardSorting:
    (state) =>
    ({ location, dashboardGroupName, sortingName }) => {
      if (!location) return console.error('location was not provided');
      const result = {
        user: [],
        default: [],
        initial: [],
      };
      if (dashboardGroupName) {
        // dashboard groups
        const idx = state[location].dashboardGroups.findIndex(
          (group) => group.NAME.trim().replace(' ', '').toLowerCase() === dashboardGroupName
        );
        if (idx >= 0) {
          result.initial = [...state[location].dashboardGroups[idx].sorting.initial] || [];
          result.user = [...state[location].dashboardGroups[idx].sorting.user] || [];
          result.default = [...state[location].dashboardGroups[idx].sorting.default] || [];
        }
      } else if (state[location] && state[location].sorting) {
        // embedded dashboards
        result.initial = [...state[location].sorting.initial] || [];
        result.user = [...state[location].sorting.user] || [];
        result.default = [state[location].sorting.default] || [];
      }
      if (!sortingName) {
        // return the first occurance of sorting. User > Default
        if (result.user.length > 0) {
          return result.user;
        }
        if (result.default.length > 0) {
          return result.default;
        }
        return result.initial;
      }
      return result;
    },
  getChartSettings:
    (state) =>
    ({ location, dashboardGroupName, reportName, getSettingsName }) => {
      if (!location) console.error('location was not provided');
      const result = {
        initialSettings: {},
        userSettings: {},
        defaultSettings: {},
      };
      if (dashboardGroupName) {
        // Dashboard groups.
        const idx = state[location].dashboardGroups.findIndex(
          (group) => group.NAME.trim().replace(' ', '').toLowerCase() === dashboardGroupName
        );
        if (idx >= 0) {
          const idx2 = state[location].dashboardGroups[idx].reports.findIndex(
            (report) => report.reportName === reportName
          );
          if (idx2 >= 0) {
            result.initialSettings = state[location].dashboardGroups[idx].reports[idx2].settings;
            result.userSettings = state[location].dashboardGroups[idx].reports[idx2].userSettings;
            result.defaultSettings = state[location].dashboardGroups[idx].reports[idx2].defaultSettings;
          }
        }
      } else {
        // Embedded dashboards.
        const idx = state[location].reports.findIndex((report) => report.reportName === reportName);
        if (idx >= 0) {
          result.initialSettings = state[location].reports[idx].settings;
          result.userSettings = state[location].reports[idx].userSettings;
          result.defaultSettings = state[location].reports[idx].defaultSettings;
        }
      }

      if (!getSettingsName) {
        if (
          Object.keys(result.userSettings).length > 0 &&
          Object.getPrototypeOf(result.userSettings) === Object.prototype
        ) {
          return result.userSettings;
        }
        if (
          Object.keys(result.defaultSettings).length > 0 &&
          Object.getPrototypeOf(result.defaultSettings) === Object.prototype
        ) {
          return result.defaultSettings;
        }
        return result.initialSettings;
      }
      // !dashboardGroupName goes here...
      return result;
    },

  getChartData:
    (state) =>
    ({ reportName }) => {
      let result;
      const idx = state.reportData.findIndex((item) => item.reportName === reportName);
      if (idx >= 0) {
        result = state.reportData[idx].reportData;
      }
      return result;
    },

  dashboardSettingsRetrieved:
    (state) =>
    ({ location, dashboardGroupName }) => {
      if (!location) console.error('location was not provided');
      let result = false;
      if (dashboardGroupName) {
        const idx = state[location].dashboardGroups.findIndex(
          (group) => group.NAME.trim().replace(' ', '').toLowerCase() === dashboardGroupName
        );
        if (idx >= 0) {
          result = state[location].dashboardGroups[idx].settingsRetrieved;
        }
      } else {
        result = state[location].settingsRetrieved;
      }
      return result === true;
    },
};
