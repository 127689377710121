<template>
  <div>
    <!-- <v-window v-model="activeWindowIndex" @change="ativeWindowChanged">
        <v-window-item><vessel-details v-if="$store.state.application.appCriticalInitFinished" v-model="vessel"></vessel-details></v-window-item>
        <v-window-item><documents location="vessel" :parent-id="vessel.ID" /></v-window-item>
        <v-window-item><vessel-history v-if="vessel" :value="vessel"></vessel-history></v-window-item>
      </v-window> -->

    <gat-tab :items="tabs" :show-in-toolbar="true" />
    <keep-alive>
      <router-view :value="vessel" location="vessel" :parentId="vesselId"></router-view>
    </keep-alive>
  </div>
</template>

<script>
import GatUtils from '@/app-components/GatUtils';
// eslint-disable-next-line import/no-cycle
import { apiGet } from '@/store/api';

export default {
  name: 'vessel',
  props: ['vesselId'],
  components: {},
  data() {
    return {
      vessel: null,
      activeWindowIndex: 0,
      tabItems: [{ caption: 'Details' }, { caption: 'Documents' }, { caption: 'History' }],
    };
  },

  computed: {
    tabHasSqlScripts() {
      return this.$store.state.helper.tabHasSqlScripts;
    },
    tabs() {
      const result = [];
      result.push({ caption: 'Details', routeName: 'vesselDetails' });
      result.push({ caption: 'History', routeName: 'vesselHistory', routeParams: {} });
      result.push({ caption: 'Documents', routeName: 'vesselDocs', routeParams: {} });
      if (this.$store.state.user.internal && this.tabHasSqlScripts.VESSEL > 0) {
        result.push({ caption: 'Dashboard', routeName: 'vesselDashboard', routeParams: {} });
      }
      if (this.$store.state.user.internal || this.$store.getters.userRight('EM.ACCESS')) {
        result.push({ caption: 'E-Mail', routeName: 'vesselEmailList' });
      }
      return result;
    },
  },

  watch: {
    vesselId(id) {
      if (id && id !== this.vessel.id) {
        this.loadVessel(id);
      }
    },
  },

  activated() {
    if (this.vessel) {
      this.$store.commit('setToolbarTitle', `${this.vessel.VESSEL_PREFIX} ${this.vessel.NAME}`);
    }
  },

  deactivated() {
    this.$store.commit('setToolbarTitle', '');
  },

  created() {
    window.messageBus.$on('vesselUpdate', (param) => {
      this.vessel = GatUtils.makeCopyViaJson(param);
    });

    if (this.vesselId) {
      this.loadVessel(this.vesselId);
    }
  },

  methods: {
    loadVessel() {
      apiGet(`/vessel/${this.vesselId}`).then((data) => {
        const newData = data;
        if (!newData.VESSEL_PREFIX) {
          newData.VESSEL_PREFIX = '';
        }
        this.vessel = newData;
        this.$store.commit('setToolbarTitle', `${this.vessel.VESSEL_PREFIX} ${this.vessel.NAME}`);
      });
    },
  },
};
</script>

<style scoped></style>
