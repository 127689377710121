<template>
  <div class="d-flex flex-column">
    <search-panel
      :autofocus="true"
      v-model="searchParams"
      :fields="searchFields"
      showMaxRows="true"
      :maxRows="maxRows"
      main-action
      :submitBtn="true" />
    <gat-grid
      class="elevation-0"
      :items="vessels"
      :columns="columns"
      title="Vessels"
      keyField="ID"
      :rowclass="this.getRowClass"
      :fixedheader="true"
      height="calc(100vh - 200px)"
      @row-clicked="onRowClick">
      <template slot="cell" slot-scope="props">
        <div v-if="props.column.field == `NAME`">
          <a class="gridLink" @click.stop="goToVessel(props.row.ID)">{{ props.row.NAME }}</a>
        </div>
      </template>
      <template slot="btns">
        <wm-button-group
          class="right mr-1"
          :buttons="[{ btnType: 'ADD', userRight: 'VE.EDIT_AND_NEW', mainAction: false }]"
          @addClick="addVessel" />
        <!-- <v-btn
          :disabled="!$store.getters.userRight('VE.EDIT_AND_NEW')"
          small
          outlined
          color="primary"
          class="right mr-2"
          @click="addVessel">
          <v-icon small>add</v-icon>Add
      </v-btn> -->
      </template>
    </gat-grid>

    <VesselNetSearch
      class="mt-3"
      v-show="noOfVesselFoundOnline > 0"
      :searchText="searchParams.search"
      @vessel-import="vesselImport"
      v-model="noOfVesselFoundOnline">
    </VesselNetSearch>
  </div>
</template>

<script>
import SearchPanel from '@/app-components/SearchPanel.vue';
import GatUtils from '@/app-components/GatUtils';
// eslint-disable-next-line import/no-cycle
import { apiPost } from '@/store/api';
import VesselNetSearch from './VesselNetSearch.vue';

export default {
  name: 'VesselList',
  props: ['search', 'maxRows', 'query'],
  components: { SearchPanel, VesselNetSearch },
  data() {
    return {
      vessels: [],
      noOfVesselFoundOnline: 0,
      vesselToImport: {},
      searchParams: {},
      searchFields: [{ name: 'search', caption: 'Search', size: 'm', value: null, maxChars: 100 }],
      columns: [
        {
          header: 'Name',
          field: 'NAME',
          width: 'auto',
          calculated(row) {
            return row.NAME;
          },
        },
        { header: 'Call sign', field: 'CALLSIGN', hideOnPhone: false },
        { header: 'IMO no', field: 'LLOYDSNR', hideOnPhone: true },
        { header: 'DWT', field: 'DWT' },
        { header: 'GT', field: 'GT' },
        { header: 'LOA', field: 'LOA', hideOnPhone: true },
        { header: 'Beam', field: 'BEAM', hideOnPhone: true },
        { header: 'Homeport', field: 'HOMEPORT', hideOnPhone: true },
        { header: 'Flag', field: 'FLAG', hideOnPhone: true },
      ],
    };
  },

  created() {
    window.messageBus.$on('vesselDelete', (param) => {
      const idx = this.vessels.findIndex((item) => item.ID == param);

      if (idx >= 0) {
        this.vessels.splice(idx, 1);
      }
    });

    //  if(this.search){
    //     this.searchForVessels({"maxRows":this.maxRows,"search":this.search})
    //  }
    this.searchParams = this.query;
  },

  // activated(){
  //   this.$store.commit('setToolbarTitle','');
  //   this.$store.commit('setToolbarTabs',undefined);
  // },

  watch: {
    searchParams(newValue) {
      if (newValue) {
        this.searchForVessels(newValue);
      }
    },
    query(newValue) {
      this.searchParams = newValue;
    },
  },

  computed: {
    vCardWarningDescriptionImo() {
      if (this.duplicateVesselImoNumbersFound.length < 1) {
        return `A vessel with the same IMO number (${this.vesselToImport.LLOYDSNR}) already exists:`;
      }
      return `${this.duplicateVesselImoNumbersFound.length} vessels with the same IMO number (${this.vesselToImport.LLOYDSNR}) already exists:`;
    },
    vCardWarningDescriptionName() {
      if (this.duplicateVesselNamesFound.length < 1) {
        return `A vessel with the same Name (${this.vesselToImport.NAME}) already exists:`;
      }
      return `${this.duplicateVesselNamesFound.length} vessels with the same name (${this.vesselToImport.NAME}) already exists:`;
    },
  },
  activated() {
    if (this.vessels.length >= 1) {
      this.$store.commit('setTraversableList', this.vessels);
    }
  },
  deactivated() {
    // console.log(this.$route.name);
    if (this.$route.name != 'vesselDetails') {
      this.$store.commit('setTraversableList', []);
    }
  },

  methods: {
    onRowClick(rowItem) {
      this.goToVessel(rowItem.ID);
    },
    goToVessel(id) {
      this.$router.push({ name: 'vesselDetails', params: { vesselId: +id } });
    },
    addVessel() {
      this.$router.push({ name: 'VesselNew', params: { value: { ID: 0, VESSEL_PREFIX: 'M/V' } } });
    },
    getRowClass(rowItem) {
      let result = '';
      if (rowItem.INACTIVE) {
        result += 'ignored';
      }
      /* if (rowItem.CHECKED) {
                    result += " checked"
                } */
      return result;
    },

    searchForVessels(params) {
      if (params.search) {
        this.$router.push(GatUtils.makeUrlParams('vessels', params));
        apiPost('/vessel/search/', params).then((data) => {
          this.vessels = data;
          this.$store.commit('setTraversableList', this.vessels);
        });
      }
    },

    vesselImport(vesselToImport) {
      // eslint-disable-next-line no-param-reassign
      vesselToImport.ID = 0;
      // eslint-disable-next-line no-param-reassign
      vesselToImport.VESSEL_PREFIX = 'M/V';
      this.$router.push({ name: 'VesselNew', params: { value: vesselToImport, fromimport: true } });
    },
  },
};
</script>

<style scoped lang="scss">
ul {
  list-style-type: none;
  padding: 0;
}
</style>
