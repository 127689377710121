<template>
  <div style="display: contents">
    <gat-flex :size="radioSize || 'xl2 sm6 md4 xs12'" :class="$vuetify.breakpoint.xsOnly ? 'mb-2' : ''">
      <gat-field-spacer style="padding-top: 0px !important">
        <v-label> <span style="font-size: 14px !important">Responsible</span></v-label>
        <v-radio-group
          v-model="teamIsResponsibleValue"
          :row="true"
          class="d-flex flex-column mt-0 pt-0"
          hide-details
          :readonly="readonly">
          <div class="d-flex flex-grow-1">
            <v-radio label="Person" v-bind:value="false" v-on:click.stop></v-radio>
            <v-radio label="Team" v-bind:value="true" v-on:click.stop :disabled="teams.length === 0"></v-radio>
          </div>
        </v-radio-group>
      </gat-field-spacer>
    </gat-flex>
    <gat-select
      v-if="!teamIsResponsible"
      label="Person in charge"
      :size="selectSize || 'xl2  sm6 md4 xs12'"
      v-model="personInChargeValue"
      :items="users"
      textFieldName="navn"
      codeFieldName="KODE"
      hide-details
      :readonly="readonly" />
    <gat-select
      v-if="teamIsResponsible && teams.length > 0"
      label="Team in charge"
      :size="selectSize || 'xl2 sm6 md4 xs6'"
      v-model="teamInChargeValue"
      :items="teams"
      :readonly="readonly" />
  </div>
</template>

<script>
export default {
  name: 'ResponsibleRadioInput',
  props: {
    /** The portcall setup ID, needed to filter teams. */
    portcallSetupId: {
      type: [Number, String],
    },
    teamIsResponsible: {
      type: Boolean,
      default: false,
      required: true,
    },
    personInCharge: {
      type: String,
      default: null,
    },
    teamInCharge: {
      type: Number,
      default: null,
    },
    radioSize: {
      type: String,
    },
    selectSize: {
      type: String,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      teamIsResponsibleValue: false,
      personInChargeValue: undefined,
      teamInChargeValue: undefined,
    };
  },
  watch: {
    // Team responsible
    teamIsResponsible: {
      handler(val) {
        this.teamIsResponsibleValue = val;
      },
      immidiate: true,
    },
    teamIsResponsibleValue: {
      handler(val) {
        this.$emit('update:teamIsResponsible', val);
      },
      immediate: true,
    },
    // Person in charge
    personInCharge(val) {
      this.personInChargeValue = val;
    },
    personInChargeValue(val) {
      this.$emit('update:personInCharge', val);
    },
    // Team in charge
    teamInCharge(val) {
      this.teamInChargeValue = val;
    },
    teamInChargeValue(val) {
      this.$emit('update:teamInCharge', val);
    },
  },
  computed: {
    users() {
      const u = this.$store.state.helper.users;
      const result = u.filter((r) => !r.KODE.toUpperCase().startsWith('GS_'));
      return result;
    },
    teams() {
      // eslint-disable-next-line array-callback-return, consistent-return
      return this.$store.getters.allTeams.filter((team) => {
        if (team.AF_AGENCY_ID === this.portcallSetupId) return team;
        if (team.AF_AGENCY_ID === null) {
          return team;
        }
      });
    },
  },
};
</script>
