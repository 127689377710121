<template>
    <div class="pb-6 mb-6">
        <gat-dialog v-model="dialogVisible" :cancel-button-disabled="isSaving" :ok-button-disabled="isSaving"
            cancelButtonCaption="CANCEL" okButtonCaption="OK" :fullscreen="isPhone" width="1000" :auto-close="false"
            @okClick="duplicateOkClicked" @cancelClick="close" :okButtonDisabled="!canCreatePortCall">
            <span v-if="title" class="text-h6 grey--text text--darken-1">{{ this.title }} {{ this.portcall.PORTCALL_NUMBER
            }} ({{ this.originalVessel ? this.originalVessel.NAME : '' }})</span>
            <gat-form :title="this.title" v-model="isDuplicateValid" ref="form" :readonly="!canCreatePortCall"
                :is-loading="isSaving">
                <gat-sub-group size="md12 xs12">
                    <gat-select size="md6 xs12" v-model="portcall.SETUP_ID" :items="agencyItems" label="Agency"
                        :required="true" />
                    <gat-vessel-select size="md6 xs12" v-model="portcall.VESSEL_ID" label="Vessel" :required="true" />
                </gat-sub-group>
                <gat-sub-group v-if="!fromTemplate" title="Mark the items that should be duplicated to the new port call"
                    size="xs12 sm12">
                    <GatEdit :disabled="!canDuplicateCargo" checkbox label="Cargo" v-model="chkCargo" size="xs12 sm6 md4" />
                    <GatEdit :disabled="!canDuplicateChecklist" checkbox label="Checklist" v-model="chkCheclist"
                        size="xs12 sm6 md4" />
                    <GatEdit :disabled="!canDuplicateDynamicFields" checkbox label="Customer defined fields"
                        v-model="chkDynaFields" size="xs12 sm6 md4" />
                    <GatEdit :disabled="!canDuplicateExpenses" checkbox label="Expenses" v-model="chkExpenses"
                        size="xs12 sm6 md4" />
                    <GatEdit :disabled="!canDuplicateHotlist" checkbox label="Connected clients" v-model="chkHotlist"
                        size="xs12 sm6 md4" />
                    <GatEdit :disabled="!canDuplicateProforma" checkbox label="Proforma" v-model="chkProforma"
                        size="xs12 sm6 md4" />
                    <GatEdit :disabled="!canDuplicateProspects" checkbox label="Prospects" v-model="chkProspects"
                        size="xs12 sm6 md4" />
                    <GatEdit :disabled="!canDuplicateQuay" checkbox label="Berth & anchorage" v-model="chkQuay"
                        size="xs12 sm6 md4" />
                    <GatEdit :disabled="!canDuplicateRoute" checkbox label="Voyage" v-model="chkRoute"
                        size="xs12 sm6 md4" />
                    <GatEdit :disabled="!canDuplicateSof" checkbox label="Statement of facts" v-model="chkSOF"
                        size="xs12 sm6 md4" />
                    <GatEdit :disabled="!canDuplicateServices" checkbox label="Services" v-model="chkServices"
                        size="xs12 sm6 md4" />
                </gat-sub-group>
                <gat-sub-group title="Port call number" size="xs12" v-if="showGenerateCallNumber">
                    <GatEdit label="Generate port call number" v-model="chkGeneratePortCallNumber" toggle size="xs6" />
                    <GatEdit v-show="!chkGeneratePortCallNumber" label="New port call number"
                        v-model="portcall.PORTCALL_NUMBER" size="xs6" :maxChars="25" />
                </gat-sub-group>
            </gat-form>
            <v-alert v-if="this.errorMessage" color="orange darken-1" icon="mdi-flag" dense text outlined>
                <span class="font-weight-medium">{{ this.errorMessage }}</span><br />
            </v-alert>
        </gat-dialog>
    </div>
</template>
  
<script>
// eslint-disable-next-line import/no-cycle
import moment from 'moment';
import { apiGet, apiPut } from '@/store/api';
import GatVesselSelect from '../../app-components/GatVesselSelect.vue';

export default {
    name: 'DuplicatePortCall',
    props: ['portcall', 'showForm', 'duplicateParams', 'fromTemplate'],
    components: {
        GatVesselSelect,
    },
    data() {
        return {
            isSaving: false,
            errorMessage: null,
            originalVessel: null,
            isDuplicateValid: false,
            dialogVisible: this.showForm,
            chkCargo: this.duplicateParams?.chkCargo?.toUpperCase() === "CHECKED",
            chkCheclist: this.duplicateParams?.chkCheclist?.toUpperCase() === "CHECKED",
            chkDynaFields: this.duplicateParams?.chkDynaFields?.toUpperCase() === "CHECKED",
            chkExpenses: this.duplicateParams?.chkExpenses?.toUpperCase() === "CHECKED",
            chkHotlist: this.duplicateParams?.chkHotlist?.toUpperCase() === "CHECKED",
            chkProforma: this.duplicateParams?.chkProforma?.toUpperCase() === "CHECKED",
            chkProspects: this.duplicateParams?.chkProspects?.toUpperCase() === "CHECKED",
            chkQuay: this.duplicateParams?.chkQuay?.toUpperCase() === "CHECKED",
            chkRoute: this.duplicateParams?.chkRoute?.toUpperCase() === "CHECKED",
            chkSOF: this.duplicateParams?.chkSOF?.toUpperCase() === "CHECKED",
            chkServices: this.duplicateParams?.chkServices?.toUpperCase() === "CHECKED",
            chkGeneratePortCallNumber: true,
            title: this.fromTemplate ? "Create new port call from template: " : "Duplicate port call: ",
        };
    },

    created() {
    },

    mounted() {
        this.isSaving = false;
        this.getVessel();
    },

    watch: {
        showForm(newValue) {
            this.dialogVisible = newValue;
        },
        chkCargo(newValue) {
            this.saveDuplicatePortcallParameters('chkCargo', newValue)
        },
        chkCheclist(newValue) {
            this.saveDuplicatePortcallParameters('chkCheclist', newValue)
        },
        chkDynaFields(newValue) {
            this.saveDuplicatePortcallParameters('chkDynaFields', newValue)
        },
        chkExpenses(newValue) {
            this.saveDuplicatePortcallParameters('chkExpenses', newValue)
        },
        chkHotlist(newValue) {
            this.saveDuplicatePortcallParameters('chkHotlist', newValue)
        },
        chkProforma(newValue) {
            this.saveDuplicatePortcallParameters('chkProforma', newValue)
        },
        chkProspects(newValue) {
            this.saveDuplicatePortcallParameters('chkProspects', newValue)
        },
        chkQuay(newValue) {
            this.saveDuplicatePortcallParameters('chkQuay', newValue)
        },
        chkRoute(newValue) {
            this.saveDuplicatePortcallParameters('chkRoute', newValue)
        },
        chkSOF(newValue) {
            this.saveDuplicatePortcallParameters('chkSOF', newValue)
        },
        chkServices(newValue) {
            this.saveDuplicatePortcallParameters('chkServices', newValue)
        },
    },

    computed: {
        isPhone() {
            return this.$vuetify.breakpoint.xsOnly;
        },
        canCreatePortCall() {
            return !this.saving && this.$store.getters.userRight('P.NEW_AND_EDIT') && this.$store.state.user.internal;
        },
        canDuplicateQuay() {
            return this.$store.getters.userRight('Q.NEW_AND_EDIT') && this.$store.state.user.internal;
        },
        canDuplicateSof() {
            return this.$store.getters.userRight('SOF.NEW_AND_EDIT') && this.$store.state.user.internal;
        },
        canDuplicateCargo() {
            return this.$store.getters.userRight('CA.NEW_AND_EDIT') && this.$store.state.user.internal;
        },
        canDuplicateRoute() {
            return this.$store.getters.userRight('RO.NEW_AND_EDIT') && this.$store.state.user.internal;
        },
        canDuplicateDynamicFields() {
            return this.$store.getters.userRight('UD.EDIT') && this.$store.state.user.internal;
        },
        canDuplicateHotlist() {
            return this.$store.getters.userRight('H.EDIT') && this.$store.state.user.internal;
        },
        canDuplicateChecklist() {
            return this.$store.getters.userRight('CH.NEW_AND_EDIT') && this.$store.state.user.internal;
        },
        canDuplicateProforma() {
            return (this.$store.getters.userRight('PR.NEW') || this.$store.getters.userRight('PR.NEW_FROM_PORTCALL')) && this.$store.state.user.internal;
        },
        canDuplicateExpenses() {
            return this.$store.getters.userRight('EX.NEW') && this.$store.state.user.internal;
        },
        canDuplicateServices() {
            return this.$store.getters.userRight('SER.NEW_AND_EDIT') && this.$store.state.user.internal;
        },
        canDuplicateProspects() {
            return this.$store.getters.userRight('PRO.NEW') && this.$store.state.user.internal;
        },

        showGenerateCallNumber() {
            return this.$store.state.globalSettings.SGL_PC_HIDE_GENERATE_PCNO_NEW_PC !== 1;
        },

        agencyItems() {
            const agency = this.$store.state.agencySettings;
            const result = agency.map((obj) => {
                const newObj = {};
                if (obj.ID) {
                    newObj.text = obj.GROUP_NAME;
                    newObj.value = obj.ID;
                }
                return newObj;
            });
            return result;
        },
    },

    methods: {
        async getVessel() {
            apiGet(`helper/vessel/${this.portcall.VESSEL_ID}`).then((data) => {
                this.originalVessel = data.length > 0 ? data[0] : null;
            });
        },
        async saveDuplicatePortcallParameters(key, value) {
            this.isSaving = true;
            await apiPut('portcall/duplicate-portcall-parameters', { 'KEY_TEXT': key, 'VALUE_TEXT': value });
            this.isSaving = false;
        },

        async duplicateOkClicked() {
            try {
                this.errorMessage = null;
                this.$refs.form.validate();
                if (this.isDuplicateValid) {
                    this.isSaving = true;
                    const isoDate = moment(new Date()).format('yyyy-MM-DDTHH:mm:ss')
                    const { SETUP_ID, VESSEL_ID, quayId, PORTCALL_NUMBER } = this.portcall;

                    const portCallResult = await apiPut('portcall/duplicatepc', {
                        ID: this.portcall.ID,
                        SETUP_ID, // agency
                        VESSEL_ID,
                        QUAY_ID: quayId,
                        PORTCALL_NUMBER,
                        HISTORY_TIME: isoDate,
                        chkCargo: this.fromTemplate || (this.canDuplicateCargo && this.chkCargo),
                        chkCheclist: this.fromTemplate || (this.canDuplicateChecklist && this.chkCheclist),
                        chkDynaFields: this.fromTemplate || (this.canDuplicateDynamicFields && this.chkDynaFields),
                        chkExpenses: this.fromTemplate || (this.canDuplicateExpenses && this.chkExpenses),
                        chkHotlist: this.fromTemplate || (this.canDuplicateHotlist && this.chkHotlist), // CONNECTED CLIENTS
                        chkProforma: this.fromTemplate || (this.canDuplicateProforma && this.chkProforma),
                        chkProspects: this.fromTemplate || (this.canDuplicateProspects && this.chkProspects),
                        chkQuay: this.fromTemplate || (this.canDuplicateQuay && this.chkQuay),
                        chkRoute: this.fromTemplate || (this.canDuplicateRoute && this.chkRoute),
                        chkSOF: this.fromTemplate || (this.canDuplicateSof && this.chkSOF),
                        chkServices: this.fromTemplate || (this.canDuplicateServices && this.chkServices),
                        chkGeneratePortCallNumber: this.chkGeneratePortCallNumber,
                    })

                    this.isSaving = false;
                    // Find port call promise result
                    if (portCallResult) {
                        // Do we have an error?
                        if (portCallResult.resultCategory > 2) {
                            this.errorMessage = portCallResult.message;
                        } else {
                            this.close();
                            const newId = portCallResult.payload;
                            this.$store.dispatch('getBerthedPortcalls'); // Update attendance berthed portcalls select input.
                            // Update store with new port call details...
                            this.$store.commit('clearCall');
                            this.$store.dispatch('getPortcallDetails', newId);
                            this.$store.commit('setWhiteBoardDataIsDirty', true);
                            if (portCallResult.resultCategory === 1 && newId) {
                                this.$router.push(`/call/${newId}`);
                            }
                        }
                    }
                } else {
                    this.close();
                }
            } catch (err) {
                this.isSaving = false;
                this.errorMessage = err;
            }
        },

        close() {
            this.isSaving = false;
            this.$emit('close');
        },
    },
};
</script>
  
<style lang="scss" scoped>
.ais {
    width: calc(100% - 250px);
}

@media only screen and (max-width: 600px) {
    .ais {
        width: 100%;
    }
}
</style>
  