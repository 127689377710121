<template>
  <div class="mb-12">
    <gat-form
      v-model="isValid"
      ref="form"
      :readonly="!this.$store.getters.userRight('CH.NEW_AND_EDIT')"
      :isLoading="isLoading">
      <gat-group title="Checklist" :collapsed="isNew ? !expandedIfNew : false">
        <v-layout wrap>
          <gat-edit label="Name" v-model="item.ITEM" size="xl3 lg4 md6" :maxChars="500" :required="true" />
          <gat-select label="Type" v-model="item.TYPE" :items="$store.state.constants.checklistTypes" size="xl2 lg3" />
        </v-layout>
        <v-layout wrap>
          <gat-edit label="Grouping" v-model="item.GROUPING" :maxChars="500" size="xl2 lg4 md6 xs12" />
          <gat-edit label="Status text" v-model="item.STATUS" :maxChars="25" size="xl2 lg2 md4 xs8" />
          <gat-edit label="Sort order" v-model="item.CHECKLIST_NUMBER" :maxChars="20" size="xl1 lg1 md2 xs4" />
        </v-layout>
        <v-layout wrap> </v-layout>
        <v-layout wrap>
          <gat-edit label="Comments" v-model="item.COMMENTS" :maxChars="250" size="xl5 lg4" :rows="2" />
        </v-layout>
      </gat-group>
      <gat-group title="Checked status" :collapsed="isNew ? !expandedIfNew : false">
        <v-layout wrap>
          <gat-edit
            label="Checked out"
            v-model="item.CHECKED"
            checkbox
            :checkedValue="1"
            :uncheckedValue="0"
            size="xl2 lg2 md2" />
          <gat-edit
            label="Ignore"
            v-model="item.IGNORE_ITEM"
            :checkedValue="1"
            :uncheckedValue="0"
            checkbox
            size="xl2 lg2 md2" />
        </v-layout>
        <v-layout wrap>
          <gat-edit
            v-if="item.CHECKED"
            label="Checked date"
            v-model="item.CHECKED_DATE"
            dateTimeEdit
            :disabled="!item.CHECKED"
            size="xl2 lg3 md4" />
          <gat-edit
            v-if="item.CHECKED"
            label="Checked by"
            v-model="item.CHECKED_BY"
            :maxChars="100"
            :disabled="!item.CHECKED"
            size="xl2 lg2 md4" />
        </v-layout>
      </gat-group>
      <gat-group title="Reminder" :collapsed="isNew ? !expandedIfNew : false">
        <v-layout wrap>
          <gat-select v-model="item.REMINDER" :items="reminder" size="xl5 lg5 md5 sm6" />
        </v-layout>
        <v-layout wrap>
          <gat-edit
            v-if="item.REMINDER == 10"
            dateTimeEdit
            :label="!item.REM_DATETIME ? 'Reminder date' : 'Remind me on'"
            v-model="item.REM_DATETIME"
            :required="true"
            size="xl2 lg3 md4" />
          <gat-edit
            v-if="item.REMINDER == 1"
            dataType="number"
            :label="!item.REM_RELATIVE ? 'Remind me (number)' : 'Remind me'"
            v-model="item.REM_RELATIVE"
            :required="true"
            size="xl3 lg3 md3 sm6" />
          <gat-select
            v-if="item.REMINDER == 1"
            label="Min/hrs/days"
            v-model="item.REM_REL_TYPE"
            :items="reminderRelativeType"
            :required="true"
            size="xl1 lg2 md2 sm6" />
          <gat-select
            v-if="item.REMINDER == 1"
            label="Before/After"
            v-model="item.REM_REL_TIME"
            :items="reminderBeforeAfter"
            :required="true"
            size="xl1 lg2 md2 sm6" />
          <gat-select
            v-if="item.REMINDER == 1"
            label="Status"
            v-model="item.REM_TYPE"
            :errorMessages="item.REM_TYPE < 20 ? '*Required' : ''"
            :items="$store.state.constants.checklistReminderTypes"
            size="xl3 lg5 md5 sm6" />
          <!--  -->
        </v-layout>
      </gat-group>
    </gat-form>
    <!-- <record-presenter :value="item" /> -->
    <gat-bottom-panel
      :orgValue="orgItem"
      :newValue="item"
      :itemDescription="'checklist item'"
      :saveBtn="true"
      :deleteBtn="true"
      :buttonsEnabled="!saving"
      :deleteDisabled="!item.ID || !$store.getters.userRight('CH.DELETE')"
      :saveDisabled="!$store.getters.userRight('CH.NEW_AND_EDIT')"
      @saveClicked="saveItem"
      @deleteClicked="deleteItem(item.ID)">
    </gat-bottom-panel>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { apiGet, apiPut, apiDelete, apiPost } from '@/store/api';

export default {
  name: 'CheckListItem',
  props: {
    checklistId: {
      type: Number,
    },
    callId: {
      type: Number,
      required: true,
    },
    isNew: {
      type: Boolean,
    },
  },
  data() {
    return {
      expandedIfNew: false, // is set in activated/deactivated based on isNew prop.
      item: {},
      orgItem: {},
      saving: false,
      reminderRelativeType: [
        { value: 1, text: 'Minutes' },
        { value: 60, text: 'Hours' },
        { value: 1440, text: 'Days' },
      ],
      reminderBeforeAfter: [
        { value: 'Before', text: 'Before' },
        { value: 'After', text: 'After' },
      ],
      reminder: [
        { value: 0, text: 'No reminder' },
        { value: 1, text: 'Relative reminder' },
        { value: 10, text: 'Absolute reminder' },
      ],
      isValid: true,
      isLoading: false,
    };
  },
  activated() {
    this.$store.commit('setBackNavigation', [
      { text: 'port call', href: `/call/${this.callId}` },
      { text: 'checklist', href: `/call/${this.callId}/checklist` },
    ]);
    if (this.checklistId) {
      if (!this.isNew) {
        this.loadChecklistItem(this.checklistId);
      }
    }
    if (this.isNew) {
      const result = { ...this.item };
      result.PORTCALL_ID = this.callId;
      result.REMINDER = 0;
      result.REM_TYPE = null;
      this.item = result;
      // eslint-disable-next-line @typescript-eslint/no-shadow
      apiGet(`/checklist/call/${this.callId}/next-sort-order`).then((result) => {
        // ADD sortorder value
        this.item.CHECKLIST_NUMBER = result;
        this.orgItem = this.duplicateViaJson(this.item);
      });
    }
    this.expandedIfNew = this.isNew;
  },
  deactivated() {
    this.$store.commit('setBackNavigation', undefined);
    this.expandedIfNew = !this.isNew;
    this.item = {};
    this.orgItem = {};
  },
  watch: {
    checklistId: {
      handler(val) {
        if (!this.isNew) {
          this.loadChecklistItem(val);
        }
      },
      immediate: true,
    },
    item: {
      immediate: true,
      deep: true,
      handler(item) {
        // Set the input fields
        if (item.REMINDER == 10) {
          this.item.REM_TYPE = 10;
        }
        if (item.REMINDER == 0) {
          this.item.REM_TYPE = -1;
        }
        if (item.CHECKED == 1 && !item.CHECKED_DATE) {
          // eslint-disable-next-line no-param-reassign
          item.CHECKED_DATE = this.globalFormatDate(Date.now(), 'YYYY-MM-DDTHH:mm:ss');
        }
        if (item.CHECKED == 1 && !item.CHECKED_BY) {
          // eslint-disable-next-line no-param-reassign
          item.CHECKED_BY = this.$store.state.user.fullName;
        }
        if (item.CHECKED == 0 && item.CHECKED_DATE) {
          // eslint-disable-next-line no-param-reassign
          item.CHECKED_DATE = null;
        }
        if (item.CHECKED == 0 && item.CHECKED_BY) {
          // eslint-disable-next-line no-param-reassign
          item.CHECKED_BY = null;
        }
      },
    },
  },
  methods: {
    deleteItem(id) {
      // set isLoading to true to show progressbar if response is not immidiate
      this.isLoading = true;
      apiDelete(`/checklist/${id}`)
        .then((result) => {
          // remove progressbar
          this.isLoading = false;
          if (result.resultCategory == 1) {
            this.item = {};
            this.orgItem = {};
            this.$store.commit('removeChecklistItem', id);
          }
        })
        .finally(() => {
          this.$nextTick(() => {
            this.$router.push(`/call/${this.callId}/checklist`); // go back
          });
          this.$store.dispatch('getPendingActivities');
        });
    },

    loadChecklistItem(id) {
      // set isLoading to true to show progressbar if response is not immidiate
      this.isLoading = true;
      apiGet(`checklist/${id}`).then((result) => {
        // remove progressbar
        /* console.log(result.REM_RELATIVE) */
        if (result.REM_TYPE == 10) {
          // eslint-disable-next-line no-param-reassign
          result.REMINDER = 10;
        }
        if (result.REMINDER == 1) {
          if (result.REM_RELATIVE < 0) {
            // eslint-disable-next-line no-param-reassign
            result.REM_REL_TIME = 'Before';
          } else {
            // eslint-disable-next-line no-param-reassign
            result.REM_REL_TIME = 'After';
          }
          // eslint-disable-next-line no-param-reassign
          result.REM_RELATIVE = Math.abs(result.REM_RELATIVE);
        }
        this.isLoading = false;
        this.item = result;
        this.orgItem = this.duplicateViaJson(this.item);
      });
    },

    constructChecklistItem(item) {
      const result = item;
      if (item.REMINDER == 1) {
        if (item.REM_REL_TIME == 'Before') {
          result.REM_RELATIVE = -Math.abs(item.REM_RELATIVE);
        } else {
          result.REM_RELATIVE = Math.abs(item.REM_RELATIVE);
        }
      }
      /* console.log(result.REM_RELATIVE) */

      return result;
    },

    saveItem() {
      this.$refs.form.validate();
      if (this.isValid) {
        this.saving = true;
        // set isLoading to true to show progressbar if response is not immidiate
        this.isLoading = true;
        const formated = this.globalFormatDateTime(this.item.CHECKED_DATE);
        let checkListItem = this.constructChecklistItem(this.item);
        if (checkListItem.REMINDER > 1) {
          checkListItem.REMINDER = 1;
        }
        // REM_REL_TIME is just a helper key - used to set the relative time to positive or negative based on "after" or "before".
        // in order to save, orgvalue and value have to be different.
        if (this.orgItem.REM_REL_TIME == 'After') {
          this.orgItem.REM_RELATIVE = Math.abs(this.orgItem.REM_RELATIVE);
        } else {
          this.orgItem.REM_RELATIVE = -Math.abs(this.orgItem.REM_RELATIVE);
        }
        delete this.orgItem.REM_REL_TIME;
        delete checkListItem.REM_REL_TIME;
        if (checkListItem.ID) {
          // update

          apiPut(`checklist/${checkListItem.ID}`, { orgValue: this.orgItem, value: checkListItem })
            .then((result) => {
              this.saving = false;
              // remove progressbar
              this.isLoading = false;
              if (result.resultCategory == 1) {
                // handle successfull update
                // this.item.CHECKED_DATE = formated;
                this.item = this.duplicateViaJson(checkListItem);
                this.orgItem = this.duplicateViaJson(checkListItem);
                // delayed for å ungå at dialog om data endret vises (changed property må rekke å settes)
                this.$store.commit('replaceChecklistItem', checkListItem);
              }
            })
            .finally(() => {
              this.$store.dispatch('getPendingActivities');
              this.$nextTick(() => {
                this.$router.push(`/call/${this.callId}/checklist`); // go back
              });
            });
        } else {
          // insert
          apiPost('checklist/', checkListItem)
            .then((result) => {
              this.saving = false;
              // remove progressbar
              this.isLoading = false;
              if (result.resultCategory == 1) {
                // handle successfull update
                checkListItem.CHECKED_DATE = formated;
                checkListItem = result.payload;
                this.item = this.duplicateViaJson(checkListItem);
                this.orgItem = this.duplicateViaJson(checkListItem);
                // delayed for å ungå at dialog om data endret vises (changed property må rekke å settes)
                this.$store.commit('addChecklistItem', result.payload);
              }
            })
            .finally(() => {
              this.$store.dispatch('getPendingActivities');
              this.$nextTick(() => {
                this.$router.push(`/call/${this.callId}/checklist`); // go back
              });
            });
        }
      }
    },
  },
};
</script>

<style></style>
