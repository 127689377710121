<template>
  <GatGroup class="pa-0" title="Vessel details" :collapsed="true">
    <template slot="compact">
      <v-layout v-if="vessel" wrap>
        <div class="d-flex flex-row justify-start" style="width: 100%">
          <gat-compact-field
            :value="vessel.VESSEL_PREFIX + ' ' + vessel.NAME"
            :link-url="'/vessel/' + vessel.ID"
            label="Vessel name"
            style="max-width: none !important" />
          <gat-compact-field :value="vessel.CALLSIGN" label="Call sign" />
          <gat-compact-field :value="vessel.LLOYDSNR" hide="phone" label="IMO" />
          <gat-compact-field :value="vessel.DWT" label="DWT" />
          <gat-compact-field :value="vessel.NATIONAL_CODES_ID" hide="phone" label="FLAG" />
        </div>
      </v-layout>
      <!-- <gat-dyna-fields componentName="tsUserDefData.VesselDyna" container="Upper part of window" v-model="vessel.dynaValues" :compact="true"></gat-dyna-fields> -->
    </template>
    <v-layout wrap>
      <GatSubGroup size="xxl">
        <gat-compact-field
          :value="vessel.VESSEL_PREFIX + ' ' + vessel.NAME"
          :link-url="'/vessel/' + vessel.ID"
          label="Vessel name"
          size="xs12 sm12" />
        <gat-vessel-select
          v-if="false"
          v-model="vessel.ID"
          label="Vessel"
          size="xs12 sm6"
          :readonly="!$store.getters.userRight('P.VESSELCHANGE')">
        </gat-vessel-select>

        <gat-compact-field :value="vessel.CALLSIGN" label="Call sign" size="s" />
        <gat-compact-field :value="vessel.LLOYDSNR" label="IMO" size="s" />
        <!-- <GatFlex hide="phone" size="sm1 md5"></GatFlex> -->
        <gat-compact-field :value="vessel.NATIONAL_CODES_ID" size="s" label="FLAG" />
        <gat-compact-field :value="vessel.DWT" size="s" label="DWT" />
        <gat-compact-field v-model="vessel.GT" label="GT" size="s" />
        <gat-compact-field v-model="vessel.NT" label="NT" size="s" />
        <gat-compact-field v-model="vessel.CGT" label="RGT" size="s" />
        <gat-compact-field v-model="vessel.SBT" label="SBT" size="s" />
        <gat-compact-field v-model="vessel.CBMTANK" label="CBM tank" size="s" />
        <gat-compact-field v-model="vessel.LOA" label="LOA" size="s" />
        <gat-compact-field v-model="vessel.BEAM" label="Beam" size="s" />
        <gat-compact-field v-model="vessel.SUMMERDRAFT" label="Max draft" size="s" />
        <gat-compact-field v-model="vessel.AIR_DRAFT" label="Air draft" size="s" />
        <gat-compact-field v-model="vessel.LL" label="LL" size="s" />
        <gat-compact-field v-model="vessel.KNOTS" label="Knots" size="s" />
        <gat-compact-field v-model="vessel.USER_VALUE" label="Field 1" size="s" />
        <gat-compact-field v-model="vessel.USER_TEXT_VALUE" label="Field 2" size="s" />
        <gat-flex size="xs12"
          ><gat-dyna-fields
            componentName="tsUserDefData.VesselDyna"
            container="Upper part of window"
            v-model="vessel.dynaValues"
            :compact="true"></gat-dyna-fields
        ></gat-flex>
      </GatSubGroup>
    </v-layout>
  </GatGroup>
</template>

<script>
import GatDynaFields from '@/app-components/dynafields/GatDynaFields.vue';
import GatVesselSelect from '@/app-components/GatVesselSelect.vue';

export default {
  name: 'PortcallVesselDetails',
  props: ['vessel'],
  components: { GatVesselSelect, GatDynaFields },

  data() {
    return {};
  },

  created() {},

  watch: {},

  methods: {},
};
</script>

<style scoped></style>
