import { render, staticRenderFns } from "./CheckList.vue?vue&type=template&id=23223995&scoped=true&"
import script from "./CheckList.vue?vue&type=script&lang=js&"
export * from "./CheckList.vue?vue&type=script&lang=js&"
import style0 from "./CheckList.vue?vue&type=style&index=0&id=23223995&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23223995",
  null
  
)

export default component.exports